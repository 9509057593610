// Core
import { useCallback } from "react";
import { useRouter } from "next/router";
import QS from "qs";
import { useQuery } from "@apollo/client";

// Definitions
import type {
  NotificationType,
  NotificationsResponseType,
  NotificationsParamsType,
  PaginationType,
  FilterQueryParamsType,
} from "client/core/notifications/models";

// Utils
import { initMetaState, QS_QUERY_CONFIG } from "utils/constants";
import NOTIFICATIONS_GET_ALL_QUERY from "client/core/notifications/hooks/gql/get-all-query.graphql";
import { getComposedQueryParams } from "client/core/notifications/utils/filter";

type UseCallbackFetchNotifications = (listData?: FilterQueryParamsType | null) => void;

type UseNotificationsFetchType = {
  loadingNotificationsFetch?: boolean;
  pagination: PaginationType;
  notifications: NotificationType[];
  totalUnread: number;
  onRefetchNotifications: UseCallbackFetchNotifications;
};

export const useNotificationsFetch = (
  props?: NotificationsParamsType,
): UseNotificationsFetchType => {
  const router = useRouter();
  const queryParams = getComposedQueryParams(props?.params, router.query);

  const notificationsAllQuery = useQuery<NotificationsResponseType>(NOTIFICATIONS_GET_ALL_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: queryParams,
  });

  const onRefetch = useCallback<UseCallbackFetchNotifications>(
    (listData) => {
      void (async () => {
        const pathname = router.pathname;
        if (listData) {
          await router.replace(
            {
              pathname,
              query: QS.stringify(listData, QS_QUERY_CONFIG),
            },
            void 0,
            { shallow: true },
          );
        }
      })();
    },
    [router],
  );

  const notifications = notificationsAllQuery?.data?.getAllNotifications;

  return {
    loadingNotificationsFetch: notificationsAllQuery.loading,
    pagination: notifications?.meta || initMetaState,
    notifications: notifications?.items || [],
    totalUnread: notifications?.totalUnread || Number(null),
    onRefetchNotifications: onRefetch,
  };
};
