const personalManagerFaqCommon = {
  aboutUs: [
    {
      title: "personal-manager:faq.items.aboutUs.content.0.title",
      desc: "personal-manager:faq.items.aboutUs.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.aboutUs.content.1.title",
      desc: "personal-manager:faq.items.aboutUs.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.aboutUs.content.2.title",
      desc: "personal-manager:faq.items.aboutUs.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.aboutUs.content.3.title",
      desc: "personal-manager:faq.items.aboutUs.content.3.desc",
    },
  ],
  benefits: [
    {
      title: "personal-manager:faq.items.benefits.content.0.title",
      desc: "personal-manager:faq.items.benefits.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.1.title",
      desc: "personal-manager:faq.items.benefits.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.2.title",
      desc: "personal-manager:faq.items.benefits.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.3.title",
      desc: "personal-manager:faq.items.benefits.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.4.title",
      desc: "personal-manager:faq.items.benefits.content.4.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.5.title",
      desc: "personal-manager:faq.items.benefits.content.5.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.6.title",
      desc: "personal-manager:faq.items.benefits.content.6.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.7.title",
      desc: "personal-manager:faq.items.benefits.content.7.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.8.title",
      desc: "personal-manager:faq.items.benefits.content.8.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.9.title",
      desc: "personal-manager:faq.items.benefits.content.9.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.10.title",
      desc: "personal-manager:faq.items.benefits.content.10.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.11.title",
      desc: "personal-manager:faq.items.benefits.content.11.desc",
    },
    {
      title: "personal-manager:faq.items.benefits.content.12.title",
      desc: "personal-manager:faq.items.benefits.content.12.desc",
    },
  ],
  websiteApp: [
    {
      title: "personal-manager:faq.items.websiteApp.content.0.title",
      desc: "personal-manager:faq.items.websiteApp.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.1.title",
      desc: "personal-manager:faq.items.websiteApp.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.2.title",
      desc: "personal-manager:faq.items.websiteApp.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.3.title",
      desc: "personal-manager:faq.items.websiteApp.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.4.title",
      desc: "personal-manager:faq.items.websiteApp.content.4.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.5.title",
      desc: "personal-manager:faq.items.websiteApp.content.5.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.6.title",
      desc: "personal-manager:faq.items.websiteApp.content.6.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.7.title",
      desc: "personal-manager:faq.items.websiteApp.content.7.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.8.title",
      desc: "personal-manager:faq.items.websiteApp.content.8.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.9.title",
      desc: "personal-manager:faq.items.websiteApp.content.9.desc",
    },
    {
      title: "personal-manager:faq.items.websiteApp.content.10.title",
      desc: "personal-manager:faq.items.websiteApp.content.10.desc",
    },
  ],
  productOffer: [
    {
      title: "personal-manager:faq.items.productOffer.content.0.title",
      desc: "personal-manager:faq.items.productOffer.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.productOffer.content.1.title",
      desc: "personal-manager:faq.items.productOffer.content.1.desc",
    },
  ],
  prices: [
    {
      title: "personal-manager:faq.items.prices.content.2.title",
      desc: "personal-manager:faq.items.prices.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.prices.content.3.title",
      desc: "personal-manager:faq.items.prices.content.3.desc",
    },
  ],
  orders: [
    {
      title: "personal-manager:faq.items.orders.content.0.title",
      desc: "personal-manager:faq.items.orders.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.orders.content.1.title",
      desc: "personal-manager:faq.items.orders.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.orders.content.2.title",
      desc: "personal-manager:faq.items.orders.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.orders.content.3.title",
      desc: "personal-manager:faq.items.orders.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.orders.content.4.title",
      desc: "personal-manager:faq.items.orders.content.4.desc",
    },
  ],
  generalPayment: [
    {
      title: "personal-manager:faq.items.generalPayment.content.0.title",
      desc: "personal-manager:faq.items.generalPayment.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.generalPayment.content.1.title",
      desc: "personal-manager:faq.items.generalPayment.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.generalPayment.content.2.title",
      desc: "personal-manager:faq.items.generalPayment.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.generalPayment.content.3.title",
      desc: "personal-manager:faq.items.generalPayment.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.generalPayment.content.4.title",
      desc: "personal-manager:faq.items.generalPayment.content.4.desc",
    },
  ],
  sepa: [
    {
      title: "personal-manager:faq.items.sepa.content.0.title",
      desc: "personal-manager:faq.items.sepa.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.sepa.content.1.title",
      desc: "personal-manager:faq.items.sepa.content.1.desc",
    },
  ],
  mondu: [
    {
      title: "personal-manager:faq.items.mondu.content.0.title",
      desc: "personal-manager:faq.items.mondu.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.1.title",
      desc: "personal-manager:faq.items.mondu.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.2.title",
      desc: "personal-manager:faq.items.mondu.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.3.title",
      desc: "personal-manager:faq.items.mondu.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.4.title",
      desc: "personal-manager:faq.items.mondu.content.4.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.5.title",
      desc: "personal-manager:faq.items.mondu.content.5.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.6.title",
      desc: "personal-manager:faq.items.mondu.content.6.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.7.title",
      desc: "personal-manager:faq.items.mondu.content.7.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.8.title",
      desc: "personal-manager:faq.items.mondu.content.8.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.9.title",
      desc: "personal-manager:faq.items.mondu.content.9.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.10.title",
      desc: "personal-manager:faq.items.mondu.content.10.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.11.title",
      desc: "personal-manager:faq.items.mondu.content.11.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.12.title",
      desc: "personal-manager:faq.items.mondu.content.12.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.13.title",
      desc: "personal-manager:faq.items.mondu.content.13.desc",
    },
    {
      title: "personal-manager:faq.items.mondu.content.14.title",
      desc: "personal-manager:faq.items.mondu.content.14.desc",
    },
  ],
  creditCards: [
    {
      title: "personal-manager:faq.items.creditCards.content.0.title",
      desc: "personal-manager:faq.items.creditCards.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.creditCards.content.1.title",
      desc: "personal-manager:faq.items.creditCards.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.creditCards.content.2.title",
      desc: "personal-manager:faq.items.creditCards.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.creditCards.content.3.title",
      desc: "personal-manager:faq.items.creditCards.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.creditCards.content.4.title",
      desc: "personal-manager:faq.items.creditCards.content.4.desc",
    },
    {
      title: "personal-manager:faq.items.creditCards.content.5.title",
      desc: "personal-manager:faq.items.creditCards.content.5.desc",
    },
    {
      title: "personal-manager:faq.items.creditCards.content.6.title",
      desc: "personal-manager:faq.items.creditCards.content.6.desc",
    },
    {
      title: "personal-manager:faq.items.creditCards.content.7.title",
      desc: "personal-manager:faq.items.creditCards.content.7.desc",
    },
    {
      title: "personal-manager:faq.items.creditCards.content.8.title",
      desc: "personal-manager:faq.items.creditCards.content.8.desc",
    },
  ],
  alternativePaymentMethods: [
    {
      title: "personal-manager:faq.items.alternativePaymentMethods.content.0.title",
      desc: "personal-manager:faq.items.alternativePaymentMethods.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.alternativePaymentMethods.content.1.title",
      desc: "personal-manager:faq.items.alternativePaymentMethods.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.alternativePaymentMethods.content.2.title",
      desc: "personal-manager:faq.items.alternativePaymentMethods.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.alternativePaymentMethods.content.3.title",
      desc: "personal-manager:faq.items.alternativePaymentMethods.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.alternativePaymentMethods.content.4.title",
      desc: "personal-manager:faq.items.alternativePaymentMethods.content.4.desc",
    },
  ],
  delivery: [
    {
      title: "personal-manager:faq.items.delivery.content.0.title",
      desc: "personal-manager:faq.items.delivery.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.1.title",
      desc: "personal-manager:faq.items.delivery.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.2.title",
      desc: "personal-manager:faq.items.delivery.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.3.title",
      desc: "personal-manager:faq.items.delivery.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.4.title",
      desc: "personal-manager:faq.items.delivery.content.4.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.5.title",
      desc: "personal-manager:faq.items.delivery.content.5.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.6.title",
      desc: "personal-manager:faq.items.delivery.content.6.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.7.title",
      desc: "personal-manager:faq.items.delivery.content.7.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.8.title",
      desc: "personal-manager:faq.items.delivery.content.8.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.9.title",
      desc: "personal-manager:faq.items.delivery.content.9.desc",
    },
    {
      title: "personal-manager:faq.items.delivery.content.10.title",
      desc: "personal-manager:faq.items.delivery.content.10.desc",
    },
  ],
  returnsAndRefunds: [
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.0.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.1.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.2.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.3.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.4.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.4.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.5.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.5.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.6.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.6.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.7.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.7.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.8.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.8.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.9.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.9.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.10.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.10.desc",
    },
    {
      title: "personal-manager:faq.items.returnsAndRefunds.content.11.title",
      desc: "personal-manager:faq.items.returnsAndRefunds.content.11.desc",
    },
  ],
  serviceAgent: [
    {
      title: "personal-manager:faq.items.serviceAgent.content.0.title",
      desc: "personal-manager:faq.items.serviceAgent.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.serviceAgent.content.1.title",
      desc: "personal-manager:faq.items.serviceAgent.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.serviceAgent.content.2.title",
      desc: "personal-manager:faq.items.serviceAgent.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.serviceAgent.content.3.title",
      desc: "personal-manager:faq.items.serviceAgent.content.4.desc",
    },
    {
      title: "personal-manager:faq.items.serviceAgent.content.4.title",
      desc: "personal-manager:faq.items.serviceAgent.content.4.desc",
    },
  ],
  accountManager: [
    {
      title: "personal-manager:faq.items.accountManager.content.0.title",
      desc: "personal-manager:faq.items.accountManager.content.0.desc",
    },
  ],
  registration: [
    {
      title: "personal-manager:faq.items.registration.content.0.title",
      desc: "personal-manager:faq.items.registration.content.0.desc",
    },
    {
      title: "personal-manager:faq.items.registration.content.1.title",
      desc: "personal-manager:faq.items.registration.content.1.desc",
    },
    {
      title: "personal-manager:faq.items.registration.content.2.title",
      desc: "personal-manager:faq.items.registration.content.2.desc",
    },
    {
      title: "personal-manager:faq.items.registration.content.3.title",
      desc: "personal-manager:faq.items.registration.content.3.desc",
    },
    {
      title: "personal-manager:faq.items.registration.content.4.title",
      desc: "personal-manager:faq.items.registration.content.4.desc",
    },
    {
      title: "personal-manager:faq.items.registration.content.5.title",
      desc: "personal-manager:faq.items.registration.content.5.desc",
    },
    {
      title: "personal-manager:faq.items.registration.content.6.title",
      desc: "personal-manager:faq.items.registration.content.6.desc",
    },
  ],
};

export const personalManagerFaqDE = [
  {
    faqTitleBlock: "personal-manager:faq.items.aboutUs.faqTitleBlock",
    content: personalManagerFaqCommon.aboutUs,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.benefits.faqTitleBlock",
    content: personalManagerFaqCommon.benefits,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.websiteApp.faqTitleBlock",
    content: personalManagerFaqCommon.websiteApp,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.productOffer.faqTitleBlock",
    content: personalManagerFaqCommon.productOffer,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.prices.faqTitleBlock",
    content: personalManagerFaqCommon.prices,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.orders.faqTitleBlock",
    content: personalManagerFaqCommon.orders,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.generalPayment.faqTitleBlock",
    content: personalManagerFaqCommon.generalPayment.slice(0, 3),
  },
  {
    faqTitleBlock: "personal-manager:faq.items.sepa.faqTitleBlock",
    content: personalManagerFaqCommon.sepa,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.mondu.faqTitleBlock",
    content: personalManagerFaqCommon.mondu,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.creditCards.faqTitleBlock",
    content: personalManagerFaqCommon.creditCards,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.alternativePaymentMethods.faqTitleBlock",
    content: personalManagerFaqCommon.alternativePaymentMethods,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.delivery.faqTitleBlock",
    content: personalManagerFaqCommon.delivery,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.returnsAndRefunds.faqTitleBlock",
    content: personalManagerFaqCommon.returnsAndRefunds,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.accountManager.faqTitleBlock",
    content: personalManagerFaqCommon.accountManager,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.registration.faqTitleBlock",
    content: personalManagerFaqCommon.registration,
  },
];

export const personalManagerFaqAT = [
  {
    faqTitleBlock: "personal-manager:faq.items.aboutUs.faqTitleBlock",
    content: personalManagerFaqCommon.aboutUs,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.benefits.faqTitleBlock",
    content: personalManagerFaqCommon.benefits,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.websiteApp.faqTitleBlock",
    content: personalManagerFaqCommon.websiteApp,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.productOffer.faqTitleBlock",
    content: personalManagerFaqCommon.productOffer,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.prices.faqTitleBlock",
    content: personalManagerFaqCommon.prices,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.orders.faqTitleBlock",
    content: personalManagerFaqCommon.orders,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.generalPayment.faqTitleBlock",
    content: personalManagerFaqCommon.generalPayment.slice(0, 4),
  },
  {
    faqTitleBlock: "personal-manager:faq.items.sepa.faqTitleBlock",
    content: personalManagerFaqCommon.sepa,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.mondu.faqTitleBlock",
    content: personalManagerFaqCommon.mondu,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.creditCards.faqTitleBlock",
    content: personalManagerFaqCommon.creditCards,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.alternativePaymentMethods.faqTitleBlock",
    content: personalManagerFaqCommon.alternativePaymentMethods,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.delivery.faqTitleBlock",
    content: personalManagerFaqCommon.delivery,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.returnsAndRefunds.faqTitleBlock",
    content: personalManagerFaqCommon.returnsAndRefunds,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.accountManager.faqTitleBlock",
    content: personalManagerFaqCommon.accountManager,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.registration.faqTitleBlock",
    content: personalManagerFaqCommon.registration,
  },
];

export const personalManagerFaqFR = [
  {
    faqTitleBlock: "personal-manager:faq.items.aboutUs.faqTitleBlock",
    content: personalManagerFaqCommon.aboutUs,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.benefits.faqTitleBlock",
    content: personalManagerFaqCommon.benefits,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.websiteApp.faqTitleBlock",
    content: personalManagerFaqCommon.websiteApp,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.productOffer.faqTitleBlock",
    content: personalManagerFaqCommon.productOffer,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.prices.faqTitleBlock",
    content: [
      {
        title: "personal-manager:faq.items.prices.content.0.title",
        desc: "personal-manager:faq.items.prices.content.0.desc",
      },
      {
        title: "personal-manager:faq.items.prices.content.1.title",
        desc: "personal-manager:faq.items.prices.content.1.desc",
      },
      ...personalManagerFaqCommon.prices,
    ],
  },
  {
    faqTitleBlock: "personal-manager:faq.items.orders.faqTitleBlock",
    content: personalManagerFaqCommon.orders,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.generalPayment.faqTitleBlock",
    content: personalManagerFaqCommon.generalPayment,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.sepa.faqTitleBlock",
    content: personalManagerFaqCommon.sepa,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.mondu.faqTitleBlock",
    content: personalManagerFaqCommon.mondu,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.creditCards.faqTitleBlock",
    content: personalManagerFaqCommon.creditCards,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.alternativePaymentMethods.faqTitleBlock",
    content: personalManagerFaqCommon.alternativePaymentMethods,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.delivery.faqTitleBlock",
    content: personalManagerFaqCommon.delivery,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.returnsAndRefunds.faqTitleBlock",
    content: [
      ...personalManagerFaqCommon.returnsAndRefunds,
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.12.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.12.desc",
      },
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.13.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.13.desc",
      },
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.14.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.14.desc",
      },
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.15.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.15.desc",
      },
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.16.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.16.desc",
      },
    ],
  },
  {
    faqTitleBlock: "personal-manager:faq.items.serviceAgent.faqTitleBlock",
    content: personalManagerFaqCommon.serviceAgent,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.accountManager.faqTitleBlock",
    content: personalManagerFaqCommon.accountManager,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.registration.faqTitleBlock",
    content: personalManagerFaqCommon.registration,
  },
];

export const personalManagerFaqNL = [
  {
    faqTitleBlock: "personal-manager:faq.items.aboutUs.faqTitleBlock",
    content: personalManagerFaqCommon.aboutUs,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.benefits.faqTitleBlock",
    content: [
      {
        title: "personal-manager:faq.items.benefits.content.0.title",
        desc: "personal-manager:faq.items.benefits.content.0.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.1.title",
        desc: "personal-manager:faq.items.benefits.content.1.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.2.title",
        desc: "personal-manager:faq.items.benefits.content.2.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.3.title",
        desc: "personal-manager:faq.items.benefits.content.3.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.4.title",
        desc: "personal-manager:faq.items.benefits.content.4.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.5.title",
        desc: "personal-manager:faq.items.benefits.content.5.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.6.title",
        desc: "personal-manager:faq.items.benefits.content.6.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.7.title",
        desc: "personal-manager:faq.items.benefits.content.7.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.8.title",
        desc: "personal-manager:faq.items.benefits.content.8.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.9.title",
        desc: "personal-manager:faq.items.benefits.content.9.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.10.title",
        desc: "personal-manager:faq.items.benefits.content.10.desc",
      },
      {
        title: "personal-manager:faq.items.benefits.content.11.title",
        desc: "personal-manager:faq.items.benefits.content.11.desc",
      },
    ],
  },
  {
    faqTitleBlock: "personal-manager:faq.items.websiteApp.faqTitleBlock",
    content: personalManagerFaqCommon.websiteApp,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.productOffer.faqTitleBlock",
    content: personalManagerFaqCommon.productOffer,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.prices.faqTitleBlock",
    content: [
      {
        title: "personal-manager:faq.items.prices.content.0.title",
        desc: "personal-manager:faq.items.prices.content.0.desc",
      },
      {
        title: "personal-manager:faq.items.prices.content.1.title",
        desc: "personal-manager:faq.items.prices.content.1.desc",
      },
    ],
  },
  {
    faqTitleBlock: "personal-manager:faq.items.orders.faqTitleBlock",
    content: personalManagerFaqCommon.orders,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.generalPayment.faqTitleBlock",
    content: personalManagerFaqCommon.generalPayment,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.sepa.faqTitleBlock",
    content: personalManagerFaqCommon.sepa,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.mondu.faqTitleBlock",
    content: personalManagerFaqCommon.mondu,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.creditCards.faqTitleBlock",
    content: personalManagerFaqCommon.creditCards,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.alternativePaymentMethods.faqTitleBlock",
    content: personalManagerFaqCommon.alternativePaymentMethods,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.delivery.faqTitleBlock",
    content: personalManagerFaqCommon.delivery,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.returnsAndRefunds.faqTitleBlock",
    content: [
      ...personalManagerFaqCommon.returnsAndRefunds,
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.12.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.12.desc",
      },
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.13.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.13.desc",
      },
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.14.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.14.desc",
      },
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.15.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.15.desc",
      },
      {
        title: "personal-manager:faq.items.returnsAndRefunds.content.16.title",
        desc: "personal-manager:faq.items.returnsAndRefunds.content.16.desc",
      },
    ],
  },
  {
    faqTitleBlock: "personal-manager:faq.items.serviceAgent.faqTitleBlock",
    content: personalManagerFaqCommon.serviceAgent,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.accountManager.faqTitleBlock",
    content: personalManagerFaqCommon.accountManager,
  },
  {
    faqTitleBlock: "personal-manager:faq.items.registration.faqTitleBlock",
    content: personalManagerFaqCommon.registration,
  },
];
