// Core
import QS from "qs";
import { AxiosRequestConfig } from "axios";

// Definitions
import type { HttpResponse, HttpRequestParams } from "models/Http";
import type {
  CartProductParamsType,
  CartPreviewType,
  CartParamsType,
  CartRepairEstimateType,
  CartProductAlternativeListType,
  CartCountType,
} from "client/core/cart/models";

// Utils
import { http } from "api/network-provider";

export const cartService = Object.freeze({
  fetchCartPreview: () => {
    return http.get<HttpResponse<CartPreviewType>>("/cart/preview");
  },
  updateCartPreview: (payload: CartParamsType) => {
    const { data } = payload;
    return http.put<HttpResponse<CartPreviewType>>("/cart/preview", data);
  },
  removeCartPreviewProduct: (payload: CartProductParamsType) => {
    const id = payload?.id;
    return http.delete<HttpResponse<void>>(`/cart-products/${String(id)}`);
  },
  removeCartProduct: (payload: CartProductParamsType) => {
    const id = payload?.id;
    return http.delete<HttpResponse<void>>(`/cart-products/${String(id)}`);
  },
  updateCartPreviewProduct: (payload: CartProductParamsType) => {
    const { data, id } = payload;
    return http.put<HttpResponse<CartPreviewType>>(`/cart-products/${String(id)}`, data);
  },
  updateCartPreviewVehicles: (payload: CartParamsType) => {
    const { data, id } = payload;
    return http.put<HttpResponse<CartPreviewType>>(`/cart-vehicles/${String(id)}`, data);
  },
  removeCartPreviewVehicle: (payload: CartParamsType) => {
    const id = payload?.id;
    return http.delete<HttpResponse<void>>(`/cart-vehicles/${String(id)}`);
  },
  createCartPreviewRepairEstimate: (
    payload: HttpRequestParams<{
      basketTabId: number;
    }>,
  ) => {
    const { data, id } = payload;
    return http.post<HttpResponse<CartRepairEstimateType>>(
      `/cart-vehicles/${String(id)}/repair-estimates`,
      data,
    );
  },
  updateCartPreviewProductAlternative: (payload: CartProductParamsType) => {
    const { data, id } = payload;
    return http.put<HttpResponse<CartPreviewType>>(
      `/cart-products/${String(id)}/alternative`,
      data,
    );
  },
  fetchProductsAlternatives: (payload: HttpRequestParams) => {
    const { params } = payload;
    const config = {
      params,
      paramsSerializer: (p: AxiosRequestConfig): string => {
        return QS.stringify(p, { encode: false, arrayFormat: "indices" });
      },
    };
    return http.get<HttpResponse<CartProductAlternativeListType>>("/products/alternatives", config);
  },
  fetchCartPreviewCount: () => {
    return http.get<HttpResponse<CartCountType>>("/cart/preview/count");
  },
});
