// Core
import { i18n } from "client/utils/i18n/client";

// Definition
import { NotificationMessage } from "components/ui/Notification/Notification.types";

const httpErrorMessage: NotificationMessage = {
  title: "Unavailable",
  desc: "Something went wrong. Please, try again later",
};
const httpSuccessMessageGeneric: NotificationMessage = {
  title: "Success",
  desc: "",
};
const httpSuccessMessageUpdate: NotificationMessage = {
  title: "Success",
  desc: "You data was updated successfully!",
};
const httpErrorMessageNoData: NotificationMessage = {
  title: "No Data",
  desc: "Data for the app was not loaded successfully",
};

export const getHttpErrorMessageWithTranslations = (): NotificationMessage => ({
  title: i18n?.has("common:errors.title") ? i18n.t("common:errors.title") : httpErrorMessage.title,
  desc: i18n?.has("common:errors.desc") ? i18n.t("common:errors.desc") : httpErrorMessage.desc,
});

export const getHttpSuccessMessageCallBackWithTranslations = (): NotificationMessage => ({
  title: i18n?.has("intro:callBack.success.title")
    ? i18n.t("intro:callBack.success.title")
    : httpSuccessMessageGeneric.title,
  desc: i18n?.has("intro:callBack.success.desc")
    ? i18n.t("intro:callBack.success.desc")
    : httpSuccessMessageGeneric.desc,
});

export const getHttpSuccessMessageWithTranslations = (): NotificationMessage => ({
  title: i18n?.has("common:notifications.update.title")
    ? i18n.t("common:notifications.update.title")
    : httpSuccessMessageUpdate.title,
  desc: i18n?.has("common:notifications.update.desc")
    ? i18n.t("common:notifications.update.desc")
    : httpSuccessMessageUpdate.desc,
});

export const getHttpErrorMessageNoPersonalManagerWithTranslations = (): NotificationMessage => ({
  title: i18n?.has("personal-manager:operatorAssesment.noManagerError.title")
    ? i18n.t("personal-manager:operatorAssesment.noManagerError.title")
    : httpErrorMessageNoData.title,
  desc: i18n?.has("personal-manager:operatorAssesment.noManagerError.desc")
    ? i18n.t("personal-manager:operatorAssesment.noManagerError.desc")
    : httpErrorMessageNoData.desc,
});
