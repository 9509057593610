/* eslint-disable jsx-a11y/alt-text */
// Components
import { Image } from "components/ui/Image";
import { WrapperClickable } from "components/common/WrapperClickable";

// Definitions
import type { ImagePropsType } from "components/ui/Image";
import type { ProductHazardInfoType } from "client/core/cart/models";

// Utils
import { noImage } from "utils/constants";

import st from "./styles.module.css";

type CartPreviewHazardInfoProps = {
  hazardInfo: ProductHazardInfoType | null;
  onClick?: (hazardInfo: ProductHazardInfoType) => void;
  testId?: string;
};

export const CartPreviewHazardInfo = (props: CartPreviewHazardInfoProps) => {
  const { hazardInfo, onClick, testId = "" } = props;

  if (!hazardInfo) {
    return null;
  }

  const icon = hazardInfo?.icons[0];

  const iconProps: ImagePropsType = {
    src: icon ? icon.src : noImage.src,
    alt: icon ? icon.alt : noImage.alt,
    objectFit: "contain",
  };

  const handleOpenModal = () => {
    onClick?.(hazardInfo);
  };

  return (
    <div className={st["cart-preview-hazard-info"]} data-test-id={testId}>
      <WrapperClickable onClick={handleOpenModal}>
        <Image {...iconProps} width={20} height={20} />
      </WrapperClickable>
    </div>
  );
};
