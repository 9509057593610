// Core
import { useMemo } from "react";

// Components
import { UserCountrySelectorForm } from "client/core/user-country-selector/components/user-country-selector-form";

// Bus
import { useUserCountry } from "bus/user/hooks/useUserCountry";

// Hooks
import { useUserCountrySelectorStaticDataFetch } from "client/core/user-country-selector/hooks/use-user-country-selector-static-data-fetch";

// Utils
import { mapUserCountriesLabel } from "utils/maps";
import st from "./styles.module.css";

type UserCountrySelectorFooterProps = {
  testId: string;
};

export const UserCountrySelectorFooter = ({ testId }: UserCountrySelectorFooterProps) => {
  const { userCountry, onUpdateUserCountry } = useUserCountry();
  const { loadingStaticData, countries } = useUserCountrySelectorStaticDataFetch({
    country: userCountry,
  });

  const options = useMemo(() => mapUserCountriesLabel(countries), [countries]);

  return (
    <div className={st["user-country-selector-footer"]} data-test-id={testId}>
      <UserCountrySelectorForm
        testId={`${testId}-form`}
        loading={loadingStaticData}
        options={options}
        country={userCountry}
        onSubmit={onUpdateUserCountry}
        type={"footer"}
        controlSize="large"
      />
    </div>
  );
};
