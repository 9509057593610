// Definitions
import type { ImageType } from "client/core/cart/models";

// Components
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { Tooltip } from "components/ui/Tooltip";
import { Image } from "components/ui/Image";

// Utils
import st from "./styles.module.css";

type PromoItemValueType =
  | ""
  | "empty"
  | "default"
  | "hot"
  | "delivery"
  | "sale"
  | "items-in-stock"
  | "items-left"
  | "delivery-in-hours";
type PromoItemType = {
  id: number | null;
  value: PromoItemValueType;
  label: string;
  image: ImageType | null;
};

type CartCellPromoLabelProps = {
  item?: PromoItemType | null;
  testId?: string;
};

export const CartCellPromoLabel = (props: CartCellPromoLabelProps) => {
  const { item, testId } = props;

  if (!item?.image) {
    return (
      <div className={st["product-promo-placeholder-cell"]} data-test-id={testId}>
        {WrapperNodeDefault()}
      </div>
    );
  }

  return (
    <Tooltip
      align={{ offset: [-13, -5] }}
      placement="topLeft"
      type="primary"
      color="primary"
      title={item?.label}
    >
      <div className={st["product-promo-label-cell"]} data-test-id={testId}>
        <span className={st["product-promo-labels-icon"]}>
          <Image
            src={item.image.src}
            alt={item.image.alt}
            layout="fill"
            blurDataURL={item?.image?.blurDataURL}
            unoptimized
          />
        </span>
      </div>
    </Tooltip>
  );
};
