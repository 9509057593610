// Core
import React, { PropsWithChildren, ReactElement } from "react";
import { z } from "zod";

// Definitions
import type { ModalProps } from "components/ui/Modal";
import type { SelectItem } from "components/ui/FormFields/InputSelect/InputSelect.types";
import type { ImageType } from "components/ui/Image/Image.types";
import type { ProfileVehicleType } from "client/core/profile-vehicle";
import { imageSchema } from "client/core/products/models";

type CustomerVehicleType = {
  id: number;
  carMaker: SelectItem;
  carModel: SelectItem;
  carEngine: SelectItem;
  regNumber?: string | undefined;
  vin?: string | undefined;
  mileage?: number | null | undefined;
  image?: ImageType | null | undefined;
};

export const productHazardInfoSchema = z.object({
  title: z.string(),
  description: z.string(),
  icons: imageSchema.array(),
});

export type ProductHazardInfoType = z.infer<typeof productHazardInfoSchema>;

type OnPageModalType = {
  visible: boolean;
  modalProps?: Partial<ModalProps>;
  params?: {
    id?: number;
    client?: { name: string; phoneNumber: string } | null;
    vehicle?: CustomerVehicleType;
    profileVehicle?: ProfileVehicleType;
    hazardInfo?: ProductHazardInfoType;
    isSubChildCategory?: boolean;
    subCategoryId?: number;
    categoryToRedirectId?: number;
    subCategoryToRedirectId?: number;
  };
};

type ContentType = {
  isModal: boolean;
  onPageModals: Record<string, OnPageModalType>;
  setOnPageModal: (type: string, value: OnPageModalType) => void;
  setIsModal: (value: boolean) => void;
};

type ModalContextProviderPropsType = {
  children: ReactElement;
};

const Context = React.createContext<ContentType>({
  isModal: false,
  onPageModals: {},
  setOnPageModal: () => {},
  setIsModal: () => {},
});

export const ModalContextProvider = ({
  children,
}: PropsWithChildren<ModalContextProviderPropsType>) => {
  const [isModal, setIsModal] = React.useState<boolean>(false);
  const [onPageModals, setOnPageModal] = React.useState<ContentType["onPageModals"]>({});
  const mounted = React.useRef<boolean>(true);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Context.Provider
      value={{
        isModal,
        onPageModals,
        setIsModal: (newIsModal) => {
          mounted.current && setIsModal(newIsModal);
        },
        setOnPageModal: (modalType, value) => {
          mounted.current &&
            setOnPageModal((prev) => ({
              ...prev,
              [modalType]: value,
            }));
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
export const ModalContextConsumer = Context.Consumer;

export const useModalContext = () => React.useContext(Context);
