// Core
import { useRouter } from "next/router";

// Components
import { Col } from "components/ui/Col";
import { Row } from "components/ui/Row";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";
import { Button } from "components/ui/Button";
import { Image } from "components/ui/Image";
import { Container } from "components/common/Container";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { book } from "init/book";
import { useTranslation } from "client/utils/i18n/client";

import st from "./styles.module.css";

export const CartEmpty = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleContinueCatalogClick = () => {
    void (async () => {
      await router.push({
        pathname: `${book.catalog}/new`,
      });
    })();
  };

  return (
    <section className={st["cart-empty"]} data-test-id="cart-empty">
      <div className={st["cart-empty-inner"]}>
        <Container>
          <Row gutter={ROW_GUTTER_TYPE.md.horizontal} justify="center">
            <Col span={16}>
              <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
                <Col span={24}>
                  <div className={st.image}>
                    <Image
                      src="/assets/images/empty-cart.svg"
                      width={64}
                      height={64}
                      alt={t("cart:empty.imageAlt")}
                    />
                  </div>
                  <Title tag="h3" size="24" fontWeight="600" alignmentHorizontal="center">
                    {t("cart:empty.title")}
                  </Title>
                  <Text
                    size="14"
                    box="full"
                    color="gray-900"
                    alignmentHorizontal="center"
                    className={st["sub-title"]}
                  >
                    {t("cart:empty.subtitle")}
                  </Text>
                </Col>
                <Col span={8} offset={8}>
                  <div className={st.actions}>
                    <Button
                      type="primary"
                      size="large"
                      block
                      onClick={handleContinueCatalogClick}
                      data-test-id="cart-empty-continue-btn"
                    >
                      {t("cart:empty.continueBtn")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};
