// Core
import { createTranslator } from "use-intl/core";

// Definition
import type { TFunction, TMessages, Locale, TranslationValues } from "../types";
import { formatTranslationKey } from "../lib/utils";

type GlobalI18nValue = {
  t: TFunction;
  tRaw: TFunction;
  has: (key: string) => boolean;
};

export let globalI18n: GlobalI18nValue | null = null;

export const assignGlobalI18nValue = ({
  messages,
  locale,
}: {
  messages: TMessages;
  locale: Locale;
}) => {
  const nextIntlTranslateFunction = createTranslator({ messages, locale });

  const t: TFunction = <T>(key: string, values?: TranslationValues) => {
    return nextIntlTranslateFunction(formatTranslationKey(key), values) as T;
  };

  const tRaw: TFunction = <T>(key: string) => {
    return nextIntlTranslateFunction.raw(formatTranslationKey(key)) as T;
  };

  const has = (key: string) => {
    return nextIntlTranslateFunction.has(formatTranslationKey(key));
  };

  globalI18n = {
    t,
    tRaw,
    has,
  };
};
