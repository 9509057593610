// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Container } from "components/common/Container";
import { CartServiceCard } from "client/core/cart/components/cart-service-card";

// Domains
import { useProfileContext } from "hooks/useProfileContext";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { cartServiceMethods } from "./cart-service-methods";

import st from "./styles.module.css";

export const CartServices = () => {
  const { profile } = useProfileContext();

  const cartServiceMethodsList = cartServiceMethods(profile?.countryCode);

  return (
    <section className={st["cart-services"]} data-test-id="payment-services">
      <Container>
        <Row gutter={ROW_GUTTER_TYPE.md.horizontal} align="middle">
          <Col span={24}>
            <div className={st["cart-services-cards"]}>
              {cartServiceMethodsList.map((item) => (
                <CartServiceCard key={item.alt} {...item} />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
