// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { PersonalManagerAdviser } from "client/core/personal-manager/components/personal-manager-adviser";
import { PersonalManagerTitle } from "client/core/personal-manager/components/personal-manager-title";
import { PersonalManagerOperatorAssesment } from "client/core/personal-manager/components/personal-manager-operator-assesment";
import { PersonalManagerFAQ } from "client/core/personal-manager/components/personal-manager-faq";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";

import st from "./styles.module.css";

export const PersonalManagerLayout = () => {
  return (
    <section className={st["personal-manager-layout"]}>
      <PersonalManagerTitle />

      <Row gutter={ROW_GUTTER_TYPE.md.horizontal} className={st["personal-manager-layout-actions"]}>
        <Col span={12}>
          <PersonalManagerAdviser />
        </Col>
        <Col span={12}>
          <PersonalManagerOperatorAssesment />
        </Col>
      </Row>

      <PersonalManagerFAQ />
    </section>
  );
};
