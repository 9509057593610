// Core
import { useEffect, useState } from "react";
import dayjs from "dayjs";

// Utils
import { cookiesService } from "utils/cookies";

export const SURVEY_STATUSES = {
  completed: "completed",
  shown: "shown",
};

type UseHeaderBannerSurveyManageAppearanceProps = {
  targetDate: string;
  surveyName: string;
  onFinishNps: () => void;
};

type UseHeaderBannerSurveyManageAppearancePayload = {
  npsSurveyAvailable: boolean;
  handleSurveyLinkClick: () => void;
};

export const useHeaderBannerSurveyManageAppearance = (
  props: UseHeaderBannerSurveyManageAppearanceProps,
): UseHeaderBannerSurveyManageAppearancePayload => {
  const { targetDate, surveyName, onFinishNps } = props;
  const SURVEY_STATUS = `survey_${surveyName}_banner_top_status`;

  const [npsSurveyAvailable, setNpsSurveyAvailable] = useState(
    cookiesService.get(SURVEY_STATUS) !== SURVEY_STATUSES.completed,
  );

  const handleSurveyLinkClick = () => {
    setNpsSurveyAvailable(false);
    onFinishNps();
    cookiesService.set(SURVEY_STATUS, SURVEY_STATUSES.completed);
  };

  useEffect(() => {
    const currentDate = dayjs();
    const targetDateTime = dayjs(targetDate);
    const durations = dayjs.duration(targetDateTime.diff(currentDate));
    const isSurveyShown = cookiesService.get(SURVEY_STATUS);

    if (!isSurveyShown && durations.asMilliseconds() > 0) {
      setNpsSurveyAvailable(true);
      cookiesService.set(SURVEY_STATUS, SURVEY_STATUSES.shown);
    }
  }, [targetDate]);

  return { npsSurveyAvailable, handleSurveyLinkClick };
};
