// Components
import { Skeleton } from "antd";
import { WrapperDateDefault } from "components/common/WrapperDateDefault";
import { Icon } from "components/ui/Icon";
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./styles.module.css";

const DELIVERY_AT_CONFIG = {
  "month-day": "MMMM D",
  "day-month-year": "DD MMMM YYYY",
} as const;
type DeliveryAtType = keyof typeof DELIVERY_AT_CONFIG;

const getDeliveryAtType = (str: DeliveryAtType) => DELIVERY_AT_CONFIG[str] || "";

type CartPreviewVehiclesDeliveryDatePropsType = {
  deliveryAtType?: DeliveryAtType;
  loading?: boolean;
  deliveryAt?: number | null;
  locale?: string;
  text?: string;
};

export const CartPreviewVehiclesDeliveryDate = (
  props: CartPreviewVehiclesDeliveryDatePropsType,
) => {
  const {
    loading,
    deliveryAt,
    locale,
    deliveryAtType = "day-month-year",
    text = "Delivery Date",
  } = props;
  const dateAtFormat = getDeliveryAtType(deliveryAtType);

  return (
    <div className={st["delivery-date"]} data-test-id="delivery-date">
      <div className={st.icon}>
        <Icon name="OrderDateDelivery" size="middle" />
      </div>
      <div className={st.date}>
        <Text
          size="14"
          color="gray-900"
          fontWeight="600"
          alignmentVertical="middle"
          className={st["date-label"]}
        >
          {text}
        </Text>

        {loading ? (
          <Skeleton.Input className={st["date-skeleton"]} active />
        ) : (
          <Text
            tag="div"
            size="14"
            color="gray-600"
            alignmentVertical="middle"
            className={st["date-value"]}
          >
            {WrapperDateDefault(deliveryAt, dateAtFormat, locale)}
          </Text>
        )}
      </div>
    </div>
  );
};
