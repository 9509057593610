// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadPaymentMethodType,
  RouteNameKeysType,
  EventPaymentMethodDataType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  PAYMENT_METHOD_ACTION,
} from "client/utils/gtm/gtm.types";

class PaymentMethodEvent extends AbstractGTMEventSegment<GTMTypesEnum.payment_method> {
  protected readonly segmentShape: EventPayloadPaymentMethodType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.route_name]: "" as RouteNameKeysType,
      [ParametersEnum.event_action]: PAYMENT_METHOD_ACTION.empty,
    },
  };

  makeSegment(data: EventPaymentMethodDataType): EventPayloadPaymentMethodType {
    const { routeName, actionType } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = routeName;
    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] = actionType;

    return segment;
  }
}

export const paymentMethodEvent: PaymentMethodEvent = new PaymentMethodEvent();
