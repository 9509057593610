// Core
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

const DEFAULT_TIME = {
  days: "00",
  hours: "00",
  minutes: "00",
};

type TimerPayloadType = {
  days: string;
  hours: string;
  minutes: string;
};

const getCountdownTime = (targetDate: string) => {
  const currentDate = dayjs();
  const targetDateTime = dayjs(targetDate);
  const durations = dayjs.duration(targetDateTime.diff(currentDate));

  if (durations.asMilliseconds() <= 0) return DEFAULT_TIME;

  const days = durations.days().toString().padStart(2, "0");
  const hours = durations.hours().toString().padStart(2, "0");
  const minutes = durations.minutes().toString().padStart(2, "0");

  return {
    days,
    hours,
    minutes,
  };
};

export const checkDateIsExpired = (date: string) => {
  const { days, hours, minutes } = getCountdownTime(date);

  return days === "00" && hours === "00" && minutes === "00";
};

export const useHeaderBannerCountdownTimer = (targetDate: string): TimerPayloadType => {
  const [remainingTime, setRemainingTime] = useState<TimerPayloadType>(
    getCountdownTime(targetDate),
  );

  useEffect(() => {
    const countdown = setInterval(() => {
      const formattedTime = getCountdownTime(targetDate);

      if (formattedTime === DEFAULT_TIME) {
        clearInterval(countdown);
      }

      setRemainingTime(formattedTime);
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [targetDate]);

  return remainingTime;
};
