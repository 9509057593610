export const HEADER_BANNER_ANALYTICS_KEY = {
  batteries: "News-Ticker - Batteries",
  oils: "News-Ticker - Oils and fluids",
  tyres: "News-Ticker - Tires and related products",
  discDiscount: "PRO DEAL - BRAKE DISCS & PADS",
  wiperBladeDiscount: "News-Ticker - UP TO 15% OFF WIPER BLADES",
  npsSurveyO24: "News-ticker - NPS-oct24",
  proDeal3: "News-ticker - PRODEAL3",
  proDeal4: "News-ticker - PRODEAL4",
  ridex: "FLASH_SALE_1",
  proDeal5: "PRO_DEAL_5",
  blackFriday: "Black Friday 28.11.2024",
  proDeal6: "PRO_DEAL_6",
  helloween: "News-ticker - PROmo_Halloween",
} as const;

export const HEADER_BANNER_TYPES = {
  olympicGames: "olympicGames",
  agentOff: "agentOff",
  assortmentExpansion: "assortmentExpansion",
  goCore: "goCore",
  discDiscount: "discDiscount",
  wiperBladeDiscount: "wiperBladeDiscount",
  beltkitsDiscount: "beltkitsDiscount",
  lightBulbsDiscount: "lightBulbsDiscount",
  sparkPlugsDiscount: "sparkPlugsDiscount",
  clutchParts: "clutchParts",
  airFilters: "airFilters",
  ridex: "ridex",
  steeringParts: "steeringParts",
  filters: "filters",
  blackFriday: "blackFriday",
  helloween: "helloween",
  npsSurvey: "npsSurvey",
  default: "default",
} as const;
