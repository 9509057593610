// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type { EventPayloadOrderSortType, EventOrderSortDataType } from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ROUTE_NAME_KEYS,
  ORDER_COLUMN_VALUE,
} from "client/utils/gtm/gtm.types";

class OrderSortEvent extends AbstractGTMEventSegment<GTMTypesEnum.order_sort> {
  protected readonly segmentShape: EventPayloadOrderSortType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: ORDER_COLUMN_VALUE.empty,
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.orders,
    },
  };

  makeSegment(data: EventOrderSortDataType): EventPayloadOrderSortType {
    const { actionType } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] =
      `sort_${ORDER_COLUMN_VALUE[actionType]}`;
    segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = ROUTE_NAME_KEYS.orders;

    return segment;
  }
}

export const orderSortEvent: OrderSortEvent = new OrderSortEvent();
