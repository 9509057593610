// Definitions
import type { BaseDataListType } from "models/Base";

export const NOTIFICATION_TAG_TYPES = {
  all: "ALL",
  general: "GENERAL",
  benefits: "BENEFITS",
  orders: "ORDERS",
  news: "NEWS",
} as const;

export const NOTIFICATION_TYPE_ID = {
  ALL: 0,
  GENERAL: 1,
  BENEFITS: 2,
  ORDERS: 3,
  NEWS: 4,
} as const;

export const NOTIFICATION_SUB_TYPES = {
  unknown: "UNKNOWN",
  empty: "EMPTY",
  welcome: "WELCOME",
  status_gold: "STATUS_GOLD",
  personal_manager_visit: "PERSONAL_MANAGER_VISIT",
  order_assembling_delayed: "ORDER_ASSEMBLING_DELAYED",
  new_order_delivery_date: "NEW_ORDER_DELIVERY_DATE",
  new_loyalty_status: "NEW_LOYALTY_STATUS",
  upgrade_loyalty_status: "UPGRADE_LOYALTY_STATUS",
  downgrade_loyalty_status: "DOWNGRADE_LOYALTY_STATUS",
  prolongation_loyalty_status: "PROLONGATION_LOYALTY_STATUS",
  achieved_next_loyalty_status: "ACHIEVED_NEXT_LOYALTY_STATUS",
  personal_manager_visit_canceled: "PERSONAL_MANAGER_VISIT_CANCELED",
  bonuses_accrued: "BONUSES_ACCRUED",
  welcome_gift_coupon: "WELCOME_GIFT_COUPON",
  go_core_coupon: "GO_CORE_COUPON",
  mondu_successful_activation: "MONDU_SUCCESSFUL_ACTIVATION",
  available_mondu_with_status_platinum: "AVAILABLE_MONDU_WITH_STATUS_PLATINUM",
  first_delivery_note_is_ready: "FIRST_DELIVERY_NOTE_IS_READY",
  promo_20_brake_discs_and_break_pads: "PROMO_20_BRAKE_DISCS_AND_BREAK_PADS",
  promo_deal_3: "PROMO_DEAL_3",
  helloween: "HELLOWEEN",
  loyalty_platinum_status_and_mondu_access: "LOYALTY_PLATINUM_STATUS_AND_MONDU_ACCESS",
  promo_deal_4: "PROMO_DEAL_4",
  new_assortment_launch_nov_14: "NEW_ASSORTMENT_LAUNCH_NOV_14",
  flash_sale_ridex: "FLASH_SALE_RIDEX",
  promo_deal_5: "PROMO_DEAL_5",
  vin_search: "VIN_SEARCH",
  promo_deal_6: "PROMO_DEAL_6",
} as const;

export const NOTIFICATION_SUB_TYPE_ID = {
  UNKNOWN: -1,
  EMPTY: 0,
  WELCOME: 1,
  STATUS_GOLD: 2,
  PERSONAL_MANAGER_VISIT: 3,
  ORDER_ASSEMBLING_DELAYED: 4,
  NEW_ORDER_DELIVERY_DATE: 5,
  NEW_LOYALTY_STATUS: 6,
  UPGRADE_LOYALTY_STATUS: 7,
  DOWNGRADE_LOYALTY_STATUS: 8,
  PROLONGATION_LOYALTY_STATUS: 9,
  ACHIEVED_NEXT_LOYALTY_STATUS: 10,
  PERSONAL_MANAGER_VISIT_CANCELED: 11,
  BONUSES_ACCRUED: 12,
  WELCOME_GIFT_COUPON: 13,
  GO_CORE_COUPON: 14,
  MONDU_SUCCESSFUL_ACTIVATION: 15,
  AVAILABLE_MONDU_WITH_STATUS_PLATINUM: 16,
  FIRST_DELIVERY_NOTE_IS_READY: 17,
  PROMO_20_BRAKE_DISCS_AND_BREAK_PADS: 18,
  PROMO_DEAL_3: 19,
  HELLOWEEN: 20,
  LOYALTY_PLATINUM_STATUS_AND_MONDU_ACCESS: 21,
  PROMO_DEAL_4: 22,
  FLASH_SALE_RIDEX: 23,
  PROMO_DEAL_5: 25,
  NEW_ASSORTMENT_LAUNCH_NOV_14: 26,
  PROMO_DEAL_6: 29,
  VIN_SEARCH: 30,
} as const;

const NOTIFICATION_READ_TYPES = {
  ALL: "ALL",
  READ: "READ",
  UNREAD: "UNREAD",
} as const;

export const NOTIFICATION_LINKS = {
  benefits: "benefits",
  "returns-new-draft": "returns-new-draft",
  "change-password": "change-password",
  "nps-research-link": "nps-research-link",
  catalog: "catalog",
  "loyalty-program": "loyalty-program",
  "personal-manager": "personal-manager",
  "company-working-hours": "company-working-hours",
  "loyalty-program-bonus": "loyalty-program-bonus",
  "returns-and-refunds": "returns-and-refunds",
  "saved-baskets": "saved-baskets",
  "cart-preview": "cart/preview",
  "gocore-listing": "gocore-listing",
  "gocore-webpage": "gocore-webpage",
  documents: "documents",
  "settings-payment-method-mondu": "settings-payment-method-mondu",
  "capsules-research": "capsules-research",
  "order-page": "order-page",
  "catalog-batteries": "catalog-batteries",
  "catalog-tyres-and-accessories": "catalog-tyres-and-accessories",
  "catalog-oils-and-fluids": "catalog-oils-and-fluids",
  "catalog-brake-discs": "catalog-brake-discs",
  "catalog-brake-pads": "catalog-brake-pads",
  "graphic-catalog-categories": "graphic-catalog-categories",
  "wiper-blades-discount": "wiper-blades-discount",
  "timing-belt-kits": "timing-belt-kits",
  "light-bulbs": "light-bulbs",
  "spark-plugs": "spark-plugs",
  "see-mondu-payment-option": "see-mondu-payment-option",
  "clutch-parts": "clutch-parts",
  "air-filters": "air-filters",
  "flash-sale-ridex": "flash-sale-ridex",
  "steering-parts": "steering-parts",
  "shock-absorbers": "shock-absorbers",
  filters: "filters",
} as const;

export const NOTIFICATION_EXTERNAL_LINKS = {
  image: "image",
  "vin-search": "vin-search",
} as const;

export type NotificationTagType =
  (typeof NOTIFICATION_TAG_TYPES)[keyof typeof NOTIFICATION_TAG_TYPES];

export type NotificationSubType =
  (typeof NOTIFICATION_SUB_TYPES)[keyof typeof NOTIFICATION_SUB_TYPES];

export type NotificationReadType = keyof typeof NOTIFICATION_READ_TYPES;

export type NotificationLinkType = (typeof NOTIFICATION_LINKS)[keyof typeof NOTIFICATION_LINKS];

export type NotificationLinksDataType = {
  orderId?: string;
};

type NotificationExternalLinkTypes =
  (typeof NOTIFICATION_EXTERNAL_LINKS)[keyof typeof NOTIFICATION_EXTERNAL_LINKS];

export type NotificationExternalLinkType = {
  alias: NotificationExternalLinkTypes;
  link: string;
};

export type NotificationType = {
  id: number;
  title: string;
  text: string;
  sentAt: number | null;
  links: NotificationLinkType[] | null;
  externalLinks: NotificationExternalLinkType[] | null;
  preview: string;
  subType: NotificationSubType;
  type: NotificationTagType;
  isRead: boolean;
  dataForCopy: string[];
  isExpandable: boolean;
  data: NotificationLinksDataType | null;
};

export type NotificationsType = BaseDataListType<NotificationType> & {
  totalUnread?: number;
};

export type NotificationsResponseType = {
  getAllNotifications: NotificationsType;
};

export type NotificationsUnReadParams = {
  perPage?: number;
  read?: string;
};

export type NotificationsParamsType = {
  id?: number;
  params?: {
    page?: number;
    perPage?: number;
    read?: NotificationReadType;
    type?: NotificationTagType;
  };
};

export type ReadNotificationsParamsType = {
  id: number;
  isRead?: boolean;
};

export type NotificationEditData = {
  id: number;
  isExpandable?: number;
};

export type UpdateNotificationsParamsType = {
  notification: NotificationEditData;
  params?: {
    page?: number;
    perPage?: number;
    read?: NotificationReadType;
    type?: NotificationTagType;
  };
};

export type PaginationType = {
  page?: number | null;
  total?: number | null;
  perPage?: number | null;
  from?: number | null;
  to?: number | null;
  lastPage?: number | null;
  path?: string;
};

export type FilterQueryParamsType = {
  page?: number | string;
  perPage?: number | string;
  type?: NotificationTagType;
  read?: NotificationReadType;
};
