// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadDocumentSearchType,
  EventDocumentSearchDataType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ROUTE_NAME_KEYS,
  DOCUMENT_SEARCH_ACTION_TYPES,
} from "client/utils/gtm/gtm.types";

class DocumentSearchEvent extends AbstractGTMEventSegment<GTMTypesEnum.document_search> {
  protected readonly segmentShape: EventPayloadDocumentSearchType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: "",
      [ParametersEnum.event_object]: DOCUMENT_SEARCH_ACTION_TYPES.empty,
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.documents,
    },
  };

  makeSegment(data: EventDocumentSearchDataType): EventPayloadDocumentSearchType {
    const { actionType, eventType } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] = actionType;
    segment[PayloadKeysEnum.parameters][ParametersEnum.event_object] = eventType;
    segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = ROUTE_NAME_KEYS.documents;

    return segment;
  }
}

export const documentSearchEvent: DocumentSearchEvent = new DocumentSearchEvent();
