// Core
import { z } from "zod";

// Definitions
import type { BaseDataListType, BaseUnknownRecordType } from "models/Base";
import type { FilterQueryRequestType } from "utils/filter-query";

export type ProductsType = {
  products: ProductsListingType;
  filter: FilterDynamicItemType[];
  sort: SortFilterType | null;
};
export type FilterQueryParamsType = FilterParamsType & BaseUnknownRecordType;

export type FilterDynamicTyresSelectorOptionType = {
  id: number;
  label: string;
  value: string;
  icon?: string;
};

export type FilterDynamicTyresSelectorType = {
  tyreSeason: FilterDynamicTyresSelectorOptionType | null;
  tyreWidth: FilterDynamicTyresSelectorOptionType | null;
  tyreHeight: FilterDynamicTyresSelectorOptionType | null;
  tyreDiameter: FilterDynamicTyresSelectorOptionType | null;
};

export type FilterDynamicItemDataType =
  | FilterDynamicItemVehicle
  | FilterDynamicItemOptionType[]
  | FilterDynamicItemPriceRange
  | FilterHorizontalDynamicType
  | FilterHorizontalDynamicCriteriaType[]
  | FilterDynamicTyresSelectorType;

export type FilterDynamicItemPriceRange = {
  minPrice?: number;
  maxPrice?: number;
  value?: [number, number];
};

export const staticDataItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
});

export type StaticDataItemType = z.infer<typeof staticDataItemSchema>;

export type RadioButtonItemType = StaticDataItemType & {
  isSelected?: boolean;
};

export type FilterHorizontalDynamicType = {
  id?: number;
  label?: string;
  value?: string;
  isSelected?: boolean;
  data?: RadioButtonItemType[] | null;
};

export type FilterDynamicItemType = {
  data: FilterDynamicItemDataType;
  id: number;
  type: FilterDynamicTypesEnumType;
  title: string;
  fieldName?: FilterDynamicFieldsEnum | FilterHorizontalFieldsEnum;
};

const SORT_KEYS = {
  relevance: "relevance",
  lowestPriceNonVat: "lowestPriceNonVat",
  lowestVatPrice: "lowestVatPrice",
  highestPriceNonVat: "highestPriceNonVat",
  highestVatPrice: "highestVatPrice",
  fastestDelivery: "fastestDelivery",
  highestRrp: "highestRrp",
} as const;
const sortSchema = z.enum([
  SORT_KEYS.fastestDelivery,
  SORT_KEYS.highestPriceNonVat,
  SORT_KEYS.highestRrp,
  SORT_KEYS.highestVatPrice,
  SORT_KEYS.lowestPriceNonVat,
  SORT_KEYS.lowestVatPrice,
  SORT_KEYS.relevance,
]);
export type SortType = z.infer<typeof sortSchema>;

export const FILTER_DYNAMIC_TYPES_KEYS = {
  checkboxGroup: "checkbox-group",
  radioGroup: "radio-group",
  priceRange: "price-range",
  radioGroupSide: "radio-group-side",
  criteriaGroup: "criteria-group",
  vehicleFilter: "vehicle-filter",
  radioGroupSae: "radio-group-sae",
  selectorGroupTyres: "selector-group-tyres",
} as const;
export type FilterDynamicTypesKeysType =
  (typeof FILTER_DYNAMIC_TYPES_KEYS)[keyof typeof FILTER_DYNAMIC_TYPES_KEYS];

export type FilterDynamicTypesEnumType = keyof {
  [key in FilterDynamicTypesKeysType]: string;
};

export enum FilterDynamicFieldsEnum {
  priceRange = "priceRange",
  brand = "brand",
  delivery = "delivery",
  generic = "generic",
  fittingScore = "fittingScore",
}

export enum FilterHorizontalFieldsEnum {
  installation = "installation",
  criteria = "criteria",
  viscosity = "viscosity",
}

export enum ProductPromoItemValueType {
  empty = "empty",
  default = "default",
  hot = "hot",
  delivery = "delivery",
  sale = "sale",
}

export type CriteriaItem = z.infer<typeof criteriaItemSchema>;

export const criteriaItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
  isSelected: z.boolean().optional(),
});

export type FilterHorizontalDynamicCriteriaType = {
  id?: number;
  name?: string;
  value?: number;
  data?: CriteriaItem[];
};

export type FilterDynamicItemOptionType = FilterDynamicStaticDataItemType & {
  count?: number;
  isSelected?: boolean;
};

export type FilterDynamicItemVehicle = {
  label: string;
  value: number;
};

export type FilterStaticParamsType = {
  filter?: FilterQueryRequestType | null;
  page?: number | string;
  perPage?: number | string;
  sort?: SortType;
};

export type FilterStaticQueryParamsType<T extends Record<string, unknown> | void = {}> =
  FilterStaticParamsType & BaseUnknownRecordType & T;

export const paginationSchema = z.object({
  page: z.number().optional(),
  total: z.number().optional(),
  perPage: z.number().optional(),
  from: z.number().optional(),
  to: z.number().optional(),
  lastPage: z.number().optional(),
  path: z.string().optional(),
});

export type PaginationType = z.infer<typeof paginationSchema>;
export type ProductsListingType = {
  items: ProductType[];
  meta: PaginationType;
};

const filterDynamicStaticDataItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
});

type FilterDynamicStaticDataItemType = z.infer<typeof filterDynamicStaticDataItemSchema>;

export const imageSchema = z.object({
  id: z.number().nullable().optional(),
  src: z.string(),
  alt: z.string(),
  blurDataURL: z.string().optional(),
});

export const productImageSchema = z.object({
  thumb: imageSchema.nullable(),
  list: imageSchema.array().optional(),
});
export type ProductImageType = z.infer<typeof productImageSchema>;

const productBrandSchema = z.object({
  id: z.number().nullable(),
  name: z.string(),
  image: imageSchema.nullable(),
});
export type ProductBrandType = z.infer<typeof productBrandSchema>;

const productQuantitySchema = z.object({
  value: z.number().nullable(),
  step: z.number().nullable(),
  default: z.number().nullable(),
  available: z.number().nullable(),
});
export type ProductQuantityType = z.infer<typeof productQuantitySchema>;

export const priceSchema = z
  .object({
    value: z.number().nullable(),
    country: z.string(),
  })

  .nullable();
export type PriceType = z.infer<typeof priceSchema>;

const productCriteriaSchema = z.object({
  id: z.number().nullable(),
  value: z.string(),
  param: z.string(),
});
export type ProductCriteriaType = z.infer<typeof productCriteriaSchema>;

const productCriteriesSchema = z.object({
  dynamic: productCriteriaSchema.array().optional(),
  static: productCriteriaSchema.array().optional(),
});
export type ProductCriteriesType = z.infer<typeof productCriteriesSchema>;

const productPromoItemSchema = z.object({
  id: z.number().nullable(),
  value: z.enum([
    ProductPromoItemValueType.empty,
    ProductPromoItemValueType.sale,
    ProductPromoItemValueType.default,
    ProductPromoItemValueType.hot,
    ProductPromoItemValueType.delivery,
  ]),
  label: z.string(),
  image: imageSchema.nullable(),
});
export type ProductPromoLabelType = z.infer<typeof productPromoItemSchema>;

export const productPromoSchema = z.object({
  prime: productPromoItemSchema.nullable(),
  general: productPromoItemSchema.array().optional(),
  stock: productPromoItemSchema.array().optional(),
});
export type ProductPromotionsType = z.infer<typeof productPromoSchema>;

export const productHazardInfoSchema = z.object({
  title: z.string(),
  description: z.string(),
  icons: imageSchema.array(),
});

export type ProductHazardInfoType = z.infer<typeof productHazardInfoSchema>;

const productSafetyPassportSchema = z.object({
  id: z.number(),
  title: z.string(),
  url: z.string(),
});

const productInfoSchema = z.object({
  id: z.number(),
  image: imageSchema,
  label: z.string(),
  value: z.string(),
});
export type ProductInfoType = z.infer<typeof productInfoSchema>;

const productSchema = z.object({
  id: z.number().nullable(),
  genericId: z.number().nullable(),
  articleId: z.number().nullable(),
  articleNo: z.string(),
  images: productImageSchema.nullable(),
  brand: productBrandSchema.nullable(),
  countryOfOrigin: z.string().nullable(), // TODO: remove null in API!
  ean: z.string(),
  quantity: productQuantitySchema.nullable(),
  deliveryAt: z.number().nullable(),
  priceRecommended: priceSchema.nullable(),
  pledge: priceSchema.nullable(),
  margin: z.number().nullable(),
  vatPrice: priceSchema.nullable(),
  priceNonVat: priceSchema.nullable(),
  name: z.string(),
  vatPercentage: z.number().nullable(),
  weight: z.number().nullable(),
  criteria: productCriteriesSchema.nullable(),
  isVinCompatibility: z.boolean(),
  fittingScore: z.number().nullable(),
  promotions: productPromoSchema.nullable(),
  isAvailable: z.boolean(),
  oldVatPrice: priceSchema.nullable(),
  oldPriceNonVat: priceSchema.nullable(),
  hazardInfo: productHazardInfoSchema.nullable(),
  safetyPassports: productSafetyPassportSchema.array(),
  info: productInfoSchema.nullable(),
});
export type ProductType = z.infer<typeof productSchema>;

export const radioObjSchema = z.object({
  id: z.number(),
  label: z.string(),
  value: z.string(),
  count: z.number().optional(),
  isSelected: z.boolean().optional(),
});

export const filterSchema = z.object({
  priceRange: z.array(z.number()).optional(),
  brand: z.array(z.string()).optional(),
  generic: z.array(z.string()).optional(),
  delivery: z.string().optional(),
  priceFrom: z.number().nullable().optional(),
  priceTo: z.number().nullable().optional(),
  nodeId: z.number().optional(),
  installation: z
    .union([radioObjSchema, z.array(radioObjSchema)])
    .nullable()
    .optional(),
  viscosity: z
    .union([radioObjSchema, z.array(radioObjSchema)])
    .nullable()
    .optional(),
  criteria: z
    .array(
      z
        .array(
          criteriaItemSchema
            .or(z.array(criteriaItemSchema.nullable().optional()))
            .nullable()
            .optional(),
        )
        .or(
          z.record(
            z.string(),
            criteriaItemSchema
              .or(z.array(criteriaItemSchema.nullable().optional()))
              .nullable()
              .optional(),
          ),
        )
        .nullable()
        .optional(),
    )
    .default([])
    .nullable()
    .optional(),
  fittingScore: z.string().optional(),
});

export type FilterType = z.infer<typeof filterSchema>;
export type FilterRequestInstallationType = { installation?: string };
export type FilterRequestViscosityType = { viscosity?: string };
export type FilterRequestFittingScoreType = { fittingScore?: string };
export type FilterRequestTyresSelectorType = {
  tyreSeason?: string;
  tyreWidth?: string;
  tyreHeight?: string;
  tyreDiameter?: string;
};

type FilterRequestValuesType =
  | FilterType
  | FilterRequestInstallationType
  | FilterRequestViscosityType
  | FilterRequestFittingScoreType
  | FilterRequestTyresSelectorType;

export type FilterParamsType = {
  filter?: FilterRequestValuesType | null;
  page?: number | string;
  perPage?: number | string;
  sort?: SortType;
};

export type FilterProductsParamsType = FilterParamsType & {
  nodeId?: string | number;
  profileVehicleId?: number;
  keyword?: string;
  oem?: string;
  vin?: string;
  articleId?: string;
};

export type FilterProductOemParamsType = FilterParamsType & {
  profileVehicleId?: number;
  oemNumber?: string;
  forcePositiveFittingScore?: boolean;
};

export type ProductUpdateType = {
  id?: number;
  quantity?: number | null;
  isActive?: boolean;
  articleId?: number;
  productId?: number;
};

export type ProductParamsType = {
  id: number | string;
  params?: FilterProductsParamsType & {
    makerId?: number;
    modelId?: number;
  };
  data?: ProductUpdateType;
};

export type ProductsFetchParams = {
  params: FilterProductsParamsType;
};

export type ProductOemFetchParamsType = {
  params: FilterProductOemParamsType;
};

export type ProductsPageParamsType = Record<string, string | number> | FilterParamsType;

export type ProductQuantitiesType = {
  quantity: ProductQuantityType | null;
  fittingScore: number | null;
};

export type ProductsCartType = {
  articleId: number | null;
  quantity: ProductQuantityType | null;
  fittingScore: number | null;
} | null;

export type ProductOEMType = {
  id: number;
  manufacturerName: string;
  displayNr: string;
};

export type ProductOEMsType = BaseDataListType<ProductOEMType>;
export type ProductCompatibilitiesType = BaseDataListType;

export const PRICE_VAT_VIEW_KEYS = {
  empty: "",
  notVat: "notVat",
  withVat: "withVat",
} as const;

export const priceVatViewSchema = z.enum([
  PRICE_VAT_VIEW_KEYS.empty,
  PRICE_VAT_VIEW_KEYS.notVat,
  PRICE_VAT_VIEW_KEYS.withVat,
]);
export type PriceVatViewType = z.infer<typeof priceVatViewSchema>;

export const PRICE_VAT_SWITCH_KEYS = {
  showPriceVat: "showPriceVat",
} as const;

export const priceVatSwitchSchema = z.object({
  [PRICE_VAT_SWITCH_KEYS.showPriceVat]: priceVatViewSchema,
});

export type PriceVatSwitchType = z.infer<typeof priceVatSwitchSchema>;

export const sortOptionSchema = z.object({
  id: z.number(),
  label: z.string(),
  value: sortSchema,
  isSelected: z.boolean(),
});
export type SortOptionType = z.infer<typeof sortOptionSchema>;

export const sortFilterSchema = z.object({
  title: z.string(),
  data: sortOptionSchema.array(),
});
export type SortFilterType = z.infer<typeof sortFilterSchema>;
