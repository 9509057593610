// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventsDataUnionType,
  EventPayloadOemCatalogBackType,
  EventOemCatalogBackDataType,
} from "client/utils/gtm/gtm.types";
import { ParametersEnum, PayloadKeysEnum, GTMTypesEnum } from "client/utils/gtm/gtm.types";

class OemCatalogBackEvent extends AbstractGTMEventSegment<GTMTypesEnum.oem_catalog_back> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.car_id]: 0,
      [ParametersEnum.car_title]: "",
      [ParametersEnum.car_description]: "",
      [ParametersEnum.vin_number]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadOemCatalogBackType {
    const oemCatalogBackData = data as EventOemCatalogBackDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters] = oemCatalogBackData;

    return segment;
  }
}

export const oemCatalogBackEvent: OemCatalogBackEvent = new OemCatalogBackEvent();
