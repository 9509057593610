// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  RouteNameKeysType,
  EventReplayPaymentDataType,
  EventPayloadReplayPaymentType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  SELECTED_PAYMENT_METHOD_VALUE,
} from "client/utils/gtm/gtm.types";

class ReplayPaymentEvent extends AbstractGTMEventSegment<GTMTypesEnum.replay_payment> {
  protected readonly segmentShape: EventPayloadReplayPaymentType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.route_name]: "" as RouteNameKeysType,
      [ParametersEnum.payment_type]: SELECTED_PAYMENT_METHOD_VALUE.empty,
      [ParametersEnum.transaction_id]: "0",
      [ParametersEnum.shipping_date]: 0,
    },
  };

  makeSegment(data: EventReplayPaymentDataType): EventPayloadReplayPaymentType {
    const { routeName, paymentType, transactionId, shippingDate } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = routeName;
    segment[PayloadKeysEnum.parameters][ParametersEnum.payment_type] =
      SELECTED_PAYMENT_METHOD_VALUE[paymentType];
    segment[PayloadKeysEnum.parameters][ParametersEnum.transaction_id] = String(transactionId);
    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = shippingDate;

    return segment;
  }
}

export const replayPaymentEvent: ReplayPaymentEvent = new ReplayPaymentEvent();
