// Core
import { MouseEvent } from "react";

// Components
import { Image } from "components/ui/Image";
import { Skeleton } from "components/ui/Skeleton";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Icon } from "components/ui/Icon";
import { Text } from "components/ui/Typography/Text";
import { Title } from "components/ui/Typography/Title";

// Hooks
import { useProfileContext } from "hooks/useProfileContext";
import { usePersonalManagerAdviserFetch } from "client/core/personal-manager";
import { useTranslation } from "client/utils/i18n/client";

// Assets
import { default as avatarDefault } from "assets/images/avatar-default.jpg";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { getFormatPhoneNumber } from "client/core/personal-manager/utils/phone-format";

import st from "./styles.module.css";

export const PersonalManagerPopoverUserMenu = () => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { loadingPersonalManagerAdviserFetch, personalManagerAdviser } =
    usePersonalManagerAdviserFetch();

  const onClickContainer = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const countryCode = profile?.countryCode;
  const fullName =
    personalManagerAdviser?.firstName || personalManagerAdviser?.lastName
      ? `${String(personalManagerAdviser?.firstName)} ${String(personalManagerAdviser?.lastName)}`
      : t("common:popovers.personalManager.noName");
  const description =
    personalManagerAdviser?.firstName || personalManagerAdviser?.lastName
      ? t("common:popovers.personalManager.desc")
      : t("common:popovers.personalManager.noData");
  const phone =
    personalManagerAdviser?.phone && profile?.countryCode
      ? getFormatPhoneNumber(String(personalManagerAdviser?.phone), countryCode)
      : t("common:popovers.personalManager.noPhone");
  const email = personalManagerAdviser?.email ?? t("common:popovers.personalManager.noEmail");
  const photo = personalManagerAdviser?.photo ?? avatarDefault.src;

  return (
    <div className={st["personal-manager-popover-user-menu"]} onClick={onClickContainer}>
      {loadingPersonalManagerAdviserFetch ? (
        <Skeleton avatar={{ size: 64 }} paragraph={{ rows: 3 }} active />
      ) : (
        <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
          <Col span={5}>
            <div className={st.image}>
              <Image src={photo} alt={fullName} objectFit="contain" width={64} height={64} />
            </div>
          </Col>
          <Col span={19}>
            <div className={st.content}>
              <div className={st.title}>
                <Title tag="h5" size="16">
                  {fullName}
                </Title>
              </div>

              <div className={st.desc}>
                <Text color="gray-700" size="14">
                  {description}
                </Text>
              </div>

              <ul className={st.info}>
                <li className={st["info-item"]}>
                  <Icon name="Envelop" className={st.icon} />
                  <span>{email}</span>
                </li>
                <li className={st["info-item"]}>
                  <Icon name="Phone" className={st.icon} size="mini" />
                  <span>{phone}</span>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
