// Core
import { cloneElement, Fragment, useMemo } from "react";
import { useTranslations as useNextIntlTranslations } from "next-intl";

// Utils
import { formatTranslationKey } from "../lib/utils";

// Definitions
import type { TranslationValues } from "../types";

type ComponentCallBack = (chunks: React.ReactNode) => React.ReactNode;

type ComponentsRenderCallbacks = {
  [key: string]: ComponentCallBack;
};

type TransComponentsProp = Record<string, ComponentCallBack | React.ReactElement>;

const prepareComponentsCallbacks = (components?: TransComponentsProp) => {
  if (!components) return undefined;

  return Object.keys(components).reduce((acc, key) => {
    const component = components[key];

    if (typeof component === "function") {
      return {
        ...acc,
        [key]: component,
      };
    }

    return {
      ...acc,
      [key]: (chunks) => cloneElement(component, undefined, chunks),
    };
  }, {} as ComponentsRenderCallbacks);
};

export const Trans = ({
  i18nKey,
  components,
  values = {},
}: {
  i18nKey: string;
  components?: TransComponentsProp;
  values?: TranslationValues;
}) => {
  const t = useNextIntlTranslations();

  const componentsCallbacks = useMemo(() => prepareComponentsCallbacks(components), [components]);

  const richTranslationValues = {
    ...componentsCallbacks,
    ...values,
  };

  return <Fragment>{t.rich(formatTranslationKey(i18nKey), richTranslationValues)}</Fragment>;
};
