// Definitions
import { GTMTypesEnum } from "client/utils/gtm/gtm.types";
import type { NotificationActionType } from "client/utils/gtm/gtm.types";

// Utils
import { gtmService } from "client/utils/gtm";

type EventNotificationPageDataType = {
  totalNotifications: number;
  totalUnreadNotifications: number;
};

export const triggerNotificationPageGtmEvent = (eventData: EventNotificationPageDataType) => {
  const { totalNotifications, totalUnreadNotifications } = eventData;

  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.notification_page, {
    totalNotifications,
    totalUnreadNotifications,
  });
};

type EventNotificationDataType = {
  actionType: NotificationActionType;
  notificationType?: number;
  notificationId?: number;
};

export const triggerNotificationGtmEvent = (eventData: EventNotificationDataType) => {
  const { actionType, notificationType, notificationId } = eventData;

  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.notification, {
    actionType,
    notificationType,
    notificationId,
  });
};
