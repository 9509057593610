// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { WrapperPrice } from "components/common/WrapperPrice";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";

import st from "./styles.module.css";

type CartBreakdownLineProps = {
  label?: string;
  price?: JSX.Element | null;
  testId?: string;
};

const CartBreakdownLine = (props: CartBreakdownLineProps) => {
  const { label, price, testId } = props;

  return (
    <Row
      gutter={ROW_GUTTER_TYPE.md.horizontal}
      align="middle"
      justify="space-between"
      data-test-id={testId}
    >
      <Col span={14}>
        <Text box="full" size="14" color="gray-900" font="system" fontWeight="400">
          {label}
        </Text>
      </Col>
      {price && (
        <Col span={10}>
          <Text
            tag="div"
            box="full"
            size="14"
            color="gray-900"
            font="system"
            fontWeight="400"
            alignmentHorizontal="right"
          >
            {price}
          </Text>
        </Col>
      )}
    </Row>
  );
};

type CartBreakdownTotalProps = {
  loading?: boolean;
  textTitle?: string;
  totalPrice?: number | null;
  countryCode?: string;
  textTotalInclVat?: string;
  totalPriceNotVat?: JSX.Element;
  vatPrice?: JSX.Element;
  testId?: string;
};
export const CartBreakdownTotal = (props: CartBreakdownTotalProps) => {
  const {
    textTitle = "",
    loading,
    textTotalInclVat,
    totalPrice,
    countryCode,
    totalPriceNotVat,
    vatPrice,
    testId,
  } = props;

  return (
    <div className={st["breakdown-total"]}>
      {textTitle && (
        <Title
          tag="h5"
          size="16"
          color="gray-900"
          font="system"
          fontWeight="600"
          className={st["breakdown-total-title"]}
        >
          {textTitle}
        </Title>
      )}
      <div className={st["breakdown-total-price"]}>
        {totalPriceNotVat}
        {vatPrice}
        <Row gutter={ROW_GUTTER_TYPE.md.horizontal} align="middle" justify="space-between">
          <Col span={14}>
            <Text box="full" size="20" color="gray-900" font="system" fontWeight="600">
              {textTotalInclVat}
            </Text>
          </Col>
          <Col span={10}>
            <Text
              tag="div"
              box="full"
              size="20"
              color="gray-900"
              font="system"
              fontWeight="600"
              alignmentHorizontal="right"
            >
              <WrapperPrice
                country={countryCode}
                loading={loading}
                value={totalPrice}
                testId={testId}
              />
            </Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

CartBreakdownTotal.Line = CartBreakdownLine;
