// Components
import { Image } from "components/ui/Image";

// Utils
import st from "./styles.module.css";

const imageLogoDarkSrc = "/assets/images/logo.svg";

type CartServiceCardProps = {
  src?: string;
  width?: number;
  height?: number;
  alt?: string;
};

export const CartServiceCard = (props: CartServiceCardProps) => {
  const { src = imageLogoDarkSrc, alt = "", width = 72, height = 32 } = props;

  return (
    <div className={st["cart-service-card"]} data-test-id="payment-service-card">
      <Image src={src} width={width} height={height} alt={alt} />
    </div>
  );
};
