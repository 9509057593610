// Definitions
import type { LightListItemType } from "components/ui/LightList";
import type { LinkProps } from "components/common/Link";

// Components
import { Link } from "components/common/Link";
import { PopoverUserNavbar } from "components/common/PopoverViews/UserNavbar";
import { PersonalManagerPopoverUserMenu } from "client/core/personal-manager";
import { NotificationsNavbar } from "client/core/notifications/components/notifications-navbar/notifications-navbar";

// Utils
import { book } from "init/book";

type ProfileActionModelProps = {
  loadingCartProductsCount?: boolean;
  loadingNotificationsFetch?: boolean;
  cartProductsCount?: number | null;
  notificationsCount?: number | null;
  onEvent: () => void;
};
export const profileActionModel = (props: ProfileActionModelProps): LightListItemType[] => {
  const {
    cartProductsCount = null,
    notificationsCount = null,
    loadingCartProductsCount,
    loadingNotificationsFetch,
    onEvent,
  } = props;
  const NotificationCellLink = (linkProps: LinkProps) =>
    Link({
      ...linkProps,
      type: "default",
      onClick: onEvent,
    });

  const CellLink = (linkProps: LinkProps) =>
    Link({
      ...linkProps,
      type: "default",
    });

  return [
    {
      key: "1",
      icon: "Bell",
      link: {
        href: book.notifications,
        component: NotificationCellLink,
      },
      badge: { count: Number(notificationsCount), offset: [-3, 6] },
      loading: loadingNotificationsFetch,
      popover: {
        component: NotificationsNavbar,
        trigger: "hover",
        placement: "bottom",
        destroyTooltipOnHide: true,
      },
    },
    {
      key: "2",
      icon: "User",
      link: {
        href: book.settings,
        component: CellLink,
      },

      popover: {
        component: PopoverUserNavbar,
        trigger: "hover",
        placement: "bottom",
      },
    },
    {
      key: "3",
      icon: "Headphones",
      link: {
        href: book.personalManager,
        component: CellLink,
      },

      popover: {
        component: PersonalManagerPopoverUserMenu,
        trigger: "hover",
        placement: "bottom",
      },
    },
    {
      key: "4",
      icon: "Cart",
      link: {
        href: book.cartPreview,
        component: CellLink,
      },
      badge: { count: Number(cartProductsCount), offset: [-3, 6] },
      loading: loadingCartProductsCount,
    },
  ];
};
