// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventsDataUnionType,
  EventPayloadEmptyOemPageType,
  EventEmptyOemPageDataType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  PAGE_VARIANT_TYPE,
} from "client/utils/gtm/gtm.types";

class EmptyOemPageEvent extends AbstractGTMEventSegment<GTMTypesEnum.empty_oem_page> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.car_id]: 0,
      [ParametersEnum.car_title]: "",
      [ParametersEnum.car_description]: "",
      [ParametersEnum.vin_number]: "",
      [ParametersEnum.page_type]: PAGE_VARIANT_TYPE.categories,
      [ParametersEnum.url_path]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadEmptyOemPageType {
    const emptyOemPageData = data as EventEmptyOemPageDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters] = emptyOemPageData;

    return segment;
  }
}

export const emptyOemPageEvent: EmptyOemPageEvent = new EmptyOemPageEvent();
