// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  EventPayloadPageLoadType,
  EventPageLoadDataType,
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class PageLoadEvent extends AbstractGTMEventSegment<GTMTypesEnum.pageLoad> {
  protected readonly segmentShape = null;

  makeSegment(data: EventsDataUnionType): EventPayloadPageLoadType {
    const pageLoadData = data as EventPageLoadDataType;
    const segment = this.getSegment();

    return {
      [PayloadKeysEnum.parameters]: {
        ...segment?.[PayloadKeysEnum.parameters],
        ...(pageLoadData[ParametersEnum.user_id] && {
          [ParametersEnum.user_id]: pageLoadData[ParametersEnum.user_id],
        }),
        ...(pageLoadData[ParametersEnum.crm_session_id] && {
          [ParametersEnum.crm_session_id]: pageLoadData[ParametersEnum.crm_session_id],
        }),
        ...(pageLoadData[ParametersEnum.visitor_type] && {
          [ParametersEnum.visitor_type]: pageLoadData[ParametersEnum.visitor_type],
        }),
        ...(pageLoadData[ParametersEnum.geo_country_code] && {
          [ParametersEnum.geo_country_code]: pageLoadData[ParametersEnum.geo_country_code],
        }),
        ...(pageLoadData[ParametersEnum.route_name] && {
          [ParametersEnum.route_name]: pageLoadData[ParametersEnum.route_name],
        }),
      },
    };
  }
}

export const pageLoadEvent: PageLoadEvent = new PageLoadEvent();
