// Core
import { PropsWithChildren } from "react";

// Utils
import st from "./styles.module.css";

type CartContentLayoutProps = PropsWithChildren<{
  testId?: string;
}>;

export const CartContentLayout = ({ testId, children }: CartContentLayoutProps) => {
  return (
    <div className={st["cart-content-layout"]} {...(testId && { "data-test-id": testId })}>
      {children}
    </div>
  );
};
