export const book = Object.freeze({
  home: "/",
  forbidden: "/403",
  notFound: "/404",
  serverError: "/500",
  about: "/about",
  paymentMethods: "/settings/payment-methods",
  benefits: "/benefits",
  benefitsTermsConditions: "/benefits-terms-conditions",
  cartPreview: "/cart/preview",
  catalog: "/catalog",
  catalogGraphic: "/graphic",
  subCategories: "/sub-categories",
  categories: "/categories",
  subChildCategories: "/sub-child-categories",
  schemas: "/schemas",
  schemaOemGroups: "/oem-groups",
  products: "/products",
  productsOem: "/products-oem",
  productsSearch: "/products-search",
  companyAndAddress: "/settings/company-address",
  companyInfo: "/company-information",
  companyInformation: "/company-information",
  companyWorkingHours: "/settings/company-working-hours",
  confirmPromoSubscription: "confirm_promo_subscription",
  coreExchange: "/core-exchange",
  customers: "/customers",
  dashboard: "/dashboard",
  delivery: "/delivery",
  depositAccount: "/settings/deposit-account",
  estimateSettings: "/settings/estimate-settings",
  helpCentre: "/help-centre",
  language: "/settings/language",
  loginAndPasswords: "/settings/change-password",
  logout: "/logout",
  loyaltyPgm: "/settings/loyalty-program",
  mandate: "/mandate",
  notifications: "/notifications",
  orders: "/orders",
  ordersCheckout: "/orders/checkout",
  ordersDelivery: "/orders/delivery",
  paymentDescriptions: "/payment-descriptions",
  personalManager: "/personal-manager",
  privacyPolicy: "/privacy-policy",
  profile: "/profile",
  promotions: "/promotions",
  repairEstimates: "/repair-estimates",
  representativeContact: "/settings/representative-contact",
  resetPassword: "/reset-password",
  returns: "/returns",
  returnsAndRefunds: "/returns-and-refunds",
  returnsConfirm: "/returns/new-confirm",
  returnsDraft: "/returns/new-draft",
  savedBaskets: "/saved-baskets",
  settings: "/settings",
  signin: "/signin",
  signup: "/signup",
  signupProfile: "/signup/profile",
  termConditions: "/term-conditions",
  users: "/settings/users",
  withdrawal: "/withdrawal",
  documents: "/documents",
  orderSupercharge: "/payment/add",
}) satisfies Readonly<Record<string, string>>;

export const globalBook: {
  VAT: Record<string, string>;
  nps: Record<string, string>;
  capsules: Record<string, string>;
} = Object.freeze({
  VAT: {
    fr: "https://www.auto-doc.fr/",
    de: "https://www.autodoc.de/",
    at: "https://www.auto-doc.at/",
    nl: "https://www.autodoc.nl/",
  },
  nps: {
    fr: "https://docs.google.com/forms/d/1vvwQLJWPn7KZg6r3QIDEf_XrnFV2Gj6xNcttocOMjrg/viewform",
    en: "https://docs.google.com/forms/d/1vvwQLJWPn7KZg6r3QIDEf_XrnFV2Gj6xNcttocOMjrg/viewform",
    de: "https://docs.google.com/forms/d/17iUe6p0qwzkEgF-gkByLiQEHqti8Xw2Pcc8bg7DKxQw/viewform",
    at: "https://docs.google.com/forms/d/1dp_L5LXdNX3T6scx85MnB-qQrbnoP-O-eARkxytEThA/viewform",
    nl: "https://docs.google.com/forms/d/1xFqkzpXYNYOgbUR7YYnX9Jg0NdeRvglf7UCq_Y2zSJA/viewform",
  },
  capsules: {
    en: "https://docs.google.com/forms/d/e/1FAIpQLSfkHoJCOcnfIECGmgv9X7KXTvY_W0lB_xJnXvVsQAuupEKcPw/viewform",
    fr: "https://docs.google.com/forms/d/e/1FAIpQLSfkHoJCOcnfIECGmgv9X7KXTvY_W0lB_xJnXvVsQAuupEKcPw/viewform",
  },
});

export const emailsBook = Object.freeze({
  helpService: "info@autodoc.pro",
});
