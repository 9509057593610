// Core
import { Trans } from "next-i18next";
import cx from "classnames";

// Components
import { Skeleton } from "antd";
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";

// Utils
import { CurrencyFormat } from "utils/currencyFormat/currencyFormat";
import { currencyConfig } from "utils/currencyFormat/country-config";
import { getVatSignByCountry } from "utils/countries";
import st from "./Styles.module.less";

type WrapperPricePropsType = {
  value?: number | null;
  isEmpty?: boolean;
  isNumber?: boolean;
  skeletonSize?: "large" | "small" | "default";
  loading?: boolean;
  disabled?: boolean;
  country?: string;
  hasVatSign?: boolean;
  hasVatSignInvert?: boolean;
  viewPriceVat?: "notVat" | "withVat" | "";
  testId?: string;
  kmtxId?: string;
};

export const wrapperPriceFormat = (val: number | null, country: string) => {
  if (Number.isNaN(val)) {
    return WrapperNodeDefault();
  }

  return new CurrencyFormat({
    ...currencyConfig[country.toLocaleUpperCase()],
  }).toFormat(val);
};

const vatPostfixModel = {
  notVat: "common:notVat",
  withVat: "common:withVat",
};

export const WrapperPrice = (props: WrapperPricePropsType) => {
  const {
    value = null,
    isEmpty = false,
    loading = false,
    skeletonSize = "small",
    disabled,
    hasVatSign = false,
    hasVatSignInvert = false,
    viewPriceVat,
    country = "",
    testId = "wrapper-price",
    kmtxId = "",
  } = props;
  const price = wrapperPriceFormat(value, country);

  const vatPostfixByCountry = getVatSignByCountry(country?.toLocaleUpperCase(), hasVatSignInvert);
  const vatPostfix = viewPriceVat ? vatPostfixModel[viewPriceVat] : vatPostfixByCountry;

  const priceStyles = cx(st["wrapper-price"], {
    [st.disabled]: disabled,
  });

  if (loading) {
    return <Skeleton.Input active className={st.skeleton} size={skeletonSize} />;
  }

  if (!value && isEmpty) {
    return WrapperNodeDefault();
  }

  return (
    <span className={priceStyles} data-test-id={testId}>
      <span className={st.price} {...(kmtxId && { "data-kmtx-article-price": kmtxId })}>
        {price}
      </span>
      {(hasVatSign || hasVatSignInvert) && (
        <span className={st.postfix}>
          <Trans i18nKey={vatPostfix} />
        </span>
      )}
    </span>
  );
};
