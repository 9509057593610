// Core
import { z } from "zod";

// Definitions
import type { TFunction } from "client/utils/i18n/types";

// Utils
import { getFieldLabel } from "client/core/cart/utils/common";

export const CART_PREVIEW_PRODUCT_FIELD_TYPE = {
  quantity: "quantity",
} as const;

const errorReport = {
  required: "inputs:errorMessages.required",
};

export const inputConfig = {
  [CART_PREVIEW_PRODUCT_FIELD_TYPE.quantity]: {
    label: getFieldLabel("quantity"),
    placeholder: getFieldLabel("quantity", true),
    required: errorReport.required,
  },
};

const cartPreviewProductsShape = {
  [CART_PREVIEW_PRODUCT_FIELD_TYPE.quantity]: null,
};

export const cartPreviewProductsForm = {
  shape: cartPreviewProductsShape,
  schema: (t: TFunction) =>
    z.object({
      [CART_PREVIEW_PRODUCT_FIELD_TYPE.quantity]: z
        .object({
          value: z
            .number({
              required_error: t(inputConfig[CART_PREVIEW_PRODUCT_FIELD_TYPE.quantity].required),
            })
            .nullable(),
          default: z
            .number({
              required_error: t(inputConfig[CART_PREVIEW_PRODUCT_FIELD_TYPE.quantity].required),
            })
            .nullable(),
          step: z
            .number({
              required_error: t(inputConfig[CART_PREVIEW_PRODUCT_FIELD_TYPE.quantity].required),
            })
            .nullable(),
        })
        .nullable(),
    }),
};
