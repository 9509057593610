// Core
import { i18n } from "next-i18next";
import dayjs, { Dayjs } from "dayjs";
import QS from "qs";

// Definition
import type { ApiErrorType, ServerErrorFormatType, ServerErrorRawFormatType } from "models/Http";
import type { MenuData } from "data/footer";
import type { TFunction, TranslationValues } from "client/utils/i18n/types";

// Utils
import { ERRORS_VALIDATION_KEY } from "utils/constants";
import { mountingSides } from "data/mountingSides";
import { logger } from "utils/logger";
import { QS_QUERY_CONFIG } from "utils/constants";

export const composeGmailLink = (mailTo: string): string => {
  return `https://mail.google.com/mail/u/0/?view=cm&source=mailto&to=${mailTo}`;
};

export const checkTypeApiError = (value: unknown) => value instanceof Object && "error" in value;

export const isApiError = (
  value: unknown,
  status: number | null = null,
  route = "",
): value is ApiErrorType => {
  status &&
    logger.browserReactQueryInformationLogger.info("API error. status:", status, "route:", route);
  return checkTypeApiError(value);
};

export const getUniqueID = (): string => {
  const chr4 = () => Math.random().toString(16).slice(-4);
  return `${chr4()}-${chr4()}-${chr4()}`;
};

export const getFormatErrorValidation = (
  error: ServerErrorRawFormatType,
): ServerErrorFormatType => {
  if (!error.errors) {
    return {
      ...error,
      errors: void 0,
    };
  }
  const errFieldsArr = Object.entries(error.errors);

  const newFields = errFieldsArr.reduce((acc, item) => {
    const key = item[0];
    const details = item[1];
    const message = i18n?.t(`${ERRORS_VALIDATION_KEY}${details.alias}`) || details.desc;

    return {
      ...acc,
      [key]: message,
    };
  }, {});

  return {
    ...error,
    errors: newFields,
  };
};

export const getHeader = (
  main: string,
  key: string,
  t: TFunction,
  options?: TranslationValues,
): string => {
  return t(`${main}.${key}`, options);
};

export const getVisibleList = <T>(items: T[], count: number): T[] => {
  return items.length <= count ? items : items.slice(0, count);
};

export const getHiddenList = <T>(items: T[], count: number): T[] => {
  return items.slice(count, items.length);
};

export const getTextFromArrayWithSeparator = (
  arr: (string | undefined | null)[],
  separator: string,
) => arr.filter((item) => item).join(separator);

export const getValueFromField = (field: Dayjs | null, valueName: string) => {
  const data = dayjs(field).isValid() ? dayjs(field).format(`${valueName}`) : 0;
  return Number(data);
};

export const disabledTime = (startValue: Dayjs | null, endValue: Dayjs | null, minuteStep = 30) => {
  return {
    disabledHours: () => {
      if (getValueFromField(startValue, "H") === 0) return [];
      if (getValueFromField(startValue, "m") + Number(minuteStep) >= 60)
        return Array.from(Array(Number(getValueFromField(startValue, "H") + 1)).keys());
      return Array.from(Array(getValueFromField(startValue, "H")).keys());
    },
    disabledMinutes: () => {
      if (
        getValueFromField(startValue, "H") === getValueFromField(endValue, "H") &&
        getValueFromField(startValue, "H") !== 0
      )
        return Array.from(Array(Number(getValueFromField(startValue, "m") + 1)).keys());
      return [];
    },
  };
};

export const getMountingIconByValue = (value: string): string | null => {
  const mountingSide = mountingSides.find((item) => {
    return item.value === value;
  });
  return mountingSide?.iconName || null;
};

export const calculatePercentageOfNumber = (expValue: number, diffValue: number): number => {
  const currentPercentSum = ((expValue - diffValue) / expValue) * 100;
  return Math.round(currentPercentSum * 100) / 100;
};

export const isEmail = (email: string): boolean => new RegExp("^[^><]+@[^><]+$").test(email);

export const maskEmail = (email: string): string => {
  if (!isEmail(email)) {
    return email;
  }

  const [user, domain] = email.split("@");
  const userLen = user.length;

  if (userLen < 2) {
    return ["***", domain].join("@");
  }

  const numCharsToSpare = userLen > 4 ? 2 : 1;
  const rx = new RegExp(`^([\\s\\S]{${numCharsToSpare}})(.*)([\\s\\S]{${numCharsToSpare}})$`);

  return [
    user.replace(rx, (_, a: string, b: string, c: string) => `${a}...${b ? c : ""}`),
    domain,
  ].join("@");
};

export const getDateFromTimestamp = (params: {
  timestamp: number;
  format: string;
  locale: string;
}): string => {
  const { timestamp, format, locale = "" } = params;
  const valueWithLocale = dayjs.unix(timestamp).locale(locale).format(format);
  const valueNoLocale = dayjs.unix(timestamp).format(format);
  const renderValue = locale ? valueWithLocale : valueNoLocale;

  return dayjs(timestamp).isValid() ? renderValue : "";
};

export const formatByPattern = (value: string | number, pattern: string) => {
  let currentIdx = 0;
  const values = value.toString().split("");
  return pattern.replace(/#/g, () => {
    const char = values[currentIdx];
    currentIdx += 1;
    return char ? char : "";
  });
};

export const getFilteredMenuItems = (data: MenuData, pages?: string[]): MenuData => {
  if (!pages || !pages.length) {
    return data;
  }
  const filteredItems = data.items.filter((item) => !pages.includes(String(item.href)));
  return { ...data, items: filteredItems };
};

export const getEncodeParams = (values: Record<string, unknown>) => {
  return QS.stringify(values, QS_QUERY_CONFIG);
};
