// Core
import { useCallback } from "react";
import Router from "next/router";

// Definitions
import type { FormSubmitFn } from "models/Forms";
import { UserCountryCodesEnum, UserLanguagesEnum } from "bus/user/models";
import type { UserChangeCountrySelectorPayload } from "bus/user/models";

// Hooks
import { useGeoContext } from "hooks/useGeoContext";

// Utils
import { USER_COUNTRY_COOKIE_KEY } from "utils/constants";
import { cookiesService } from "utils/cookies";
import { getLocaleByCountry } from "utils/locales";

type OnSetUserCountryStorageCbType = (
  country: UserCountryCodesEnum,
  language: UserLanguagesEnum,
) => void;
type OnUpdateUserCountryCbType = FormSubmitFn<UserChangeCountrySelectorPayload>;
type OnUpdateUserCountrybyPhoneCbProps = {
  country: UserCountryCodesEnum;
};
type OnUpdateUserCountrybyPhoneCbType = (value: OnUpdateUserCountrybyPhoneCbProps) => void;
type UseUserCountryType = {
  userCountry?: UserCountryCodesEnum;
  onUpdateUserCountry: OnUpdateUserCountryCbType;
  onUpdateUserCountryByPhone: OnUpdateUserCountrybyPhoneCbType;
};

export const useUserCountry = (): UseUserCountryType => {
  const { geo, setGeo } = useGeoContext();
  const country = geo?.country || void 0;

  const onSetUserCountry = useCallback<OnSetUserCountryStorageCbType>((countryCode, language) => {
    cookiesService.set(USER_COUNTRY_COOKIE_KEY, countryCode);
    setGeo({ country: countryCode, language });
  }, []);

  const onUpdateUserCountry = useCallback<OnUpdateUserCountryCbType>(({ values }) => {
    if (!values.country) {
      throw new Error("Update user country flow error");
    }

    const { locale: newLocale, language } = getLocaleByCountry(values.country?.value);

    onSetUserCountry(values.country.value, language);
    void (async () => {
      const destination = {
        pathname: Router.pathname,
        query: {
          ...Router.query,
        },
      };
      await Router.replace(destination, void 0, { locale: newLocale });
    })();
  }, []);

  const onUpdateUserCountryByPhone = useCallback<OnUpdateUserCountrybyPhoneCbType>((data) => {
    if (!data.country) {
      throw new Error("Update user country flow error");
    }

    const { locale: newLocale, language } = getLocaleByCountry(data.country);

    onSetUserCountry(data.country, language);
    void (async () => {
      const destination = {
        pathname: Router.pathname,
        query: {
          ...Router.query,
        },
      };
      await Router.replace(destination, void 0, { locale: newLocale });
    })();
  }, []);

  return {
    userCountry: country,
    onUpdateUserCountry,
    onUpdateUserCountryByPhone,
  };
};
