// Core
import getConfig from "next/config";

const publicRuntimeConfig = getConfig()?.publicRuntimeConfig;

const BUILD_ENV = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

export const verifyBuild = (): {
  isDevelopment: boolean;
  isProduction: boolean;
} => {
  const isDev = publicRuntimeConfig?.BUILD_ENV === BUILD_ENV.DEVELOPMENT;
  const isProduction = publicRuntimeConfig?.BUILD_ENV === BUILD_ENV.PRODUCTION;

  return {
    isDevelopment: isDev,
    isProduction,
  };
};

export const isBuildDevelopmentSSR = process.env.BUILD_ENV === "development";
