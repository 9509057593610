// Core
import { useContext } from "react";

// Components
import { Drawer } from "components/ui/Drawer";
import { DynamicImport } from "components/common/DynamicImport/DynamicImport";
import { Title } from "components/ui/Typography/Title";
import { CartPreviewProductAlternativeRow } from "client/core/cart/components/cart-preview-product-alternative-row";
import { CartPreviewProductAlternativesCellCustom } from "client/core/cart/components/cart-preview-product-alternatives-cell-custom";

// Definitions
import type { CommonTableProps } from "components/common/CommonTableAnt";
import type { FilterParamsType } from "client/core/cart/utils/filter";
import type { CartProductAlternative, ProductHazardInfoType } from "client/core/cart/models";

// Hooks
import { useCartPreviewProductAlternativesFetch } from "client/core/cart/hooks/use-cart-preview-product-alternatives-fetch";
import { useProfileContext } from "hooks/useProfileContext";
// TODO: add own permission for show PriceVat from product-alternatives entity
import { useCartPreviewPriceVatAbility } from "client/core/cart/hooks/use-cart-preview-price-vat-ability";
import { useModalContext } from "hooks/useModalContext";
import { useTranslation } from "client/utils/i18n/client";

// Contexts
import { useCartPreviewProductAlternativesStore } from "client/core/cart/hooks/use-cart-preview-product-alternative-store";
import { SettingsPriceVatContext } from "client/core/settings-price-vat/context";

// Utils
import { getFinalTableQuery } from "client/core/cart/utils/filter";
import { getCartPreviewProductAlternativesColumns } from "./render-model";
import { GET_ENUMS } from "utils/constants";

const CommonTableAntDynamic = DynamicImport<CommonTableProps<CartProductAlternative>>(
  import("components/common/CommonTableAnt"),
  "CommonTableAnt",
);

const CartPreviewProductAlternativesList = () => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { canSwitchVat } = useCartPreviewPriceVatAbility();
  const { priceVatCartPreview: viewPriceVat } = useContext(SettingsPriceVatContext);
  const { productAlternativesParams: paramsStore, onChange } =
    useCartPreviewProductAlternativesStore();
  const fetchParams = {
    ...(paramsStore?.productId && { productId: paramsStore.productId }),
    ...(paramsStore?.vehicleId && { vehicleId: paramsStore.vehicleId }),
    ...(paramsStore?.articleId && { articleId: paramsStore.articleId }),
    ...(paramsStore?.sort && { sort: paramsStore.sort }),
    ...(paramsStore?.page && { page: paramsStore.page }),
    ...(paramsStore?.perPage && { perPage: paramsStore.perPage }),
  };
  const { loadingProductsAlternativesFetch, productsAlternatives, params } =
    useCartPreviewProductAlternativesFetch({ params: fetchParams });

  const { setOnPageModal } = useModalContext();

  const handleChangeTable = (data: FilterParamsType) => {
    const filterData = getFinalTableQuery(data, productsAlternatives.meta);
    onChange(filterData);
  };

  const handleClickProductHazardInfo = (hazardInfo: ProductHazardInfoType) => {
    setOnPageModal(GET_ENUMS.popup.cartHazardInfo, {
      visible: true,
      params: {
        hazardInfo,
      },
    });
  };

  const columns = getCartPreviewProductAlternativesColumns({
    t,
    onClickProductHazardInfo: handleClickProductHazardInfo,
    country: profile?.countryCode,
    canSwitchVat,
    ...(canSwitchVat && { viewPriceVat }),
  });

  return (
    <>
      <CommonTableAntDynamic
        loading={loadingProductsAlternativesFetch}
        data={productsAlternatives?.items}
        columns={columns}
        params={{ ...params, pagination: productsAlternatives?.meta }}
        customRow={CartPreviewProductAlternativeRow}
        customCell={CartPreviewProductAlternativesCellCustom}
        onTableUpdate={handleChangeTable}
        testId={"cart-preview-product-alternatives"}
      />
    </>
  );
};

export const CartPreviewProductAlternatives = () => {
  const { t } = useTranslation();
  const { visibleProductAlternatives, onClose } = useCartPreviewProductAlternativesStore();

  return (
    <Drawer
      title={
        <Title tag="h3" size="24" color="gray-900" fontWeight="600">
          {t("cart:productAlternatives.title")}
        </Title>
      }
      placement="right"
      onClose={onClose}
      visible={visibleProductAlternatives}
    >
      <CartPreviewProductAlternativesList />
    </Drawer>
  );
};
