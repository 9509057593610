// Definitions
import type { CartCountType } from "client/core/cart/models";

// Domains
import { cartKeys } from "client/core/cart/store";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { cartService } from "client/core/cart/service";

type UseCartCountFetchType = {
  loadingCartCountFetch: boolean;
  cartCount: number | null;
};

export const useCartCountFetch = (): UseCartCountFetchType => {
  const fetchCartProps = useQueryAsync<CartCountType, CartCountType, [string] | string[]>({
    key: [cartKeys.previewCount],
    name: "fetchCartPreviewCount",
    fetcher: cartService.fetchCartPreviewCount,
    options: {
      refetchOnMount: "always",
    },
  });

  return {
    loadingCartCountFetch: fetchCartProps.isLoading || fetchCartProps.isRefetching,
    cartCount: fetchCartProps.data?.quantity || null,
  };
};
