// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventsDataUnionType,
  EventPayloadOemCategoryClickType,
  EventOemCategoryClickDataType,
} from "client/utils/gtm/gtm.types";
import { ParametersEnum, PayloadKeysEnum, GTMTypesEnum } from "client/utils/gtm/gtm.types";

class OemCategoryClickEvent extends AbstractGTMEventSegment<GTMTypesEnum.oem_category_click> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.category_id]: "",
      [ParametersEnum.category_name]: "",
      [ParametersEnum.category_image]: false,
      [ParametersEnum.car_id]: 0,
      [ParametersEnum.car_title]: "",
      [ParametersEnum.car_description]: "",
      [ParametersEnum.vin_number]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadOemCategoryClickType {
    const oemCategoryClickData = data as EventOemCategoryClickDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters] = oemCategoryClickData;

    return segment;
  }
}

export const oemCategoryClickEvent: OemCategoryClickEvent = new OemCategoryClickEvent();
