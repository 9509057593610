// Definitions
import type { MenuPropsType } from "components/ui/Menu";
import { HEADER_MENU_ITEM } from "client/utils/gtm/gtm.types";
import type { HeaderMenuItemType } from "client/utils/gtm/gtm.types";

// Components
import { Menu } from "components/ui/Menu";

// Utils
import st from "./Styles.module.less";
import { triggerHeaderMenuGtmEvent } from "utils/header-gtm-events";

type NavbarProps = {
  menuItems: MenuPropsType["items"];
};

export const Navbar = ({ menuItems }: NavbarProps) => {
  const handleTrackHeaderLinkClick: MenuPropsType["onClick"] = ({ key }) => {
    const keyParts = key.split("-");
    const actionType =
      (keyParts[keyParts.length - 1] as HeaderMenuItemType) || HEADER_MENU_ITEM.empty;
    triggerHeaderMenuGtmEvent({ actionType });
  };

  return (
    <nav className={st.navbar} data-test-id="navigation">
      <Menu items={menuItems} mode="horizontal" theme="dark" onClick={handleTrackHeaderLinkClick} />
    </nav>
  );
};
