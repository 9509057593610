// Definitions
import { GTMTypesEnum } from "client/utils/gtm/gtm.types";
import type { HeaderMenuItemType } from "client/utils/gtm/gtm.types";

// Utils
import { gtmService } from "client/utils/gtm";

type EventHeaderMenuDataType = {
  actionType: HeaderMenuItemType;
};

export const triggerHeaderMenuGtmEvent = (data: EventHeaderMenuDataType) => {
  const { actionType } = data;
  gtmService?.event(GTMTypesEnum.ecommerce_clear, null);
  gtmService?.event(GTMTypesEnum.header_menu, { actionType });
};
