// Core
import { useState } from "react";
import NextImage from "next/legacy/image";

// Definitions
import type { ImageProps as NextImageProps, ImageLoaderProps } from "next/legacy/image";
import type { ImageType } from "./Image.types";

// Utils
import { noImage } from "utils/constants";

export type ImagePropsType = NextImageProps & ImageType;

export const Image = (props: ImagePropsType) => {
  const { src, alt, externalUrl = false, testId, ...rest } = props;

  const [imgSrc, setImgSrc] = useState<ImageType["src"]>(src);

  const urlWithWidthParameter = (url: string, width: number) => {
    const separator = url.includes("?") ? "&" : "?";
    return url + separator + `w=${width}`;
  };

  const loaderProp = ({ src: loaderSrc, width }: ImageLoaderProps) => {
    return externalUrl ? loaderSrc : urlWithWidthParameter(loaderSrc, width);
  };

  return (
    <NextImage
      {...rest}
      alt={alt}
      src={imgSrc}
      quality="95"
      loader={loaderProp}
      data-test-id={testId}
      onError={() => setImgSrc(noImage.src)}
    />
  );
};
