// Components
import { Col } from "components/ui/Col";
import { IndicatorCircle } from "components/ui/IndicatorCircle";
import { Row } from "components/ui/Row";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";
import { WrapperDateDefault } from "components/common/WrapperDateDefault";
import { DecorDivider } from "components/common/DecorDivider";

// Definitions
import type { NotificationType } from "client/core/notifications/models";
import { NOTIFICATION_TYPE_ID, NOTIFICATION_SUB_TYPE_ID } from "client/core/notifications/models";
import { NOTIFICATION_ACTION_TYPE } from "client/utils/gtm/gtm.types";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import st from "./styles.module.css";
import { dateFormatNotification } from "utils/constants";
import { triggerNotificationGtmEvent } from "client/core/notifications/utils/notifications-gtm-events";

type NotificationsPreviewMessageProps = {
  data: NotificationType;
  totalNotifications: number;
  notificationNo: number;
};

export const NotificationsPreviewMessage = (props: NotificationsPreviewMessageProps) => {
  const { data, totalNotifications, notificationNo } = props;

  const handleClick = () => {
    triggerNotificationGtmEvent({
      actionType: NOTIFICATION_ACTION_TYPE.pre_read,
      notificationType: NOTIFICATION_TYPE_ID[data.type],
      notificationId: NOTIFICATION_SUB_TYPE_ID[data.subType],
    });
  };

  return (
    <div onClick={handleClick}>
      <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
        <Col span={5}>
          <div className={st["notifications-preview-message"]}>
            <div className={st["notifications-preview-message-indicator"]}>
              <IndicatorCircle />
            </div>
            <div className={st.content}>
              <div>
                <Title tag="h5" size="14" color="gray-900" fontWeight="400">
                  {data.title}
                </Title>
              </div>
              <Text size="14" color="gray-700">
                {data.preview}
              </Text>
              <div className={st["notifications-preview-message-time"]}>
                <Text size="12" fontWeight="400" color="gray-600">
                  {WrapperDateDefault(Number(data.sentAt), String(dateFormatNotification))}
                </Text>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {totalNotifications > notificationNo + 1 && <DecorDivider type="horizontal" gutter="small" />}
    </div>
  );
};
