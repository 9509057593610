// Core
import { forwardRef } from "react";
import cx from "classnames";

// Utils
import st from "./Styles.module.less";

type LinkAlignmentVerticalType = "" | "top" | "middle" | "bottom";
type LinkAlignmentHorizontalType = "left" | "center" | "right";
type LinkSizeType = "12" | "14" | "16" | "20" | "24";
type LinkPaddingType = "" | "middle";
type LinkFontFontType = "system" | "base" | "second";
type LinkFontWeightType = "300" | "400" | "600" | "700";
type LinkType = "primary" | "secondary" | "disabled" | "default" | "link" | "banner";
type LinkTypeDecoration = "none" | "underline";
type LinkBoxType = "inline" | "block";
type LinkColorWhiteType = "white-100";
type LinkColorBlueType = "blue-800";
type LinkBgColorOrangeType = "orange-10" | "orange-8";
type LinkBgColorGrayType = "gray-400";
type LinkBgColorBlueType = "blue-800";
type LinkBorderColorBlueType = "blue-800";
type LinkColorType = LinkColorWhiteType | LinkColorBlueType;
type LinkBgColorType = LinkBgColorOrangeType | LinkBgColorGrayType | LinkBgColorBlueType;
type LinkBorderColorType = LinkBorderColorBlueType;

export type LinkProps = React.PropsWithChildren<{
  children?: React.ReactNode;
  type?: LinkType;
  box?: LinkBoxType;
  alignmentVertical?: LinkAlignmentVerticalType;
  alignmentHorizontal?: LinkAlignmentHorizontalType;
  size?: LinkSizeType;
  padding?: LinkPaddingType;
  font?: LinkFontFontType;
  fontWeight?: LinkFontWeightType;
  decoration?: LinkTypeDecoration;
  color?: LinkColorType;
  bgColor?: LinkBgColorType;
  icon?: React.ElementType;
  href?: string;
  blank?: boolean;
  style?: React.CSSProperties;
  noStyles?: boolean;
  fullHeight?: boolean;
  disabled?: boolean;
  borderColor?: LinkBorderColorType;
  testId?: string;
  className?: string;
  onClick?: (evt: React.SyntheticEvent) => void;
  dataAttributes?: { [key: string]: boolean | string };
}>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    href,
    size = "14",
    padding = "",
    box = "inline",
    type = "primary",
    font = "system",
    fontWeight = "400",
    alignmentVertical = "",
    alignmentHorizontal = "left",
    className,
    testId,
    blank,
    noStyles,
    fullHeight,
    disabled,
    decoration = "none",
    icon: IconCmp,
    style,
    children,
    color,
    bgColor,
    borderColor,
    onClick,
    dataAttributes = {},
  } = props;

  const linkStyles = cx(
    st.link,
    {
      [st["link-no-styles"]]: Boolean(noStyles),
      [st["link-disabled"]]: Boolean(disabled),
      [st["link-full-height"]]: Boolean(fullHeight),
    },
    !noStyles && {
      [st[`link-type-${type}`]]: Boolean(type),
      [st[`link-box-${box}`]]: Boolean(box),
      [st[`link-color-${color}`]]: Boolean(color),
      [st[`link-bg-color-${bgColor}`]]: Boolean(bgColor),
      [st[`link-font-${font}`]]: Boolean(font),
      [st[`link-font-weight-${fontWeight}`]]: Boolean(fontWeight),
      [st[`link-size-${size}`]]: Boolean(size),
      [st[`link-padding-${padding}`]]: Boolean(padding),
      [st[`link-decoration-${decoration}`]]: Boolean(decoration),
      [st[`link-alignment-vertical-${alignmentVertical}`]]: Boolean(alignmentVertical),
      [st[`link-alignment-horizontal-${alignmentHorizontal}`]]: Boolean(alignmentHorizontal),
      [st["link-icon"]]: Boolean(IconCmp),
      [st[`link-border-color-${borderColor}`]]: Boolean(borderColor),
    },
    className,
  );
  const iconNode = () => {
    return !IconCmp ? null : (
      <span className={st["link-text-icon"]}>
        <IconCmp />
      </span>
    );
  };

  if (blank) {
    return (
      <a
        data-test-id={testId}
        style={style}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={linkStyles}
        ref={ref}
        onClick={onClick}
        {...dataAttributes}
      >
        {iconNode()}
        <span className={st["link-text-value"]}>{children}</span>
      </a>
    );
  }

  return (
    <a
      data-test-id={testId}
      style={style}
      href={href}
      className={linkStyles}
      ref={ref}
      onClick={onClick}
      {...dataAttributes}
    >
      {iconNode()}
      <span className={st["link-text-value"]}>{children}</span>
    </a>
  );
});
Link.displayName = "Link";
