// Core
import { TFunction } from "next-i18next";

// Definitions
import type { MenuPropsType } from "components/ui/Menu/";
import type { LinkProps } from "components/common/Link";
import type { HeaderMenuItemType } from "client/utils/gtm/gtm.types";
import { HEADER_MENU_ITEM } from "client/utils/gtm/gtm.types";

// Component
import { MenuItem } from "components/ui/Menu";
import { Link } from "components/common/Link";

// Utils
import { book } from "init/book";

type NavigationItemBasicType = {
  id: number;
  link?: string;
  text?: string;
  icon?: string;
  analyticKey?: HeaderMenuItemType;
};

type NavigationItemType = NavigationItemBasicType & {
  children?: NavigationItemBasicType[];
};

type MainNavModelProps = {
  items: NavigationItemType[];
  t: TFunction;
  keyPrefix?: string;
};

export const mainNavItemsCatalog = (data: {
  pages?: string[];
  profileVehicleId?: string;
}): NavigationItemType[] => {
  const { pages, profileVehicleId } = data;
  const selectedProfileVehicleId = profileVehicleId || "new";

  const menuList: NavigationItemType[] = [
    {
      id: 1,
      text: "",
      link: book.dashboard,
      icon: "HomeFilled",
      analyticKey: HEADER_MENU_ITEM.main,
    },
    {
      id: 2,
      text: "common:navigation.main.catalog",
      link: `${book.catalog}/${selectedProfileVehicleId}`,
      analyticKey: HEADER_MENU_ITEM.catalog,
    },
    {
      id: 3,
      text: "common:navigation.main.orders",
      link: "orders",
      children: [
        {
          id: 1,
          text: "common:navigation.main.savedBaskets",
          link: `${book.savedBaskets}?sort[id]=desc`,
          analyticKey: HEADER_MENU_ITEM.saved_baskets,
        },
        {
          id: 3,
          text: "common:navigation.main.orders",
          link: `${book.orders}?sort[id]=desc`,
          analyticKey: HEADER_MENU_ITEM.orders,
        },
      ],
    },
    {
      id: 4,
      text: "common:navigation.main.documents",
      link: book.documents,
      analyticKey: HEADER_MENU_ITEM.documents,
    },
    {
      id: 5,
      text: "common:navigation.main.returns",
      link: "returns",
      children: [
        {
          id: 5,
          text: "common:navigation.main.returnsNew",
          link: book.returnsDraft,
          analyticKey: HEADER_MENU_ITEM.new_return,
        },
        {
          id: 4,
          text: "common:navigation.main.returnsHistory",
          link: book.returns,
          analyticKey: HEADER_MENU_ITEM.returns,
        },
      ],
    },
    {
      id: 6,
      text: "common:navigation.main.repairEstimates",
      link: book.repairEstimates,
      analyticKey: HEADER_MENU_ITEM.repair_estimates,
    },
    {
      id: 7,
      text: "common:navigation.main.promotions",
      link: book.promotions,
      analyticKey: HEADER_MENU_ITEM.promotions,
    },
  ];

  return menuList
    .filter((item) => !pages?.includes(String(item.link)))
    .map((item) => ({
      ...item,
      children: item.children?.filter((child) => !pages?.includes(String(child.link))),
    }));
};

export const mainNavModel = (props: MainNavModelProps): MenuPropsType["items"] => {
  const { t, items, keyPrefix } = props;
  const prefix = keyPrefix ? `${keyPrefix}-` : "";

  return items.map((item) => {
    const textValue = item.text ? t(String(item.text)) : "";
    const CellLink = (linkProps: LinkProps) => Link({ ...linkProps, noStyles: true });

    return {
      key: `${prefix}${String(item.analyticKey || item.id)}`,
      label: MenuItem({
        link: {
          href: item.link,
          component: CellLink,
        },
        hasText: !!item.text,
        hasIcon: !!item.icon,
        text: textValue,
        icon: item.icon,
        isSubMenu: !!item?.children,
      }),
      children: item.children
        ? mainNavModel({ t, items: item.children, keyPrefix: String(item.id) })
        : void 0,
    };
  });
};
