// Core
import { AxiosRequestConfig } from "axios";
import QS from "qs";

// Definitions
import type { BaseEmptyType } from "models/Base";
import type { HttpRequestParams, HttpResponse } from "models/Http";
import type {
  SavedBasketParamsType,
  SavedBasketListType,
  SavedBasketType,
  SavedBasketRepairEstimateCreateParamsType,
  SavedBasketRepairEstimateType,
  SortType,
} from "client/core/saved-basket-listing/models";

// Utils
import { http } from "api/network-provider";
import { QS_QUERY_CONFIG } from "utils/constants";

export const savedBasketsService = Object.freeze({
  fetchSavedBaskets: (
    payload: HttpRequestParams<
      void,
      {
        filter?: {
          search?: string;
          dateCreateFrom?: string;
          dateCreateTo?: string;
        } | null;
        page?: number;
        perPage?: number;
        sort?: SortType;
      }
    >,
  ) => {
    const { params } = payload;
    const config = {
      params,
      paramsSerializer: (p: AxiosRequestConfig): string => QS.stringify(p, QS_QUERY_CONFIG),
    };
    return http.get<HttpResponse<SavedBasketListType>>("/saved-baskets", config);
  },
  createSavedBasket: async (payload: SavedBasketParamsType) => {
    const { data } = payload;
    return http.post<HttpResponse<SavedBasketType>>("/saved-baskets", data);
  },
  removeSavedBasket: (payload: SavedBasketParamsType) => {
    const { id } = payload;
    return http.delete<HttpResponse<BaseEmptyType>>(`/saved-baskets/${String(id)}`);
  },
  createSavedBasketRepairEstimate: (payload: SavedBasketRepairEstimateCreateParamsType) => {
    const { data, id } = payload;

    return http.post<HttpResponse<SavedBasketRepairEstimateType>>(
      `/saved-baskets/${Number(id)}/repair-estimates`,
      data,
    );
  },
  createCartPreviewSavedBasket: async (payload: SavedBasketParamsType) => {
    const { data } = payload;
    return http.post<HttpResponse<void>>("/cart/preview/saved-basket", data);
  },
});
