// Components
import { SearchBarForm } from "client/core/search-bar/components/search-bar-form";

// Hooks
import { useModalContext } from "hooks/useModalContext";

// Utils
import st from "./styles.module.css";
import { GET_ENUMS } from "utils/constants";
import { modalTypes } from "components/ui/Modal/utils";

export const SearchBarPlaceholder = () => {
  const { setOnPageModal, onPageModals } = useModalContext();

  const handleSearchFocus = () => {
    setOnPageModal(GET_ENUMS.popup.searchBar, {
      visible: true,
      modalProps: {
        gutter: "middle",
        isCentered: false,
        modalType: modalTypes.SEARCH_BAR,
        closable: false,
        topPosition: "small",
      },
    });
  };

  const isSearchBarModalOpened = onPageModals[GET_ENUMS.popup.searchBar]?.visible || false;

  return (
    <div className={st["search-bar"]} data-test-id="main-search">
      {!isSearchBarModalOpened && (
        <SearchBarForm onFocus={handleSearchFocus} isSearchActive={isSearchBarModalOpened} />
      )}
    </div>
  );
};
