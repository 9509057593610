// Core
import "reflect-metadata";
import NextI18nConfig from "nextI18nConfig";
import { appWithTranslation } from "next-i18next";
import { QueryClientProvider } from "react-query";
import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary } from "react-error-boundary";
import { useRouter } from "next/router";
import { useApollo } from "init/apollo-store";

// Styles
import "theme/init.less";
import "theme/palette.css";
import "theme/fonts.css";
import "theme/global.css";
import "theme/tmp-disable.css";

// Definitions
import type { AppProps as NextAppProps } from "next/app";
import type { NextPageCustom, NextPageSSRCustomProps } from "models/Next";

// Components
import { AppError } from "components/common/Errors";

// Contexts
import { ConfigProvider as AntdConfigProvider } from "antd";
import { AuthSessionProvider } from "bus/auth/context";
import { PermissionContext } from "contexts/usePermissionContext";
import { CookiesProvider } from "client/contexts/cookies-provider";
import { ModalContextProvider } from "hooks/useModalContext";
import { ReturnNewStoreProvider } from "client/core/return-new/components/return-new-store-provider/return-new-store-provider";
import { PaymentSavedCardsProvider } from "client/core/order-payment/context";
import { GeoContextProvider } from "hooks/useGeoContext";
import { ProfileContextProvider } from "hooks/useProfileContext";

// Hooks
import { useKmtxVariables } from "hooks/useKmtxVariables";
import { useGTMPageLoad } from "client/core/common";

// Utils
import "client/utils/gtm";
import { queryClient } from "init/queryClient";
import { antdLocales } from "utils/locales";
import { Permission } from "utils/permission";

type CustomAppProps = NextAppProps<NextPageSSRCustomProps> & {
  Component: NextPageCustom;
};

const MyApp = ({ Component, pageProps }: CustomAppProps) => {
  const { geo = null, profile = null, ...otherPageProps } = pageProps;

  const apolloClient = useApollo();
  const router = useRouter();

  useGTMPageLoad({ profileId: profile?.customerId, profileCountryCode: geo?.country });
  useKmtxVariables({ geo, profile });

  const locale = router.locale ?? router.defaultLocale ?? "";
  const permissionContextValue = new Permission().createAbility(profile?.permission.feature);

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <AntdConfigProvider locale={antdLocales(locale)}>
          <ErrorBoundary fallback={<AppError />}>
            <GeoContextProvider geo={geo}>
              <ProfileContextProvider profile={profile}>
                <ModalContextProvider>
                  <ReturnNewStoreProvider>
                    <PaymentSavedCardsProvider>
                      <PermissionContext.Provider value={permissionContextValue}>
                        <CookiesProvider>
                          <Component {...otherPageProps} />
                        </CookiesProvider>
                      </PermissionContext.Provider>
                    </PaymentSavedCardsProvider>
                  </ReturnNewStoreProvider>
                </ModalContextProvider>
              </ProfileContextProvider>
            </GeoContextProvider>
          </ErrorBoundary>
        </AntdConfigProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
};

const AppWithI18n = appWithTranslation(MyApp, NextI18nConfig);

const AppWithAuth = (props: CustomAppProps) => {
  return (
    <AuthSessionProvider session={props.pageProps.session}>
      <AppWithI18n {...props} />
    </AuthSessionProvider>
  );
};
export default AppWithAuth;
