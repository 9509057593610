// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventsDataUnionType,
  EventPayloadOemNumberClickType,
  EventOemNumberClickDataType,
} from "client/utils/gtm/gtm.types";
import { ParametersEnum, PayloadKeysEnum, GTMTypesEnum } from "client/utils/gtm/gtm.types";

class OemNumberClickEvent extends AbstractGTMEventSegment<GTMTypesEnum.oem_number_click> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.car_id]: 0,
      [ParametersEnum.car_title]: "",
      [ParametersEnum.car_description]: "",
      [ParametersEnum.vin_number]: "",
      [ParametersEnum.schema_page_id]: "",
      [ParametersEnum.schema_name]: "",
      [ParametersEnum.oem_number]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadOemNumberClickType {
    const oemNumberClickData = data as EventOemNumberClickDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters] = oemNumberClickData;

    return segment;
  }
}

export const oemNumberClickEvent: OemNumberClickEvent = new OemNumberClickEvent();
