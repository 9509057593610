// Components
import { LightList } from "components/ui/LightList";

// Hooks
import { useCartCountFetch } from "client/core/cart";
import { useNotificationsFetch } from "client/core/notifications/hooks/use-notifications-fetch";

// Definitions
import { NOTIFICATION_ACTION_TYPE } from "client/utils/gtm/gtm.types";

// Utils
import { profileActionModel } from "utils/renderModels/profileActionModel";
import { triggerNotificationGtmEvent } from "client/core/notifications/utils/notifications-gtm-events";

export const NavbarProfile = () => {
  const { loadingCartCountFetch, cartCount } = useCartCountFetch();
  const { loadingNotificationsFetch, totalUnread } = useNotificationsFetch();

  const handleTrackByNotificationIcon = () => {
    triggerNotificationGtmEvent({
      actionType: NOTIFICATION_ACTION_TYPE.click_bell,
    });
  };

  const menuItems = profileActionModel({
    cartProductsCount: cartCount,
    loadingCartProductsCount: loadingCartCountFetch,
    notificationsCount: totalUnread,
    loadingNotificationsFetch,
    onEvent: handleTrackByNotificationIcon,
  });

  return <LightList testId="navbar-profile" mode="horizontal" type="actions" items={menuItems} />;
};
