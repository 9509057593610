// Components
import { DecorDivider } from "components/common/DecorDivider";
import { CartCellButton } from "client/core/cart/components/cart-cell-button";
import { CartCellDisabled } from "client/core/cart/components/cart-cell-disabled";

// Definitions
import type { CartTabProductType } from "client/core/cart/models";

// Utils
import st from "./styles.module.css";

type CartCellActionsProps = {
  hasActReplaceActive?: boolean;
  hasActReplaceText?: string;
  loadingRemove?: boolean;
  disabled?: boolean;
  onUpdate?: ({ id, articleId }: { id: number; articleId: number }) => void;
  onRemove?: (product: CartTabProductType) => void;
  product: CartTabProductType;
};

export const CartCellActions = (props: CartCellActionsProps) => {
  const {
    hasActReplaceActive,
    hasActReplaceText,
    loadingRemove,
    product,
    disabled,
    onUpdate,
    onRemove,
  } = props;

  const handleUpdate =
    ({ id, articleId }: { id: number; articleId: number }) =>
    () =>
      onUpdate?.({ id, articleId });
  const handleRemove = (value: CartTabProductType) => () => onRemove?.(value);

  const baseJSX = (
    <div className={st["cell-actions"]}>
      {onUpdate && (
        <CartCellButton
          type="default"
          btnType={hasActReplaceActive ? "icon-active" : "icon"}
          iconName="Change"
          onSubmit={handleUpdate({ id: Number(product.id), articleId: Number(product.articleId) })}
          actions
          hasTooltip={hasActReplaceActive}
          showTooltip={hasActReplaceActive}
          tooltipPlacement="left"
          tooltipContent={hasActReplaceText}
          testId="cell-button-update"
        />
      )}

      {onUpdate && onRemove && (
        <DecorDivider type="vertical" gutter="empty" className={st["action-divider"]} />
      )}
      {onRemove && (
        <CartCellButton
          type="default"
          btnType="icon"
          loading={loadingRemove}
          iconName="Delete"
          onSubmit={handleRemove(product)}
          actions
          testId="cell-button-delete"
        />
      )}
    </div>
  );

  return disabled ? <CartCellDisabled center>{baseJSX}</CartCellDisabled> : baseJSX;
};
