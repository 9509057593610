// Components
import { authorizationEvent } from "./segments/authorization";
import { registrationEvent } from "./segments/registration";
import { searchEvent } from "./segments/search";
import { selectorCarEvent } from "./segments/selector-car";
import { catalogEvent } from "./segments/catalog";
import { showPriceEvent } from "./segments/show-price";
import { addToCartEvent } from "./segments/add-to-cart";
import { beginCheckoutEvent } from "./segments/begin-checkout";
import { purchaseEvent } from "./segments/purchase";
import { catalogAddTabEvent } from "./segments/catalog-add-tab";
import { catalogCloseTabEvent } from "./segments/catalog-close-tab";
import { catalogTabClickEvent } from "./segments/catalog-tab-click";
import { consentOptionEvent } from "./segments/consent-option";
import { consentDefEvent } from "./segments/consent-def";
import { pageLoadEvent } from "./segments/page-load";
import { viewItemListEvent } from "./segments/view_item_list";
import { viewItemEvent } from "./segments/view_item";
import { ecommerceClearEvent } from "./segments/ecommerce_clear";
import { selectItemEvent } from "./segments/select-item";
import { addPaymentInfoEvent } from "./segments/add-payment-info";
import { removeFromCartEvent } from "./segments/remove-from-cart";
import { viewCartEvent } from "./segments/view-cart";
import { popupEvent } from "./segments/popup";
import { headerTopEvent } from "./segments/header-top";
import { footerMenuEvent } from "./segments/footer-menu";
import { notificationPageEvent } from "./segments/notification-page";
import { notificationEvent } from "./segments/notification";
import { bannerEvent } from "./segments/banner";
import { headerMenuEvent } from "./segments/header-menu";
import { bannerNewsEvent } from "./segments/banner-news";
import { oemCatalogClickEvent } from "./segments/oem-catalog-click";
import { oemCatalogBackEvent } from "./segments/oem-catalog-back";
import { emptyOemPageEvent } from "./segments/empty-oem-page";
import { oemCategoryClickEvent } from "./segments/oem-category-click";
import { schemaPageLoadEvent } from "./segments/schema-page-load";
import { oemNumberClickEvent } from "./segments/oem-number-click";
import { documentSearchEvent } from "./segments/document-search";
import { documentSortEvent } from "./segments/document-sort";
import { documentDownloadEvent } from "./segments/document-download";
import { orderSortEvent } from "./segments/order-sort";
import { paymentMethodEvent } from "./segments/payment-method";
import { checkoutBtnEvent } from "./segments/checkout-btn";
import { replayPaymentEvent } from "./segments/replay-payment";
import { trackOrderEvent } from "./segments/track-order";

// Definitions
import { GTMTypesEnum, GTMConfigType } from "./gtm.types";

const eventsMap: GTMConfigType["eventsMap"] = new Map();
eventsMap.set(GTMTypesEnum.pageLoad, pageLoadEvent);
eventsMap.set(GTMTypesEnum.authorization, authorizationEvent);
eventsMap.set(GTMTypesEnum.registration, registrationEvent);
eventsMap.set(GTMTypesEnum.search, searchEvent);
eventsMap.set(GTMTypesEnum.selector_car, selectorCarEvent);
eventsMap.set(GTMTypesEnum.catalog, catalogEvent);
eventsMap.set(GTMTypesEnum.show_price, showPriceEvent);
eventsMap.set(GTMTypesEnum.add_to_cart, addToCartEvent);
eventsMap.set(GTMTypesEnum.begin_checkout, beginCheckoutEvent);
eventsMap.set(GTMTypesEnum.purchase, purchaseEvent);
eventsMap.set(GTMTypesEnum.add_tab, catalogAddTabEvent);
eventsMap.set(GTMTypesEnum.close_tab, catalogCloseTabEvent);
eventsMap.set(GTMTypesEnum.tab_click, catalogTabClickEvent);
eventsMap.set(GTMTypesEnum.consent_mode_option, consentOptionEvent);
eventsMap.set(GTMTypesEnum.consent_mode_def, consentDefEvent);
eventsMap.set(GTMTypesEnum.view_item_list, viewItemListEvent);
eventsMap.set(GTMTypesEnum.view_item, viewItemEvent);
eventsMap.set(GTMTypesEnum.select_item, selectItemEvent);
eventsMap.set(GTMTypesEnum.remove_from_cart, removeFromCartEvent);
eventsMap.set(GTMTypesEnum.view_cart, viewCartEvent);
eventsMap.set(GTMTypesEnum.add_payment_info, addPaymentInfoEvent);
eventsMap.set(GTMTypesEnum.popup, popupEvent);
eventsMap.set(GTMTypesEnum.header_top, headerTopEvent);
eventsMap.set(GTMTypesEnum.footer_menu, footerMenuEvent);
eventsMap.set(GTMTypesEnum.header_menu, headerMenuEvent);
eventsMap.set(GTMTypesEnum.notification_page, notificationPageEvent);
eventsMap.set(GTMTypesEnum.notification, notificationEvent);
eventsMap.set(GTMTypesEnum.banner, bannerEvent);
eventsMap.set(GTMTypesEnum.banner_news, bannerNewsEvent);
eventsMap.set(GTMTypesEnum.ecommerce_clear, ecommerceClearEvent);
eventsMap.set(GTMTypesEnum.oem_catalog_click, oemCatalogClickEvent);
eventsMap.set(GTMTypesEnum.oem_catalog_back, oemCatalogBackEvent);
eventsMap.set(GTMTypesEnum.empty_oem_page, emptyOemPageEvent);
eventsMap.set(GTMTypesEnum.oem_category_click, oemCategoryClickEvent);
eventsMap.set(GTMTypesEnum.schema_page_load, schemaPageLoadEvent);
eventsMap.set(GTMTypesEnum.oem_number_click, oemNumberClickEvent);
eventsMap.set(GTMTypesEnum.document_search, documentSearchEvent);
eventsMap.set(GTMTypesEnum.document_sort, documentSortEvent);
eventsMap.set(GTMTypesEnum.document_download, documentDownloadEvent);
eventsMap.set(GTMTypesEnum.order_sort, orderSortEvent);
eventsMap.set(GTMTypesEnum.payment_method, paymentMethodEvent);
eventsMap.set(GTMTypesEnum.checkout_btn, checkoutBtnEvent);
eventsMap.set(GTMTypesEnum.replay_payment, replayPaymentEvent);
eventsMap.set(GTMTypesEnum.track_order, trackOrderEvent);

export { eventsMap };
