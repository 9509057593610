// Components
import { DynamicImport } from "components/common/DynamicImport";

// Hooks
import { useHeaderBannerManageContent } from "client/core/header-banner/hooks/use-header-banner-manage-content";

const HeaderBannerList = DynamicImport(
  import("client/core/header-banner/components/header-banner-list"),
  "HeaderBannerList",
);

export const HeaderBanner = () => {
  const { banners, dataIndex, handleSurveyLinkClick } = useHeaderBannerManageContent(5000);

  return (
    <HeaderBannerList
      banners={banners}
      dataIndex={dataIndex}
      handleSurveyLinkClick={handleSurveyLinkClick}
    />
  );
};
