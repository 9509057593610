// Definitions
import type { CountriesType } from "client/core/user-country-selector/models";
import type { HttpResponse } from "models/Http";

// Utils
import { http } from "api/network-provider";

export const userCountrySelectorService = Object.freeze({
  fetchCountries: () => {
    return http.get<HttpResponse<CountriesType>>("/dropdown-options/countries");
  },
});
