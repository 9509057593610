// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventFooterMenuDataType,
  EventPayloadFooterMenuType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  FOOTER_MENU_ITEM,
} from "client/utils/gtm/gtm.types";

class FooterMenuEvent extends AbstractGTMEventSegment<GTMTypesEnum.footer_menu> {
  protected readonly segmentShape: EventPayloadFooterMenuType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: FOOTER_MENU_ITEM.empty,
    },
  };

  makeSegment(data: EventFooterMenuDataType): EventPayloadFooterMenuType {
    const { actionType } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] = actionType;

    return segment;
  }
}

export const footerMenuEvent: FooterMenuEvent = new FooterMenuEvent();
