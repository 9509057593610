// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadNotificationType,
  EventNotificationDataType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  NOTIFICATION_ACTION_TYPE,
} from "client/utils/gtm/gtm.types";

class NotificationEvent extends AbstractGTMEventSegment<GTMTypesEnum.notification> {
  protected readonly segmentShape: EventPayloadNotificationType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: NOTIFICATION_ACTION_TYPE.empty,
    },
  };

  makeSegment(data: EventNotificationDataType): EventPayloadNotificationType {
    const { actionType, notificationType, notificationId } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] = actionType;

    if (notificationType) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.event_object] = notificationType;
    }

    if (notificationId) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.notification_id] = notificationId;
    }

    return segment;
  }
}

export const notificationEvent: NotificationEvent = new NotificationEvent();
