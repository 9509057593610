// Core
import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs } from "dayjs";

// Components
import { Form } from "antd";
import { WrappedFormTimePicker } from "components/common/WrappedFormTimePicker";
import { WrappedFormInput } from "components/common/WrappedFormInput";
import { Button } from "components/ui/Button";
import { FormTitle } from "components/ui/FormTitle";
import { InputForm } from "components/ui/FormFields/InputForm";
import { WrappedFormSelect } from "components/common/WrappedFormSelect";
import { FormBlock } from "components/ui/FormFields/FormBlock";
import { InputGroup } from "components/ui/FormFields/InputGroup";

// Definitions
import type { SyntheticEvent } from "react";
import type { FormSubmitFn } from "models/Forms";
import type { PersonalManagerCallBackType } from "client/core/personal-manager/models";

// Utils
import { getFieldsMetaValidation } from "utils/forms";
import {
  getCountryItemByLocale,
  mapCountriesToIcons,
  disabledOutLimitPickerTime,
  disabledOutLimitPickerDate,
} from "client/core/personal-manager/utils/common";
import {
  personalManagerCallBackForm,
  inputConfig,
  PERSONAL_MANAGER_CALLBACK_FIELD,
} from "./config";
import { useTranslation } from "client/utils/i18n/client";

import st from "./styles.module.css";

const PICKER_TIME_FORMAT = "HH:mm";
const PICKER_TIME_MINUTE_STEP = 5;
const DATE_FORMAT = "DD/MM/YYYY";

type PersonalManagerCallbackFormProps = {
  loading: boolean;
  loadingStaticData?: boolean;
  title: string;
  country?: string;
  phoneCodes?: {
    id: number;
    value: string;
    label: string;
  }[];
  onSubmit: FormSubmitFn<PersonalManagerCallBackType>;
};

export const PersonalManagerCallbackForm = (props: PersonalManagerCallbackFormProps) => {
  const { title, loading, loadingStaticData, onSubmit, country, phoneCodes = [] } = props;
  const { i18n, t } = useTranslation();

  const phonePrefixWithIcons = useMemo(() => mapCountriesToIcons(phoneCodes), [phoneCodes]);

  const countryPrefix = country || i18n.language;
  const formProps = useForm<PersonalManagerCallBackType>({
    defaultValues: { ...personalManagerCallBackForm.shape },
    resolver: zodResolver(personalManagerCallBackForm.schema(t)),
    mode: "all",
    reValidateMode: "onBlur",
  });

  const { control, handleSubmit, formState, setError, trigger, reset, setValue } = formProps;
  const { isSubmitting } = formState;
  const fieldsMeta = getFieldsMetaValidation(PERSONAL_MANAGER_CALLBACK_FIELD, formState);
  const minDate = dayjs().add(15, "minutes");
  const roundedMinute =
    Math.ceil(minDate.minute() / PICKER_TIME_MINUTE_STEP) * PICKER_TIME_MINUTE_STEP;
  const roundedDate = minDate.minute(roundedMinute);
  const outLimitPickerTime = disabledOutLimitPickerTime(minDate);
  const outLimitPickerDate = disabledOutLimitPickerDate(minDate);

  const onDateTimeChange = (date: Dayjs | null) => {
    void (async () => {
      const updateDate = date?.isBefore(roundedDate) ? roundedDate : date;
      setValue("desiredTime", !updateDate ? "" : updateDate.toISOString());
      await trigger("desiredTime");
    })();
  };

  const handleSubmitForm = handleSubmit((values: PersonalManagerCallBackType): void => {
    onSubmit?.({
      values,
      acts: {
        setError,
        reset,
      },
    });
  });

  const onSubmitForm = (event: SyntheticEvent): void => {
    void (async () => {
      await handleSubmitForm(event);
    })();
  };

  useWatch({ control });

  useEffect(() => {
    const defaultPhonePrefixOption = getCountryItemByLocale(phoneCodes, countryPrefix);
    const closestCallTime = roundedDate.toISOString();
    defaultPhonePrefixOption?.icon && setValue("phonePrefix", defaultPhonePrefixOption);
    closestCallTime && setValue("desiredTime", closestCallTime);
  }, [phoneCodes, countryPrefix, setValue]);

  return (
    <Form
      layout="vertical"
      onFinish={onSubmitForm}
      className={st["personal-manager-callback-form"]}
    >
      <FormTitle>{title}</FormTitle>
      <InputForm
        htmlFor={PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber}
        label={t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber].label)}
        labelRequired
        type="no-gutter"
        skeletonType="phone-number"
        loading={loadingStaticData}
      >
        <InputGroup type="phone">
          <InputForm
            id={PERSONAL_MANAGER_CALLBACK_FIELD.phonePrefix}
            name={PERSONAL_MANAGER_CALLBACK_FIELD.phonePrefix}
            validateStatus={fieldsMeta.phonePrefix.status}
            {...fieldsMeta.phonePrefix.helpText}
          >
            <WrappedFormSelect
              id={PERSONAL_MANAGER_CALLBACK_FIELD.phonePrefix}
              name={PERSONAL_MANAGER_CALLBACK_FIELD.phonePrefix}
              control={control}
              placeholder={t(inputConfig.phonePrefix.placeholder)}
              options={phonePrefixWithIcons}
              validateStatus={fieldsMeta.phonePrefix.status}
              hasPrefix
              hasDisabled
            />
          </InputForm>
          <InputForm
            id={PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber}
            name={PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber}
            validateStatus={fieldsMeta.phoneNumber.status}
            {...fieldsMeta.phoneNumber.helpText}
          >
            <WrappedFormInput
              id={PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber}
              name={PERSONAL_MANAGER_CALLBACK_FIELD.phoneNumber}
              control={control}
              placeholder={t(inputConfig.phoneNumber.placeholder)}
              hasPhoneMask
              unmaskedValue
            />
          </InputForm>
        </InputGroup>
      </InputForm>
      <InputForm
        name={PERSONAL_MANAGER_CALLBACK_FIELD.dateTime}
        validateStatus={fieldsMeta.desiredTime.status}
        {...fieldsMeta.desiredTime.helpText}
        label={t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime].label)}
        labelRequired
      >
        <WrappedFormTimePicker
          name={PERSONAL_MANAGER_CALLBACK_FIELD.dateTime}
          control={control}
          placeholder={t(inputConfig[PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime].placeholder)}
          hasDisabled={isSubmitting}
          showTime
          minuteStep={PICKER_TIME_MINUTE_STEP}
          format={`${DATE_FORMAT} ${PICKER_TIME_FORMAT}`}
          disabledDate={outLimitPickerDate}
          disabledTime={outLimitPickerTime}
          defaultValue={roundedDate}
          size="large"
          onChange={onDateTimeChange}
          minDate={roundedDate}
        />
      </InputForm>
      <WrappedFormInput
        name={PERSONAL_MANAGER_CALLBACK_FIELD.desiredTime}
        control={control}
        hidden
      />
      <FormBlock>
        <Button type="primary" htmlType="submit" size="large" block loading={loading}>
          {t("buttons:send")}
        </Button>
      </FormBlock>
    </Form>
  );
};
