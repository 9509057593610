// Core
import cx from "classnames";

// Definitions
import type { PropsWithChildren, ReactNode } from "react";
import type { TooltipProps } from "components/ui/Tooltip";
import type {
  ButtonGeneralType,
  ButtonCustomType,
  ButtonSizeType,
  ButtonHTMLType,
} from "components/ui/Button";

// Components
import { Icon } from "components/ui/Icon";
import { Button } from "components/ui/Button";

// Utils
import st from "./styles.module.css";

type CartCellButtonProps = PropsWithChildren<{
  iconName?: string;
  iconSize?: "mini" | "small" | "middle" | "large";
  type?: ButtonGeneralType;
  testId?: string;
  danger?: boolean;
  buy?: boolean;
  loading?: boolean;
  block?: boolean;
  actions?: boolean;
  disabled?: boolean;
  size?: ButtonSizeType;
  noStyle?: boolean;
  btnType?: ButtonCustomType;
  htmlType?: ButtonHTMLType;
  hasTooltip?: boolean;
  showTooltip?: boolean;
  tooltipContent?: ReactNode;
  tooltipPlacement?: TooltipProps["placement"];
  onSubmit?: () => void;
}>;

export const CartCellButton = (props: CartCellButtonProps) => {
  const {
    iconName = "",
    iconSize = "middle",
    type = "primary",
    testId = "",
    danger,
    block = false,
    loading,
    size = "large",
    noStyle,
    children,
    disabled,
    actions,
    btnType,
    hasTooltip,
    showTooltip,
    tooltipPlacement,
    tooltipContent,
    htmlType,
    onSubmit,
    ...otherProps
  } = props;
  const buttonClassName = cx(st["cell-btn"], {
    [st.actions]: actions,
  });

  return (
    <Button
      className={buttonClassName}
      block={block}
      icon={iconName && <Icon name={iconName} size={iconSize} />}
      type={type}
      btnType={btnType}
      size={size}
      htmlType={htmlType}
      danger={danger}
      onClick={onSubmit}
      loading={loading}
      noStyle={noStyle}
      disabled={disabled}
      hasTooltip={hasTooltip}
      showTooltip={showTooltip}
      tooltipPlacement={tooltipPlacement}
      tooltipContent={tooltipContent}
      testId={testId}
      {...otherProps}
    >
      {children}
    </Button>
  );
};
