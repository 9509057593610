"use client";

// Core
import { useEffect } from "react";
import { NextIntlClientProvider } from "next-intl";
import { useRouter } from "next/router";

// Definitions
import { Locale } from "../types";

// Utils
import { getMessageFallback, onError } from "../lib/utils";
import { assignGlobalI18nValue } from "./global-i18n";
import { defaultLocale } from "../config";

type TranslationsProviderProps = React.ComponentProps<typeof NextIntlClientProvider>;

export const TranslationsProvider = ({
  children,
  locale: propsLocale,
  now,
  timeZone,
  messages,
  formats,
}: TranslationsProviderProps) => {
  // with server components in app router, locale will not be passed
  const router = useRouter();
  const locale = (propsLocale ?? router.locale ?? router.defaultLocale ?? defaultLocale) as Locale;

  useEffect(() => {
    if (!messages || !locale) return;
    assignGlobalI18nValue({ messages, locale });
  }, []);

  return (
    <NextIntlClientProvider
      locale={locale}
      messages={messages}
      now={now}
      timeZone={timeZone}
      formats={formats}
      onError={onError}
      getMessageFallback={getMessageFallback}
    >
      {children}
    </NextIntlClientProvider>
  );
};
