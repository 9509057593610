// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type { EventPayloadBannerType, EventBannerDataType } from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ANALYTICS_ACTION_TYPE,
} from "client/utils/gtm/gtm.types";

class BannerEvent extends AbstractGTMEventSegment<GTMTypesEnum.banner> {
  protected readonly segmentShape: EventPayloadBannerType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: "",
      [ParametersEnum.banner_position]: "",
      [ParametersEnum.banner_name]: "",
      [ParametersEnum.banner_id]: 0,
    },
  };

  makeSegment(data: EventBannerDataType): EventPayloadBannerType {
    const { bannerPosition, bannerName, bannerType, bannerId } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] =
      `${bannerType}_${ANALYTICS_ACTION_TYPE.click}`;
    segment[PayloadKeysEnum.parameters][ParametersEnum.banner_position] = bannerPosition;
    segment[PayloadKeysEnum.parameters][ParametersEnum.banner_name] = bannerName;
    segment[PayloadKeysEnum.parameters][ParametersEnum.banner_id] = bannerId;

    return segment;
  }
}

export const bannerEvent: BannerEvent = new BannerEvent();
