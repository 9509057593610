// Definitions
import type { AxiosPromise } from "axios";

export enum HttpStatusCode {
  success = 200,
  created = 201,
  noContent = 204,
  badRequest = 400,
  auth = 401,
  forbidden = 403,
  notFound = 404,
  unprocessableEntity = 422,
  toMuchReq = 429,
  cancelled = 499,
  server = 500,
}

export enum HttpServerErrorCode {
  validation = "validation",
  leadVerified = "leadVerified",
  leadRegistered = "invited_lead_already_registered",
  callBackLimit = "callBackLimit",
  personalCallBackLimit = "personalCallBackLimit",
  hashInvalid = "hashInvalid",
  quantityMax = "quantityMax",
  clientAlreadyExist = "clientAlreadyExist",
  timeoutServiceValidation = "timeoutServiceValidation",
  companyValidation = "companyValidation",
  addressValidation = "addressValidation",
  customerBlocked = "customerBlocked",
  maxRequestError = "maxRequestError",
  kbaExists = "kbaExists",
  regNumberExists = "regNumberExists",
  loyaltyLevelValidation = "loyaltyLevelValidation",
}

export type ServerErrorBaseType = {
  type: string;
  message: string;
  errorCode?: HttpServerErrorCode;
};

export type ServerErrorFieldDetailsType = { alias: string; desc: string };
export type ServerErrorFieldFormatType = Record<string, string>;
export type ServerErrorValidationFieldFormatType = Record<string, ServerErrorFieldDetailsType>;

export type ServerErrorRawFormatType = ServerErrorBaseType & {
  errors?: ServerErrorValidationFieldFormatType | null;
  hasError?: boolean;
};

export type ServerErrorFormatType = ServerErrorBaseType & {
  errors?: ServerErrorFieldFormatType;
  hasError?: boolean;
};

export type HttpErrorType = ServerErrorFormatType & {
  status: HttpStatusCode;
};

export type ApiErrorType = {
  error: ServerErrorRawFormatType;
  data?: any;
};

export type HttpResponse<T = void> = {
  data?: T;
};

type RequestMetaParamsType = {
  country?: string;
  locale?: string;
  cookies?: string;
  isSSR?: boolean;
};

export type HttpRequestParams<
  D = object,
  P = Record<string, unknown>,
  M = RequestMetaParamsType,
> = {
  id?: number;
  params?: P;
  data?: D;
  meta?: M;
};

export type HttpServerRequestType<
  D = unknown,
  P = Record<string, string | string[] | object | number>,
> = {
  headers?: Record<string, string>;
  params?: P;
  data?: D;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// export type HttpResponse<T = any> = {
//   data: HttpErrorShape<T>;
//   // data: T extends Blob ? T : HttpEntity<T>;
//   status: HttpStatusCode;
// };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FetchDataType<T, P = any> = (
  v: P,
) => AxiosPromise<HttpResponse<T>> | AxiosPromise<ApiErrorType>;

export type HttpResponseBufferType = {
  type: "buffer";
  data: ArrayBuffer;
};
