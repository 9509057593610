// Core
import { useContext } from "react";
import { useRouter } from "next/router";

// Definitions
import type { ReactNode } from "react";
import type {
  CartTabType,
  CartTabProductType,
  ProductHazardInfoType,
} from "client/core/cart/models";
import type { FormSubmitParams } from "models/Forms";
import type { CommonTableProps } from "components/common/CommonTableAnt";
import { PERMISSION_ACT } from "client/core/profile/models";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Text } from "components/ui/Typography/Text";
import { Button } from "components/ui/Button";
import { Icon } from "components/ui/Icon";
import { InputCheckbox } from "components/ui/FormFields/InputCheckbox";
import { DecorDivider } from "components/common/DecorDivider";
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { DynamicImport } from "components/common/DynamicImport/DynamicImport";
import { Can } from "components/common/Can";
import { CartPreviewVehiclesDeliveryDate } from "client/core/cart/components/cart-preview-vehicles-delivery-date";
import { CartPreviewProductsForm } from "client/core/cart/components/cart-preview-products-form";
import { CartCellCustom } from "client/core/cart/components/cart-cell-custom";
import { CartCellDisabled } from "client/core/cart/components/cart-cell-disabled";
import { CartPreviewVehicleForm } from "client/core/cart/components/сart-preview-vehicle-form";
import { CartStringEditForm } from "client/core/cart/components/cart-string-edit-form";

// Hooks
import { useCartPreviewVehiclesStore } from "client/core/cart/hooks/use-cart-preview-vehicles-store";
import { useCartPreviewProductRemove } from "client/core/cart/hooks/use-cart-preview-product-remove";
import { useCartPreviewVehiclesUpdate } from "client/core/cart/hooks/use-cart-preview-vehicles-update";
import { useCartPreviewSavedBasketCreate } from "client/core/cart/hooks/use-cart-preview-saved-basket-create";
import { useCartPreviewProductUpdate } from "client/core/cart/hooks/use-cart-preview-product-update";
import { useCartPreviewProductAlternativesToggle } from "client/core/cart/hooks/use-cart-preview-product-alternatives-toggle";
import { useProfileContext } from "hooks/useProfileContext";
import { useCartPreviewPriceVatAbility } from "client/core/cart/hooks/use-cart-preview-price-vat-ability";
import { useModalContext } from "hooks/useModalContext";
import { useTranslation } from "client/utils/i18n/client";

// Utils
import { GET_PARAMS, GET_ENUMS } from "utils/constants";
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { getCartPreviewProductsColumns } from "./render-model";
import { SettingsPriceVatContext } from "client/core/settings-price-vat/context";
import { book } from "init/book";

import st from "./styles.module.css";

const CommonTableAntDynamic = DynamicImport<CommonTableProps<CartTabProductType>>(
  import("components/common/CommonTableAnt"),
  "CommonTableAnt",
);

type CartPreviewVehiclesPropsType = {
  tab?: CartTabType | null;
  loadingCartFetch?: boolean;
  loadingCartUpdate?: boolean;
};

export const CartPreviewVehicles = (props: CartPreviewVehiclesPropsType) => {
  const { tab = null, loadingCartFetch, loadingCartUpdate } = props;
  const router = useRouter();
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { canSwitchVat } = useCartPreviewPriceVatAbility();
  const { priceVatCartPreview: viewPriceVat } = useContext(SettingsPriceVatContext);

  const { edit, setEdit } = useCartPreviewVehiclesStore();

  const { loadingCartPreviewProductRemove, onRemoveCartPreviewProduct } =
    useCartPreviewProductRemove();

  const { loadingCartPreviewProductUpdate, onUpdateCartPreviewProduct } =
    useCartPreviewProductUpdate();

  const { loadingCartPreviewVehiclesUpdate, onUpdateCartPreviewVehicles } =
    useCartPreviewVehiclesUpdate(tab?.id);

  const { loadingCartPreviewAddSavedBasketFetch, onAddSavedBasketCartPreview } =
    useCartPreviewSavedBasketCreate();

  const { onOpenProductAlternatives } = useCartPreviewProductAlternativesToggle();

  const { setOnPageModal } = useModalContext();

  const handleUpdateVehicles = (
    data: FormSubmitParams<{
      title: string;
    }>,
  ) => {
    onUpdateCartPreviewVehicles(data);
  };

  const handleCreateSavedBasket = () => {
    onAddSavedBasketCartPreview({ vehicleId: Number(tab?.id) });
  };

  const handleCreateCartRepairEstimate = () => {
    void (async () => {
      await router.push({
        pathname: book.cartPreview,
        query: {
          ...router.query,
          [GET_PARAMS.popup]: GET_ENUMS.popup.cartRepairEstimateCreate,
          basketTabId: Number(tab?.id),
        },
      });
    })();
  };

  const handleRemoveProduct = (value: CartTabProductType) => {
    onRemoveCartPreviewProduct?.(value);
  };

  const handleUpdateProduct = async (record: CartTabProductType, selected: boolean) => {
    if (record?.quantity?.value) {
      await onUpdateCartPreviewProduct?.({
        id: record.id,
        isActive: selected,
        quantity: record.quantity.value,
      });
    }
  };

  const handleFetchAlternatives = (value: { id: number; articleId: number }) => {
    onOpenProductAlternatives({
      articleId: value.articleId,
      productId: value.id,
      ...(tab?.vehicleId && { vehicleId: tab?.vehicleId }),
    });
  };

  const handleDeleteSelected = () => {
    void (async () => {
      await router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          [GET_PARAMS.popup]: GET_ENUMS.popup.deleteCarFromCart,
          tabId: Number(tab?.id),
        },
      });
    })();
  };

  const selectedRow = tab?.products?.filter((item) => item.isActive).map((item) => item.id);
  const isEditableTab = tab?.isEditableTitle;
  const isRepairEstimateAvailable = tab?.isRepairEstimateAvailable;

  const handleClickProductHazardInfo = (hazardInfo: ProductHazardInfoType) => {
    setOnPageModal(GET_ENUMS.popup.cartHazardInfo, {
      visible: true,
      params: {
        hazardInfo,
      },
    });
  };

  const columns = getCartPreviewProductsColumns({
    t,
    country: profile?.countryCode,
    onRemoveProduct: handleRemoveProduct,
    onClickProductHazardInfo: handleClickProductHazardInfo,
    onFetchAlternatives: handleFetchAlternatives,
    canSwitchVat,
    ...(canSwitchVat && { viewPriceVat }),
  });

  const rowSelection = {
    selectedRowKeys: selectedRow,
    hideSelectAll: true,
    columnWidth: 64,
    onSelect: handleUpdateProduct,
    renderCell: (
      _: unknown,
      record: Record<string, unknown>,
      __: unknown,
      originNode: ReactNode,
    ) => {
      const isDisabled = !record.isAvailable;

      return isDisabled ? (
        <CartCellDisabled testId="cell-selection" center>
          <InputCheckbox value={isDisabled} size="large" hasDisabled={isDisabled} />
        </CartCellDisabled>
      ) : (
        <div data-test-id="cell-selection">{originNode}</div>
      );
    },
  };

  return (
    <>
      <Row
        gutter={ROW_GUTTER_TYPE.md.horizontal}
        className={st["cart-preview-vehicles"]}
        data-test-id="cart-preview-vehicles"
      >
        <Col span={24}>
          <Row
            gutter={ROW_GUTTER_TYPE.md.horizontal}
            className={st["tab-info-block"]}
            align="bottom"
          >
            <Col span={8}>
              <div className={st["tab-info"]}>
                {!edit ? (
                  <Row wrap={false} align="middle">
                    <Col flex="auto">
                      <div data-test-id="cart-preview-vehicle-edit">
                        <CartPreviewVehicleForm
                          initialValues={{
                            selected: String(tab?.selected),
                          }}
                          loadingFetch={loadingCartFetch}
                          loadingUpdate={loadingCartPreviewVehiclesUpdate}
                          onSubmit={handleUpdateVehicles}
                        >
                          <Text
                            fontWeight="600"
                            className={st["tab-info-title"]}
                            alignmentVertical="middle"
                            testId="tab-info-title"
                          >
                            {WrapperNodeDefault(tab?.title)}
                          </Text>
                        </CartPreviewVehicleForm>
                      </div>
                    </Col>
                    {isEditableTab && !loadingCartPreviewVehiclesUpdate && (
                      <Col>
                        <Button
                          type="link"
                          size="small"
                          icon={
                            <Icon
                              name="EditOutlined"
                              size="fullWidth"
                              className={st["tab-info-icon"]}
                            />
                          }
                          onClick={() => setEdit(true)}
                          testId="cart-preview-vehicles-edit-button"
                        />
                      </Col>
                    )}
                  </Row>
                ) : (
                  <Col>
                    <CartStringEditForm
                      initialValue={{
                        title: String(tab?.title),
                      }}
                      formSize="middle"
                      loadingUpdate={loadingCartPreviewVehiclesUpdate}
                      onSubmit={handleUpdateVehicles}
                      onCancel={() => setEdit(false)}
                      testId="free-search-edit-form"
                    />
                  </Col>
                )}

                {tab?.regNumber ? (
                  <Text size="14" fontWeight="600" color="orange-900">
                    {t("cart:tab.regNum", { key: tab.regNumber })}
                  </Text>
                ) : null}
              </div>
            </Col>
            <Col span={16}>
              <Row gutter={ROW_GUTTER_TYPE.md.horizontal} justify="end">
                <Col>
                  <CartPreviewVehiclesDeliveryDate
                    loading={loadingCartFetch || loadingCartUpdate}
                    deliveryAt={tab?.deliveryDate}
                    locale={router.locale}
                    text={t("cart:tab.deliveryDate")}
                  />
                </Col>
                <Can I={PERMISSION_ACT.CREATE} this="cart_repair_estimate">
                  <Col>
                    <Button
                      type="primary"
                      ghost
                      block
                      disabled={loadingCartFetch || loadingCartUpdate || !isRepairEstimateAvailable}
                      onClick={handleCreateCartRepairEstimate}
                      testId="cart-preview-add-cart-repair-estimate-button"
                    >
                      {t("buttons:createRepairEstimate")}
                    </Button>
                  </Col>
                </Can>
                <Col>
                  <Button
                    type="primary"
                    ghost
                    block
                    disabled={loadingCartFetch || loadingCartUpdate}
                    loading={loadingCartPreviewAddSavedBasketFetch}
                    onClick={handleCreateSavedBasket}
                    testId="cart-preview-add-saved-basket-button"
                  >
                    {t("cart:tab.saveBtn")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
            <Col span={24}>
              <CommonTableAntDynamic
                loading={
                  loadingCartFetch ||
                  loadingCartUpdate ||
                  loadingCartPreviewProductUpdate ||
                  loadingCartPreviewProductRemove
                }
                data={tab?.products}
                columns={columns}
                rowSelection={rowSelection}
                customRow={CartPreviewProductsForm}
                customCell={CartCellCustom}
              />
            </Col>
          </Row>
          <Row justify="end" gutter={ROW_GUTTER_TYPE.md.horizontal}>
            <Col span={24}>
              <div className={st["delete-btn"]}>
                <Button
                  disabled={
                    loadingCartFetch || loadingCartUpdate || loadingCartPreviewVehiclesUpdate
                  }
                  onClick={handleDeleteSelected}
                  type="link"
                  size="small"
                  testId="vehicle-btn-delete"
                >
                  {t("cart:tab.deleteCar")}
                </Button>
              </div>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
            <Col span={24}>
              <DecorDivider gutter="empty" />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
