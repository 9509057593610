// Definitions
import type { TFunction } from "client/utils/i18n/types";

const PRICE_VART_OPTION = {
  notVat: "notVat",
  withVat: "withVat",
} as const;

const priceVatSwitcherOptionsRaw = [
  { id: 1, label: "common:notVat", value: PRICE_VART_OPTION.notVat },
  { id: 2, label: "common:withVat", value: PRICE_VART_OPTION.withVat },
];

export const getCartPreviewPriceVatSwitcherOptions = (t: TFunction) => {
  return priceVatSwitcherOptionsRaw.map((el) => {
    return { ...el, label: t(el.label) };
  });
};
