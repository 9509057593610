// Core
import getConfig from "next/config";

// Utils
import { verifyBuild } from "utils/verify-build";
import { isBuildDevelopmentSSR } from "utils/verify-build";

// Base
const API_PROTOCOL = "http";
const WS_PROTOCOL = "ws";
const HOST = "app.dev";
const API_PATH = "/api";
const GRAPHQL_PATH = "/graphql";
const PORT = 3000;

// Runtime
const { isDevelopment } = verifyBuild();
const publicRuntimeConfig = getConfig()?.publicRuntimeConfig;

// API Client base
const CLIENT_API_BASE_HOST = process.env.NEXT_PUBLIC_CLIENT_HOST || HOST;
const CLIENT_API_BASE_PORT = publicRuntimeConfig?.APP_PORT || process.env.PORT || PORT;
const CLIENT_API_BASE_HTTP_PROTOCOL =
  process.env.NEXT_PUBLIC_CLIENT_API_HTTP_PROTOCOL || API_PROTOCOL;
const CLIENT_API_BASE_WS_PROTOCOL = process.env.NEXT_PUBLIC_CLIENT_API_WS_PROTOCOL || WS_PROTOCOL;

// API Client prefix
const CLIENT_API_URL_HTTP_PREFIX = isDevelopment
  ? ""
  : `${CLIENT_API_BASE_HTTP_PROTOCOL}://${CLIENT_API_BASE_HOST}`;
const CLIENT_API_URL_WS_PREFIX = isDevelopment
  ? `${CLIENT_API_BASE_WS_PROTOCOL}://${CLIENT_API_BASE_HOST}:${CLIENT_API_BASE_PORT}`
  : `${CLIENT_API_BASE_WS_PROTOCOL}://${CLIENT_API_BASE_HOST}`;

// API Client
export const CLIENT_API_URL_HTTP_REST = `${CLIENT_API_URL_HTTP_PREFIX}${API_PATH}`;
export const CLIENT_API_URL_HTTP_GRAPHQL = `${CLIENT_API_URL_HTTP_PREFIX}${API_PATH}${GRAPHQL_PATH}`;
export const CLIENT_API_URL_WS_GRAPHQL = `${CLIENT_API_URL_WS_PREFIX}${API_PATH}${GRAPHQL_PATH}`;

// API Client SSR prefix
const CLIENT_SSR_API_URL_HTTP_PREFIX =
  isBuildDevelopmentSSR || isDevelopment
    ? `${CLIENT_API_BASE_HTTP_PROTOCOL}://${CLIENT_API_BASE_HOST}:${CLIENT_API_BASE_PORT}`
    : `${CLIENT_API_BASE_HTTP_PROTOCOL}://${CLIENT_API_BASE_HOST}`;

// API Client for SSR
export const CLIENT_SSR_API_URL_HTTP_REST = `${CLIENT_SSR_API_URL_HTTP_PREFIX}${API_PATH}`;
