// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventBannerNewsDataType,
  EventPayloadBannerNewsType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  BANNER_NEWS_ACTION_TYPE,
} from "client/utils/gtm/gtm.types";

class BannerNewsEvent extends AbstractGTMEventSegment<GTMTypesEnum.banner_news> {
  protected readonly segmentShape: EventPayloadBannerNewsType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: BANNER_NEWS_ACTION_TYPE.empty,
      [ParametersEnum.banner_position]: "",
    },
  };

  makeSegment(data: EventBannerNewsDataType): EventPayloadBannerNewsType {
    const { actionType, bannerPosition, bannerName, categoryId } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] = actionType;
    segment[PayloadKeysEnum.parameters][ParametersEnum.banner_position] = bannerPosition;

    if (bannerName) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.banner_name] = bannerName;
    }

    if (categoryId) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.category_id] = categoryId;
    }

    return segment;
  }
}

export const bannerNewsEvent: BannerNewsEvent = new BannerNewsEvent();
