// Definitions
import type { CartPreviewType, CartTabProductType } from "client/core/cart/models";
import type { ProductType } from "client/core/product-details";
import type { OrdersCheckoutType } from "client/core/orders-checkout/models";

export enum GTMTypesEnum {
  pageLoad = "page_load",
  registration = "registration",
  authorization = "authorization",
  search = "search",
  selector_car = "selector_car",
  catalog = "catalog",
  show_price = "show_price",
  add_to_cart = "add_to_cart",
  remove_from_cart = "remove_from_cart",
  view_cart = "view_cart",
  add_payment_info = "add_payment_info",
  begin_checkout = "begin_checkout",
  purchase = "purchase",
  add_tab = "add_tab",
  close_tab = "close_tab",
  tab_click = "tab_click",
  consent_mode_option = "consent_mode_option",
  oem_catalog_click = "OEM_catalog_click",
  oem_catalog_back = "OEM_catalog_back",
  empty_oem_page = "empty_oem_page",
  oem_category_click = "oem_category_click",
  schema_page_load = "schema_page_load",
  oem_number_click = "OEM_number_click",
  consent_mode_def = "consent_mode_def",
  view_item_list = "view_item_list",
  view_item = "view_item",
  select_item = "select_item",
  popup = "popup",
  header_top = "header_top",
  footer_menu = "footer_menu",
  notification_page = "notification_page",
  notification = "notification",
  banner = "banner",
  header_menu = "header_menu",
  banner_news = "banner_news",
  document_search = "document_search",
  document_sort = "document_sort",
  document_download = "document_download",
  order_sort = "order_sort",
  payment_method = "payment_method",
  checkout_btn = "checkout_btn",
  replay_payment = "replay_payment",
  track_order = "track_order",
  ecommerce_clear = "ecommerce_clear",
}

export enum PayloadKeysEnum {
  parameters = "parameters",
  ecommerce = "ecommerce",
}

export enum ParametersEnum {
  user_id = "user_id",
  visitor_type = "visitor_type",
  registration_step = "registration_step",
  query = "query",
  car_id = "car_id",
  car_title = "car_title",
  car_description = "car_description",
  vin_number = "vin_number",
  page_type = "page_type",
  url_path = "url_path",
  schema_count = "schema_count",
  schema_page_id = "schema_page_id",
  schema_name = "schema_name",
  oem_number = "oem_number",
  model_id = "model_id",
  engine_id = "engine_id",
  maker_id = "maker_id",
  basket_id = "basket_id",
  event_action = "event_action",
  event_object = "event_object",
  event_label = "event_label",
  parent_id = "parent_id",
  child_id = "child_id",
  shipping_date = "shipping_date",
  route_name = "route_name",
  free_search = "free_search",
  necessary = "necessary",
  statistics = "statistics",
  ad_storage = "ad_storage",
  analytics_storage = "analytics_storage",
  ad_storage_def = "ad_storage_def",
  analytics_storage_def = "analytics_storage_def",
  authorization_type = "authorization_type",
  error_type = "error_type",
  geo_country_code = "geo_country_code",
  crm_session_id = "crm_session_id",
  coupon = "coupon",
  banner_position = "banner_position",
  banner_name = "banner_name",
  banner_id = "banner_id",
  category_id = "category_id",
  category_image = "category_image",
  category_name = "category_name",
  notification_id = "notification_id",
  payment_type = "payment_type",
  transaction_id = "transaction_id",
}

export enum EcommerceEnum {
  currency = "currency",
  value = "value",
  items = "items",
  transaction_id = "transaction_id",
  shipping = "shipping",
  item_list_id = "item_list_id",
  payment_type = "payment_type",
  coupon = "coupon",
  used_bonus = "used_bonus",
  purchase_discount = "purchase_discount",
  tax = "tax",
}

export enum EcommerceItemsEnum {
  item_id = "item_id",
  item_name = "item_name",
  item_brand = "item_brand",
  item_category = "item_category",
  item_category2 = "item_category2",
  item_category3 = "item_category3",
  item_variant = "item_variant",
  price = "price",
  quantity = "quantity",
  affiliation = "affiliation",
  item_list_id = "item_list_id",
}

export enum EcommerceProductVariantsEnum {
  vin_compitable = "vin_compitable",
  vin_incompitable = "vin_incompitable",
}

export enum VisitorTypeEnum {
  logged_user = "logged_user",
  registered_user = "registered_user",
  unknown_user = "uknown_user",
}

export enum AuthorizationTypeEnum {
  authorization_type_success = "success",
  authorization_type_error = "error",
}

export enum ErrorTypeEnum {
  unknown = "unknown error",
  validation = "invalid validation",
  leadVerified = "lead not verified",
  leadRegistered = "lead already registered",
  forbidden = "access forbidden",
}
export const SELECTOR_CAR_EVENT_ACTION_KEYS = {
  car: "car",
  kba: "kba",
  history: "history",
  click_maker: "click_maker",
  click_model: "click_model",
  click_engine: "click_engine",
  choose_maker: "choose_maker",
  choose_model: "choose_model",
  choose_engine: "choose_engine",
  car_reset: "car_reset",
  clear_maker_only: "clear%maker%none%none",
  clear_maker_model_only: "clear%maker%model%none",
  clear_maker_model_engine: "clear%maker%model%engine",
} as const;

export type SelectorCarEventActionKeysType =
  (typeof SELECTOR_CAR_EVENT_ACTION_KEYS)[keyof typeof SELECTOR_CAR_EVENT_ACTION_KEYS];

export const SELECTOR_CAR_EVENT_OBJECT_KEYS = {
  popup: "popup",
  base: "base",
} as const;

export type SelectorCarEventObjectKeysType =
  (typeof SELECTOR_CAR_EVENT_OBJECT_KEYS)[keyof typeof SELECTOR_CAR_EVENT_OBJECT_KEYS];

export const CATALOG_EVENT_ACTION_KEYS = {
  click_open: "click_open",
  click_close: "click_close",
} as const;

export type CatalogEventActionType = keyof typeof CATALOG_EVENT_ACTION_KEYS;

export const CATALOG_EVENT_OBJECT_KEYS = {
  sidebar: "sidebar",
  main: "main",
} as const;

export type CatalogEventObjectType = keyof typeof CATALOG_EVENT_OBJECT_KEYS;

export enum ShowPriceEventActionEnum {
  show_price = "show_price",
  hide_price = "hide_price",
}

export enum CatalogTabCloseEventActionEnum {
  icon = "icon",
  limit = "limit",
}

export const ROUTE_NAME_KEYS = {
  product: "product",
  catalog: "catalog",
  cart: "cart",
  free_search: "free-search",
  oem_search: "oem-search",
  oem_catalog: "oem-catalog",
  oem_catalog_product: "oem-catalog-product",
  dashboard: "dashboard",
  promotions: "promotions",
  documents: "documents",
  orders: "orders",
  single_order: "single_order",
  checkout: "checkout",
  payment_adyen: "payment_adyen",
} as const;

export type RouteNameKeysType = (typeof ROUTE_NAME_KEYS)[keyof typeof ROUTE_NAME_KEYS];

export enum ConsentEnum {
  granted = "granted",
  denied = "denied",
}

export const AFFILIATION_TYPE = {
  "autodoc.pro": "autodoc.pro",
} as const;

export type AffiliationType = keyof typeof AFFILIATION_TYPE;

export const ITEM_LIST_ID_TYPE = {
  "catalog-items": "catalog-items",
  "free-search-items": "free-search-items",
  "oem-search-items": "oem-search-items",
  "product-items": "product-items",
  "cart-items": "cart-items",
  "oem-catalog-items": "oem-catalog-items",
  "oem-catalog-product-items": "oem-catalog-product-items",
} as const;

export type ItemListIdType = keyof typeof ITEM_LIST_ID_TYPE;

export const BANNER_NAME = {
  go_core: "go_core",
  dashboard: "dashboard",
  promotion: "promotion",
} as const;

export type BannerNameType = (typeof BANNER_NAME)[keyof typeof BANNER_NAME];

export const ANALYTICS_ACTION_TYPE = {
  view: "view",
  click: "click",
} as const;

export const HEADER_TOP_ACTION_TYPE = {
  banner_view: "banner_view",
  banner_click: "banner_click",
  empty: "",
} as const;

export type HeaderTopActionType =
  (typeof HEADER_TOP_ACTION_TYPE)[keyof typeof HEADER_TOP_ACTION_TYPE];

export const BANNER_NEWS_ACTION_TYPE = {
  banner_view: "banner_view",
  banner_click: "banner_click",
  empty: "",
} as const;

export type BannerNewsActionType =
  (typeof BANNER_NEWS_ACTION_TYPE)[keyof typeof BANNER_NEWS_ACTION_TYPE];

export const PAGE_VARIANT_TYPE = {
  categories: "categories",
  sub_categories: "sub_categories",
  schemas: "schemas",
  schema: "schema",
  listing: "listing",
} as const;

export type PageVariantType = (typeof PAGE_VARIANT_TYPE)[keyof typeof PAGE_VARIANT_TYPE];

export const POPUP_ACTION_TYPE = {
  go_core_view: "go_core_view",
  go_core_click: "go_core_click",
  nps_view: "nps_view",
  nps_click: "nps_click",
  empty: "",
} as const;

export const FOOTER_MENU_ITEM = {
  cookie: "cookie",
  legal_notice: "legal_notice",
  about: "about",
  go_core: "go_core",
  benefits: "benefits",
  "term-conditions": "term-conditions",
  "benefits-terms-conditions": "benefits-terms-conditions",
  "privacy-policy": "privacy-policy",
  code_of_conduct: "code_of_conduct",
  paymentDescriptions: "payment",
  delivery: "delivery",
  "core-exchange": "core-exchange",
  returns_refunds: "returns_refunds",
  empty: "",
} as const;

export type FooterMenuItemType = (typeof FOOTER_MENU_ITEM)[keyof typeof FOOTER_MENU_ITEM];

export const NOTIFICATION_ACTION_TYPE = {
  preview: "preview",
  click_bell: "click_bell",
  view_all_btn: "view_all_btn",
  view_more: "view_more",
  view_less: "view_less",
  go_core_shop: "go_core_shop",
  go_core_meet: "go_core_meet",
  read_all: "read_all",
  pre_read: "pre_read",
  view_full: "view_full",
  take_survey: "take_survey",
  benefits: "benefits",
  returns_new_draft: "returns_new_draft",
  change_password: "change_password",
  catalog: "catalog",
  loyalty_program: "loyalty_program",
  personal_manager: "personal_manager",
  company_working_hours: "company_working_hours",
  loyalty_program_bonus: "loyalty_program_bonus",
  returns_and_refunds: "returns_and_refunds",
  saved_baskets: "saved_baskets",
  cart_preview: "cart_preview",
  documents: "documents",
  settings_payment_method_mondu: "settings_payment_method_mondu",
  capsules_research: "capsules_research",
  order_page: "order_page",
  catalog_batteries: "catalog_batteries",
  catalog_tyres_and_accessories: "catalog_tyres_and_accessories",
  catalog_oils_and_fluids: "catalog_oils_and_fluids",
  catalog_brake_discs: "catalog_brake_discs",
  catalog_brake_pads: "catalog_brake_pads",
  graphic_catalog_categories: "graphic_catalog_categories",
  wiper_blades_discount: "wiper_blades_discount",
  timing_belt_kits: "timing_belt_kits",
  light_bulbs: "light_bulbs",
  spark_plugs: "spark_plugs",
  see_mondu_payment_option: "see_mondu_payment_option",
  empty: "",
} as const;

export type NotificationActionType =
  (typeof NOTIFICATION_ACTION_TYPE)[keyof typeof NOTIFICATION_ACTION_TYPE];

export const HEADER_MENU_ITEM = {
  main: "main",
  catalog: "catalog",
  orders: "orders",
  saved_baskets: "saved_baskets",
  documents: "documents",
  returns: "returns",
  new_return: "new_return",
  repair_estimates: "repair_estimates",
  promotions: "promotions",
  empty: "",
} as const;

export type HeaderMenuItemType = (typeof HEADER_MENU_ITEM)[keyof typeof HEADER_MENU_ITEM];

export const DOCUMENT_SEARCH_ACTION_TYPES = {
  empty: "",
  reset: "reset",
  search: "search",
} as const;

export type DocumentSearchActionType =
  (typeof DOCUMENT_SEARCH_ACTION_TYPES)[keyof typeof DOCUMENT_SEARCH_ACTION_TYPES];

export const DOCUMENT_TYPES = {
  empty: "",
  invoice: "invoice",
  "credit-note": "credit-note",
  "delivery-note": "delivery-note",
} as const;

export type DocumentType = (typeof DOCUMENT_TYPES)[keyof typeof DOCUMENT_TYPES];

export const DOCUMENT_COLUMN_VALUE = {
  createdAt: "date",
  type: "document",
  number: "document No.",
  priceAmountVat: "amount €, incl. VAT",
  priceAmountNoVat: "amount €, excl. VAT",
  orderId: "order No.",
  empty: "empty",
} as const;

export type DocumentColumnValueType = keyof typeof DOCUMENT_COLUMN_VALUE;

export const ORDER_COLUMN_VALUE = {
  empty: "empty",
  id: "order No.",
  createdAt: "date",
  totalPriceVat: "amount €, incl. VAT",
  totalPriceNoVat: "amount €, excl. VAT",
  status: "order_status",
  deliveryAt: "delivery_date",
} as const;

export type OrderColumnValueType = keyof typeof ORDER_COLUMN_VALUE;

export const PAYMENT_METHOD_ACTION = {
  click_adyen: "click_adyen",
  click_sepa: "click_sepa",
  click_mondu: "click_mondu",
  click_no_other: "click_no_other",
  click_bonus: "click_bonus",
  click_coupon: "click_coupon",
  change_card: "change_card",
  add_card: "add_card",
  empty: "",
} as const;

export type PaymentMethodActionType =
  (typeof PAYMENT_METHOD_ACTION)[keyof typeof PAYMENT_METHOD_ACTION];

export const SELECTED_PAYMENT_METHOD_KEY = {
  sepa: "sepa",
  sepa_bonus: "sepa_bonus",
  sepa_coupon: "sepa_coupon",
  mondu: "mondu",
  mondu_bonus: "mondu_bonus",
  mondu_coupon: "mondu_coupon",
  adyen: "adyen",
  adyen_bonus: "adyen_bonus",
  adyen_coupon: "adyen_coupon",
  empty: "empty",
} as const;

export type SelectedPaymentMethodKeyType = keyof typeof SELECTED_PAYMENT_METHOD_KEY;

export const SELECTED_PAYMENT_METHOD_VALUE = {
  sepa: "sepa",
  sepa_bonus: "sepa + bonus",
  sepa_coupon: "sepa + coupon",
  mondu: "mondu",
  mondu_bonus: "mondu + bonus",
  mondu_coupon: "mondu + coupon",
  adyen: "adyen",
  adyen_bonus: "adyen + bonus",
  adyen_coupon: "adyen + coupon",
  empty: "empty",
} as const;

export type SelectedPaymentMethodValueType =
  (typeof SELECTED_PAYMENT_METHOD_VALUE)[keyof typeof SELECTED_PAYMENT_METHOD_VALUE];

export type EventPayloadPageLoadType = {
  [PayloadKeysEnum.parameters]?: {
    [ParametersEnum.user_id]?: number;
    [ParametersEnum.crm_session_id]?: string;
    [ParametersEnum.geo_country_code]?: string;
    [ParametersEnum.visitor_type]?: VisitorTypeEnum;
    [ParametersEnum.route_name]?: string;
  };
} | null;

export type EventPageLoadDataType = {
  [ParametersEnum.user_id]?: number;
  [ParametersEnum.crm_session_id]?: string;
  [ParametersEnum.geo_country_code]?: string;
  [ParametersEnum.visitor_type]?: VisitorTypeEnum;
  [ParametersEnum.route_name]?: string;
};

export type EventPayloadAuthorizationType = {
  [PayloadKeysEnum.parameters]?: {
    [ParametersEnum.user_id]?: number;
    [ParametersEnum.visitor_type]?: VisitorTypeEnum;
    [ParametersEnum.authorization_type]?: AuthorizationTypeEnum;
    [ParametersEnum.error_type]?: ErrorTypeEnum;
  };
} | null;

export type EventAuthorizationDataType = {
  [ParametersEnum.user_id]?: number;
  [ParametersEnum.visitor_type]?: VisitorTypeEnum;
  [ParametersEnum.authorization_type]?: AuthorizationTypeEnum;
  [ParametersEnum.error_type]?: ErrorTypeEnum;
};

export type EventPayloadRegistrationType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.user_id]?: number;
    [ParametersEnum.visitor_type]?: VisitorTypeEnum;
    [ParametersEnum.registration_step]?: number;
  };
};

export type EventRegistrationDataType = {
  [ParametersEnum.user_id]?: number;
  [ParametersEnum.visitor_type]?: VisitorTypeEnum;
  [ParametersEnum.registration_step]?: number;
};

export type EventPayloadSearchType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.query]: string;
  };
};

export type EventSearchDataType = {
  [ParametersEnum.query]: string;
};

export type EventPayloadSelectorCarType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.engine_id]?: number;
    [ParametersEnum.event_action]: SelectorCarEventActionKeysType;
    [ParametersEnum.event_object]: SelectorCarEventObjectKeysType;
  };
};

export type EventSelectorCarDataType = {
  [ParametersEnum.maker_id]?: number;
  [ParametersEnum.model_id]?: number;
  [ParametersEnum.engine_id]?: number;
  [ParametersEnum.car_id]?: number;
  [ParametersEnum.event_action]: SelectorCarEventActionKeysType;
  [ParametersEnum.event_object]?: SelectorCarEventObjectKeysType;
};

export type EventPayloadCatalogType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.parent_id]: number;
    [ParametersEnum.child_id]?: number;
    [ParametersEnum.event_action]: CatalogEventActionType;
    [ParametersEnum.event_object]: CatalogEventObjectType;
  };
};
export type EventCatalogDataType = {
  [ParametersEnum.parent_id]?: number;
  [ParametersEnum.child_id]?: number;
  [ParametersEnum.event_action]?: CatalogEventActionType;
  [ParametersEnum.event_object]?: CatalogEventObjectType;
};

export type EventPayloadShowPriceType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: ShowPriceEventActionEnum;
  };
};
export type EventShowPriceDataType = {
  [ParametersEnum.event_action]: ShowPriceEventActionEnum;
};

export type EventCatalogTabDataType = {
  [ParametersEnum.car_id]: number | string;
};
export type EventCatalogTabPayloadType = {
  [PayloadKeysEnum.parameters]: EventCatalogTabDataType;
};

export type EventCatalogCloseTabDataType = EventCatalogTabDataType & {
  [ParametersEnum.event_action]?: CatalogTabCloseEventActionEnum;
};
export type EventCatalogCloseTabPayloadType = {
  [PayloadKeysEnum.parameters]: EventCatalogCloseTabDataType;
};

export type EventPayloadPurchaseItemsType = {
  [EcommerceItemsEnum.item_id]: number;
  [EcommerceItemsEnum.item_name]: string;
  [EcommerceItemsEnum.item_brand]: string;
  [EcommerceItemsEnum.affiliation]: AffiliationType;
  [EcommerceItemsEnum.price]: number;
  [EcommerceItemsEnum.quantity]: number;
  [EcommerceItemsEnum.item_variant]: EcommerceProductVariantsEnum;
  [EcommerceItemsEnum.item_category3]?: number;
  [EcommerceItemsEnum.item_list_id]: ItemListIdType;
};

export type EventPayloadProductItemType = {
  [EcommerceItemsEnum.item_id]: number;
  [EcommerceItemsEnum.item_name]: string;
  [EcommerceItemsEnum.item_brand]: string;
  [EcommerceItemsEnum.price]: number;
  [EcommerceItemsEnum.quantity]: number;
  [EcommerceItemsEnum.item_variant]: EcommerceProductVariantsEnum;
  [EcommerceItemsEnum.affiliation]: AffiliationType;
  [EcommerceItemsEnum.item_list_id]: ItemListIdType;
  [EcommerceItemsEnum.item_category]?: number;
  [EcommerceItemsEnum.item_category2]?: number;
  [EcommerceItemsEnum.item_category3]?: number | string;
};

export type EventPayloadAddToCartType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.car_id]?: number;
    [ParametersEnum.shipping_date]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventPayloadRemoveFromCartType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.shipping_date]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventPayloadCartProductItemType = {
  [EcommerceItemsEnum.item_id]: number;
  [EcommerceItemsEnum.item_name]: string;
  [EcommerceItemsEnum.item_brand]: string;
  [EcommerceItemsEnum.price]: number;
  [EcommerceItemsEnum.quantity]: number;
  [EcommerceItemsEnum.item_variant]: EcommerceProductVariantsEnum;
  [EcommerceItemsEnum.affiliation]: AffiliationType;
  [EcommerceItemsEnum.item_list_id]: ItemListIdType;
  [EcommerceItemsEnum.item_category3]?: number | string;
};

export type EventPayloadViewCartType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.shipping_date]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadCartProductItemType[][];
  };
};

export type EventPayloadBeginCheckoutType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.shipping_date]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventPayloadAddPaymentInfoType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]?: RouteNameKeysType;
    [ParametersEnum.shipping_date]?: number;
    [ParametersEnum.coupon]?: string;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.payment_type]: string;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventPayloadPaymentMethodType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: PaymentMethodActionType;
    [ParametersEnum.route_name]: RouteNameKeysType;
  };
};

export type CheckoutBtnCheckedType = "saved" | "not saved";

export type EventPayloadCheckoutBtnType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]: RouteNameKeysType;
    [ParametersEnum.event_label]?: CheckoutBtnCheckedType;
    [ParametersEnum.payment_type]: SelectedPaymentMethodValueType;
  };
};

export type EventPayloadReplayPaymentType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]: RouteNameKeysType;
    [ParametersEnum.payment_type]: SelectedPaymentMethodValueType;
    [ParametersEnum.transaction_id]: string;
    [ParametersEnum.shipping_date]: number;
  };
};

export type EventPayloadTrackOrderType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]: RouteNameKeysType;
    [ParametersEnum.transaction_id]: string;
    [ParametersEnum.shipping_date]: number;
  };
};

export type EventViewItemListPayloadType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.route_name]: RouteNameKeysType;
    [ParametersEnum.car_id]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.item_list_id]: ItemListIdType;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventViewItemPayloadType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.shipping_date]: number;
    [ParametersEnum.route_name]: RouteNameKeysType;
    [ParametersEnum.car_id]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventSelectItemPayloadType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.shipping_date]: number;
    [ParametersEnum.route_name]: RouteNameKeysType;
    [ParametersEnum.car_id]?: number;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.item_list_id]: ItemListIdType;
    [EcommerceEnum.items]: EventPayloadProductItemType[];
  };
};

export type EventClearEcommercePayloadType = {
  [PayloadKeysEnum.ecommerce]: null;
};

export type EventPayloadPurchaseType = {
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.transaction_id]?: string;
    [EcommerceEnum.shipping]?: number;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.tax]: number;
    [EcommerceEnum.coupon]?: string;
    [EcommerceEnum.used_bonus]?: number;
    [EcommerceEnum.purchase_discount]?: number;
    [EcommerceEnum.items]: EventPayloadPurchaseItemsType[];
  };
};

export type EventConsentOptionPayloadType = {
  [ParametersEnum.ad_storage]: ConsentEnum;
  [ParametersEnum.analytics_storage]: ConsentEnum;
};

export type EventConsentDefPayloadType = {
  [ParametersEnum.ad_storage_def]: ConsentEnum;
  [ParametersEnum.analytics_storage_def]: ConsentEnum;
};

export type EventPayloadBannerType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: string;
    [ParametersEnum.banner_position]: string;
    [ParametersEnum.banner_name]: string;
    [ParametersEnum.banner_id]: number;
  };
};

export type EventPayloadBannerNewsType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: string;
    [ParametersEnum.category_id]?: number;
    [ParametersEnum.banner_name]?: string;
    [ParametersEnum.banner_position]: string;
  };
};

export type EventPayloadOemCatalogClickType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.car_title]: string;
    [ParametersEnum.car_description]: string;
    [ParametersEnum.vin_number]: string;
    [ParametersEnum.car_id]: number;
  };
};

export type EventPayloadOemCatalogBackType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.car_title]: string;
    [ParametersEnum.car_description]: string;
    [ParametersEnum.vin_number]: string;
    [ParametersEnum.car_id]: number;
  };
};

export type EventPayloadEmptyOemPageType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.car_title]: string;
    [ParametersEnum.car_description]: string;
    [ParametersEnum.vin_number]: string;
    [ParametersEnum.car_id]: number;
    [ParametersEnum.page_type]: PageVariantType;
    [ParametersEnum.url_path]: string;
  };
};

export type EventPayloadOemCategoryClickType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.category_id]: string;
    [ParametersEnum.category_image]: boolean;
    [ParametersEnum.category_name]: string;
    [ParametersEnum.car_title]: string;
    [ParametersEnum.car_description]: string;
    [ParametersEnum.vin_number]: string;
    [ParametersEnum.car_id]: number;
  };
};

export type EventPayloadSchemaPageLoadType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.car_title]: string;
    [ParametersEnum.car_description]: string;
    [ParametersEnum.vin_number]: string;
    [ParametersEnum.car_id]: number;
    [ParametersEnum.schema_count]: number;
    [ParametersEnum.schema_page_id]: string;
    [ParametersEnum.schema_name]: string;
  };
};

export type EventPayloadOemNumberClickType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.car_title]: string;
    [ParametersEnum.car_description]: string;
    [ParametersEnum.vin_number]: string;
    [ParametersEnum.car_id]: number;
    [ParametersEnum.schema_page_id]: string;
    [ParametersEnum.schema_name]: string;
    [ParametersEnum.oem_number]: string;
  };
};

export type EventPayloadPopupType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: (typeof POPUP_ACTION_TYPE)[keyof typeof POPUP_ACTION_TYPE];
  };
};

export type EventPayloadHeaderTopType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: HeaderTopActionType;
    [ParametersEnum.category_id]?: number;
    [ParametersEnum.banner_name]?: string;
  };
};

export type EventPayloadFooterMenuType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: FooterMenuItemType;
  };
};

export type EventPayloadHeaderMenuType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: HeaderMenuItemType;
  };
};

export type EventPayloadNotificationPageType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: string;
  };
};

export type EventPayloadNotificationType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: NotificationActionType;
    [ParametersEnum.event_object]?: number;
    [ParametersEnum.notification_id]?: number;
  };
};

export type EventPayloadDocumentSearchType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: string;
    [ParametersEnum.event_object]: DocumentSearchActionType;
    [ParametersEnum.route_name]: RouteNameKeysType;
  };
};

export type EventPayloadDocumentDownloadType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]?: number;
    [ParametersEnum.event_label]: number;
    [ParametersEnum.event_object]: DocumentType;
    [ParametersEnum.route_name]: RouteNameKeysType;
  };
};

export type EventPayloadDocumentSortType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: string;
    [ParametersEnum.route_name]: RouteNameKeysType;
  };
};

export type EventPayloadOrderSortType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: string;
    [ParametersEnum.route_name]: RouteNameKeysType;
  };
};

export type EventViewItemListDataType = {
  itemListId: ItemListIdType;
  productsItems: ProductType[];
  categoryId?: number;
  subCategoryId?: number;
  route?: RouteNameKeysType;
  carId?: number;
};

export type EventViewItemDataType = {
  productData: ProductType;
  itemListId: ItemListIdType;
  categoryId?: number;
  subCategoryId?: number;
  route?: RouteNameKeysType;
  carId?: number;
};

export type EventSelectItemDataType = {
  itemListId: ItemListIdType;
  productData: ProductType;
  categoryId?: number;
  subCategoryId?: number;
  route?: RouteNameKeysType;
  carId?: number;
};

export type EventAddToCartDataType = {
  product: ProductType;
  quantity: number;
  itemListId: ItemListIdType;
  categoryId?: number;
  subCategoryId?: number;
  route?: RouteNameKeysType;
  carId?: number;
};

export type EventRemoveFromCartDataType = {
  product: CartTabProductType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export type EventViewCartDataType = {
  cartPreview: CartPreviewType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export type EventBeginCheckoutDataType = {
  ordersCheckout: OrdersCheckoutType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export type EventAddPaymentInfoDataType = {
  checkout: OrdersCheckoutType;
  itemListId: ItemListIdType;
  route?: RouteNameKeysType;
};

export type EventPurchaseDataType = {
  checkout: OrdersCheckoutType;
  orderId: number;
  itemListId: ItemListIdType;
};

export type EventConsentOptionDataType = {
  [ParametersEnum.necessary]: boolean;
  [ParametersEnum.statistics]: boolean;
};

export type EventConsentDefDataType = {
  [ParametersEnum.necessary]: boolean;
  [ParametersEnum.statistics]: boolean;
};

export type EventOemCatalogClickDataType = {
  [ParametersEnum.car_title]: string;
  [ParametersEnum.car_description]: string;
  [ParametersEnum.vin_number]: string;
  [ParametersEnum.car_id]: number;
};

export type EventOemCatalogBackDataType = {
  [ParametersEnum.car_title]: string;
  [ParametersEnum.car_description]: string;
  [ParametersEnum.vin_number]: string;
  [ParametersEnum.car_id]: number;
};

export type EventEmptyOemPageDataType = {
  [ParametersEnum.car_title]: string;
  [ParametersEnum.car_description]: string;
  [ParametersEnum.vin_number]: string;
  [ParametersEnum.car_id]: number;
  [ParametersEnum.page_type]: PageVariantType;
  [ParametersEnum.url_path]: string;
};

export type EventOemCategoryClickDataType = {
  [ParametersEnum.category_id]: string;
  [ParametersEnum.category_image]: boolean;
  [ParametersEnum.category_name]: string;
  [ParametersEnum.car_title]: string;
  [ParametersEnum.car_description]: string;
  [ParametersEnum.vin_number]: string;
  [ParametersEnum.car_id]: number;
};

export type EventSchemaPageLoadDataType = {
  [ParametersEnum.car_title]: string;
  [ParametersEnum.car_description]: string;
  [ParametersEnum.vin_number]: string;
  [ParametersEnum.car_id]: number;
  [ParametersEnum.schema_count]: number;
  [ParametersEnum.schema_page_id]: string;
  [ParametersEnum.schema_name]: string;
};

export type EventOemNumberClickDataType = {
  [ParametersEnum.car_title]: string;
  [ParametersEnum.car_description]: string;
  [ParametersEnum.vin_number]: string;
  [ParametersEnum.car_id]: number;
  [ParametersEnum.schema_page_id]: string;
  [ParametersEnum.schema_name]: string;
  [ParametersEnum.oem_number]: string;
};

export type EventBannerDataType = {
  bannerPosition: string;
  bannerType: BannerNameType;
  bannerName: string;
  bannerId: number;
};

export type EventBannerNewsDataType = {
  bannerPosition: string;
  bannerName?: string;
  categoryId?: number;
  actionType: BannerNewsActionType;
};

export type EventPopupDataType = {
  actionType: (typeof POPUP_ACTION_TYPE)[keyof typeof POPUP_ACTION_TYPE];
};

export type EventHeaderTopDataType = {
  actionType: HeaderTopActionType;
  bannerName?: string;
  categoryId?: number;
};

export type EventFooterMenuDataType = {
  actionType: FooterMenuItemType;
};

export type EventHeaderMenuDataType = {
  actionType: HeaderMenuItemType;
};

export type EventNotificationPageDataType = {
  totalNotifications: number;
  totalUnreadNotifications: number;
};

export type EventNotificationDataType = {
  actionType: NotificationActionType;
  notificationType?: number;
  notificationId?: number;
};

export type EventDocumentSearchDataType = {
  actionType: string;
  eventType: DocumentSearchActionType;
};

export type EventDocumentDownloadDataType = {
  documentNumber?: number;
  documentType: DocumentType;
  orderId: number;
  routeName: RouteNameKeysType;
};

export type EventDocumentSortDataType = {
  actionType: DocumentColumnValueType;
};

export type EventOrderSortDataType = {
  actionType: OrderColumnValueType;
};

export type EventPaymentMethodDataType = {
  actionType: PaymentMethodActionType;
  routeName: RouteNameKeysType;
};

export type EventCheckoutBtnDataType = {
  routeName: RouteNameKeysType;
  paymentType: SelectedPaymentMethodKeyType;
  saveCardType?: CheckoutBtnCheckedType;
};

export type EventReplayPaymentDataType = {
  routeName: RouteNameKeysType;
  paymentType: SelectedPaymentMethodKeyType;
  transactionId: number;
  shippingDate: number;
};

export type EventTrackOrderDataType = {
  routeName: RouteNameKeysType;
  transactionId: number;
  shippingDate: number;
};

export type EventPayloadType = {
  [GTMTypesEnum.pageLoad]: EventPayloadPageLoadType;
  [GTMTypesEnum.authorization]: EventPayloadAuthorizationType;
  [GTMTypesEnum.registration]: EventPayloadRegistrationType;
  [GTMTypesEnum.search]: EventPayloadSearchType;
  [GTMTypesEnum.selector_car]: EventPayloadSelectorCarType;
  [GTMTypesEnum.catalog]: EventPayloadCatalogType;
  [GTMTypesEnum.show_price]: EventPayloadShowPriceType;
  [GTMTypesEnum.add_to_cart]: EventPayloadAddToCartType;
  [GTMTypesEnum.begin_checkout]: EventPayloadBeginCheckoutType;
  [GTMTypesEnum.purchase]: EventPayloadPurchaseType;
  [GTMTypesEnum.add_tab]: EventCatalogTabPayloadType;
  [GTMTypesEnum.close_tab]: EventCatalogCloseTabPayloadType;
  [GTMTypesEnum.tab_click]: EventCatalogTabPayloadType;
  [GTMTypesEnum.consent_mode_option]: EventConsentOptionPayloadType;
  [GTMTypesEnum.consent_mode_def]: EventConsentDefPayloadType;
  [GTMTypesEnum.view_item_list]: EventViewItemListPayloadType;
  [GTMTypesEnum.view_item]: EventViewItemPayloadType;
  [GTMTypesEnum.select_item]: EventSelectItemPayloadType;
  [GTMTypesEnum.remove_from_cart]: EventPayloadRemoveFromCartType;
  [GTMTypesEnum.view_cart]: EventPayloadViewCartType;
  [GTMTypesEnum.add_payment_info]: EventPayloadAddPaymentInfoType;
  [GTMTypesEnum.popup]: EventPayloadPopupType;
  [GTMTypesEnum.header_top]: EventPayloadHeaderTopType;
  [GTMTypesEnum.footer_menu]: EventPayloadFooterMenuType;
  [GTMTypesEnum.header_menu]: EventPayloadHeaderMenuType;
  [GTMTypesEnum.notification_page]: EventPayloadNotificationPageType;
  [GTMTypesEnum.notification]: EventPayloadNotificationType;
  [GTMTypesEnum.banner]: EventPayloadBannerType;
  [GTMTypesEnum.banner_news]: EventPayloadBannerNewsType;
  [GTMTypesEnum.oem_catalog_click]: EventPayloadOemCatalogClickType;
  [GTMTypesEnum.oem_catalog_back]: EventPayloadOemCatalogBackType;
  [GTMTypesEnum.empty_oem_page]: EventPayloadEmptyOemPageType;
  [GTMTypesEnum.oem_category_click]: EventPayloadOemCategoryClickType;
  [GTMTypesEnum.schema_page_load]: EventPayloadSchemaPageLoadType;
  [GTMTypesEnum.oem_number_click]: EventPayloadOemNumberClickType;
  [GTMTypesEnum.document_search]: EventPayloadDocumentSearchType;
  [GTMTypesEnum.document_sort]: EventPayloadDocumentSortType;
  [GTMTypesEnum.document_download]: EventPayloadDocumentDownloadType;
  [GTMTypesEnum.order_sort]: EventPayloadOrderSortType;
  [GTMTypesEnum.payment_method]: EventPayloadPaymentMethodType;
  [GTMTypesEnum.checkout_btn]: EventPayloadCheckoutBtnType;
  [GTMTypesEnum.replay_payment]: EventPayloadReplayPaymentType;
  [GTMTypesEnum.track_order]: EventPayloadTrackOrderType;
  [GTMTypesEnum.ecommerce_clear]: EventClearEcommercePayloadType;
};

export type EventsDataType = {
  [GTMTypesEnum.pageLoad]: EventPageLoadDataType;
  [GTMTypesEnum.authorization]: EventAuthorizationDataType;
  [GTMTypesEnum.registration]: EventRegistrationDataType;
  [GTMTypesEnum.search]: EventSearchDataType;
  [GTMTypesEnum.selector_car]: EventSelectorCarDataType;
  [GTMTypesEnum.catalog]: EventCatalogDataType;
  [GTMTypesEnum.show_price]: EventShowPriceDataType;
  [GTMTypesEnum.add_to_cart]: EventAddToCartDataType;
  [GTMTypesEnum.begin_checkout]: EventBeginCheckoutDataType;
  [GTMTypesEnum.purchase]: EventPurchaseDataType;
  [GTMTypesEnum.add_tab]: EventCatalogTabDataType;
  [GTMTypesEnum.close_tab]: EventCatalogCloseTabDataType;
  [GTMTypesEnum.tab_click]: EventCatalogTabDataType;
  [GTMTypesEnum.consent_mode_option]: EventConsentOptionDataType;
  [GTMTypesEnum.consent_mode_def]: EventConsentDefDataType;
  [GTMTypesEnum.view_item_list]: EventViewItemListDataType;
  [GTMTypesEnum.view_item]: EventViewItemDataType;
  [GTMTypesEnum.select_item]: EventSelectItemDataType;
  [GTMTypesEnum.remove_from_cart]: EventRemoveFromCartDataType;
  [GTMTypesEnum.view_cart]: EventViewCartDataType;
  [GTMTypesEnum.add_payment_info]: EventAddPaymentInfoDataType;
  [GTMTypesEnum.popup]: EventPopupDataType;
  [GTMTypesEnum.header_top]: EventHeaderTopDataType;
  [GTMTypesEnum.footer_menu]: EventFooterMenuDataType;
  [GTMTypesEnum.header_menu]: EventHeaderMenuDataType;
  [GTMTypesEnum.notification_page]: EventNotificationPageDataType;
  [GTMTypesEnum.notification]: EventNotificationDataType;
  [GTMTypesEnum.banner]: EventBannerDataType;
  [GTMTypesEnum.banner_news]: EventBannerNewsDataType;
  [GTMTypesEnum.oem_catalog_click]: EventOemCatalogClickDataType;
  [GTMTypesEnum.oem_catalog_back]: EventOemCatalogBackDataType;
  [GTMTypesEnum.empty_oem_page]: EventEmptyOemPageDataType;
  [GTMTypesEnum.oem_category_click]: EventOemCategoryClickDataType;
  [GTMTypesEnum.schema_page_load]: EventSchemaPageLoadDataType;
  [GTMTypesEnum.oem_number_click]: EventOemNumberClickDataType;
  [GTMTypesEnum.banner_news]: EventBannerNewsDataType;
  [GTMTypesEnum.document_search]: EventDocumentSearchDataType;
  [GTMTypesEnum.document_sort]: EventDocumentSortDataType;
  [GTMTypesEnum.document_download]: EventDocumentDownloadDataType;
  [GTMTypesEnum.order_sort]: EventOrderSortDataType;
  [GTMTypesEnum.payment_method]: EventPaymentMethodDataType;
  [GTMTypesEnum.checkout_btn]: EventCheckoutBtnDataType;
  [GTMTypesEnum.replay_payment]: EventReplayPaymentDataType;
  [GTMTypesEnum.track_order]: EventTrackOrderDataType;
  [GTMTypesEnum.ecommerce_clear]: null;
};

export type EventsDataUnionType =
  | EventPageLoadDataType
  | EventAuthorizationDataType
  | EventRegistrationDataType
  | EventSearchDataType
  | EventSelectorCarDataType
  | EventCatalogDataType
  | EventShowPriceDataType
  | EventAddToCartDataType
  | EventBeginCheckoutDataType
  | EventPurchaseDataType
  | EventCatalogTabDataType
  | EventCatalogCloseTabDataType
  | EventConsentOptionDataType
  | EventConsentDefDataType
  | EventViewItemListDataType
  | EventViewItemDataType
  | EventSelectItemDataType
  | EventRemoveFromCartDataType
  | EventViewCartDataType
  | EventAddPaymentInfoDataType
  | EventPopupDataType
  | EventHeaderTopDataType
  | EventFooterMenuDataType
  | EventHeaderMenuDataType
  | EventNotificationPageDataType
  | EventNotificationDataType
  | EventBannerDataType
  | EventBannerNewsDataType
  | EventOemCatalogClickDataType
  | EventOemCatalogBackDataType
  | EventEmptyOemPageDataType
  | EventOemCategoryClickDataType
  | EventSchemaPageLoadDataType
  | EventOemNumberClickDataType
  | EventDocumentSearchDataType
  | EventDocumentSortDataType
  | EventDocumentDownloadDataType
  | EventOrderSortDataType
  | EventPaymentMethodDataType
  | EventCheckoutBtnDataType
  | EventReplayPaymentDataType
  | EventTrackOrderDataType
  | null;

export interface GTMServiceInterface {
  event(name: GTMTypesEnum, data: EventsDataType[GTMTypesEnum]): void;
}

export interface GTMSegmentBuilderInterface<T extends GTMTypesEnum = GTMTypesEnum> {
  makeSegment(data: EventsDataUnionType): EventPayloadType[T];
}

export type GTMConfigType = {
  gtmId: string;
  eventsMap: Map<GTMTypesEnum, GTMSegmentBuilderInterface>;
  gtmArgs?: {
    dataLayer?: object;
    dataLayerName?: string;
  };
  checkEventsConsentGranted?: (name: GTMTypesEnum) => boolean;
  active?: boolean;
};
