// Core
import { forwardRef, Ref, FC, ReactElement } from "react";
import cx from "classnames";

// Definitions
import type { SelectItem } from "./InputSelect.types";
import type { OnChangeValue } from "react-select/dist/declarations/src/types";

// Components
import Select, {
  Props,
  SelectInstance as ReactSelectInstance,
  SingleValueProps,
  MultiValueProps,
  OptionProps,
  components as selectComponents,
  StylesConfig,
  DropdownIndicatorProps,
  ControlProps,
  GroupHeadingProps,
} from "react-select";
import makeAnimated from "react-select/animated";
import { Checkbox } from "antd";
import { Icon } from "../../Icon";

// Utils
import st from "./Styles.module.less";

const components = {
  ...selectComponents,
  ...makeAnimated(),
};

type ControlPropsType = ControlProps<SelectItem>;
const ControlItem: FC<ControlPropsType> = ({ children, ...rest }) => {
  return <components.Control {...rest}>{children}</components.Control>;
};

type SelectItemValueProps = SingleValueProps<SelectItem>;
const SelectItemValue = (props: SelectItemValueProps) => {
  const { data } = props;

  return (
    <components.SingleValue {...props} className={st["select-item"]}>
      {data?.icon && <Icon name={data.icon} />} {data?.label}
    </components.SingleValue>
  );
};

type SelectItemMultiValueProps = MultiValueProps<SelectItem>;
const SelectItemMultiValue: FC<SelectItemMultiValueProps> = (props) => {
  const { data } = props;
  return (
    <components.MultiValue {...props}>
      {data?.icon && <Icon name={data.icon} />} {data?.label}
    </components.MultiValue>
  );
};

enum SelectOptionViewEnumType {
  text = "text",
  checkbox = "checkbox",
}

type SelectItemOptionProps = OptionProps<SelectItem> & {
  optionViewType?: keyof typeof SelectOptionViewEnumType;
};
export const SelectItemOption = (props: SelectItemOptionProps) => {
  const { children, ...other } = props;

  switch (props.optionViewType) {
    case SelectOptionViewEnumType.checkbox:
      return (
        <components.Option {...other}>
          <Checkbox checked={other.isSelected}>{other.data?.label}</Checkbox>
        </components.Option>
      );

    case SelectOptionViewEnumType.text:
      return (
        <components.Option {...other}>
          {other.data?.icon && <Icon name={other.data.icon} />} {other.data?.label}
        </components.Option>
      );
    default:
      return <components.Option {...other}>{children}</components.Option>;
  }
};

const GroupHeading: FC<GroupHeadingProps<SelectItem>> = (props) => {
  const {
    data: { label },
  } = props;
  return <components.GroupHeading {...props}>{label}</components.GroupHeading>;
};

type SelectIndicatorProps = DropdownIndicatorProps<SelectItem> & { loading?: boolean };
const SelectIndicator: FC<SelectIndicatorProps> = (props) => {
  const { selectProps, loading } = props;

  return (
    <components.DropdownIndicator {...props}>
      {loading ? (
        <Icon name="LoadingOutlined" />
      ) : selectProps.menuIsOpen ? (
        <Icon name="UpOutlined" />
      ) : (
        <Icon name="DownOutlined" />
      )}
    </components.DropdownIndicator>
  );
};

const selectStyles = ({
  isValid,
  controlSize = 40,
}: {
  isValid: boolean;
  controlSize?: number;
}): StylesConfig<SelectItem> => ({
  control: (styles, { menuIsOpen }) => {
    return {
      ...styles,
      minHeight: controlSize,
      borderRadius: "2px",
      border: isValid ? "1px solid #e7e9ea" : "1px solid red",
      cursor: "pointer",
      color: "#132530",
      boxShadow: menuIsOpen ? "0 0 0 1px #2684ff" : "none",
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      boxShadow: "0px 2px 4px rgba(19, 37, 48, 0.12), 0px 0px 8px rgba(19, 37, 48, 0.16)",
      borderRadius: "2px",
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      padding: 8,
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#132530",
      cursor: "pointer",
      backgroundColor: isDisabled ? void 0 : isSelected ? "#F1F4F6" : isFocused ? "white" : void 0,
      ":hover": {
        ...styles[":active"],
        background: "#F1F4F6",
        borderRadius: "2px",
      },
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled ? (isSelected ? "#F1F4F6" : "white") : void 0,
      },
    };
  },
  placeholder: (styles, { isFocused, isDisabled }) => {
    return {
      ...styles,
      color: !isDisabled ? (isFocused ? "#c7cfd4" : "#132530") : "#B8BDC0",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    };
  },
  dropdownIndicator: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? "#B8BDC0" : "#184460",
    };
  },
});

export type InputSelectChangeValue = OnChangeValue<SelectItem, boolean>;

export type InputSelectProps = Props<SelectItem> & {
  hasDisabled?: boolean;
  hasPrefix?: boolean;
  hasSearch?: boolean;
  hasValid?: boolean;
  hasFocused?: boolean;
  prefixNumber?: number;
  hasPrefixControl?: boolean;
  loading?: boolean;
  optionViewType?: keyof typeof SelectOptionViewEnumType;
  ghost?: boolean;
  withoutBorder?: boolean;
  type?: "header" | "footer";
  controlSize?: "large" | "small" | "default";
  widthAuto?: boolean;
};

type SelectInstance = ReactSelectInstance<SelectItem>;

export const InputSelect = forwardRef(
  (props: InputSelectProps, ref: Ref<SelectInstance>): ReactElement => {
    const {
      className,
      prefixNumber,
      hasDisabled = false,
      hasPrefix = false,
      hasSearch,
      hasValid = true,
      hasFocused = false,
      hasPrefixControl = false,
      loading = false,
      optionViewType = "text",
      ghost = false,
      withoutBorder = false,
      type,
      controlSize = "default",
      widthAuto = false,
      ...rest
    } = props;

    const selectStyle = cx(
      st.select,
      {
        [st.disabled]: hasDisabled,
        [st.prefix]: hasPrefix,
        [st.focused]: hasFocused,
        [st.ghost]: ghost,
        [st["without-border"]]: withoutBorder,
        [st["width-auto"]]: widthAuto,
        [st[controlSize]]: controlSize,
      },
      type && { [st[type]]: !!type },
      className,
    );

    const getInputSelectSize = (size: InputSelectProps["controlSize"]) => {
      switch (size) {
        case "large":
          return 48;
        case "default":
          return 40;
        case "small":
          return 24;
        default:
          return void 0;
      }
    };

    const prefixControlStyle = cx(st["prefix-control"], {
      [st.active]: hasFocused,
    });

    return (
      <Select
        {...rest}
        classNamePrefix={st.select}
        className={selectStyle}
        components={{
          ...components,
          Control: ({ children, ...controlProps }) => (
            <ControlItem {...controlProps}>
              {hasPrefixControl && <div className={prefixControlStyle}>{prefixNumber}</div>}
              {children}
            </ControlItem>
          ),
          SingleValue: (singleValueProps) => {
            return <SelectItemValue {...singleValueProps} />;
          },
          MultiValue: (multiValueProps) => {
            return <SelectItemMultiValue {...multiValueProps} />;
          },
          Option: (optionProps) => {
            return <SelectItemOption optionViewType={optionViewType} {...optionProps} />;
          },
          GroupHeading: (groupHeadingProps) => {
            return <GroupHeading {...groupHeadingProps} />;
          },
          DropdownIndicator: (indicatorProps) => {
            return <SelectIndicator loading={loading} {...indicatorProps} />;
          },
        }}
        isDisabled={hasDisabled || loading}
        isSearchable={hasSearch}
        ref={ref}
        styles={selectStyles({ isValid: hasValid, controlSize: getInputSelectSize(controlSize) })}
        instanceId={rest.name}
      />
    );
  },
);
InputSelect.displayName = "InputSelect";
