// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Button } from "components/ui/Button";
import { Link } from "components/common/Link";
import { CartBreakdownTotal } from "client/core/cart/components/cart-breakdown-total";
import { Text } from "components/ui/Typography/Text";
import { Can } from "components/common/Can";
import { WrapperPrice } from "components/common/WrapperPrice";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { book } from "init/book";
import { Trans, useTranslation } from "client/utils/i18n/client";

import st from "./styles.module.css";

type CartPriceEntityType = {
  value: number | null;
  country: string;
} | null;
type CartTotalPriceProps = {
  totalPrice?: CartPriceEntityType;
  totalPriceVat?: CartPriceEntityType;
  vatPrice?: CartPriceEntityType;
  vatPercentage?: number | null;
  buttonText: string;
  loadingFetch?: boolean;
  loadingUpdate?: boolean;
  loadingCreate?: boolean;
  onCreate?: () => void;
  hasDescription?: boolean;
  hasMandateUnconfirmed?: boolean;
  warningMessage?: null | JSX.Element;
};

export const CartTotalPrice = (props: CartTotalPriceProps) => {
  const {
    totalPrice,
    totalPriceVat,
    vatPrice,
    vatPercentage,
    buttonText,
    onCreate,
    loadingFetch,
    loadingUpdate,
    loadingCreate,
    hasDescription,
    hasMandateUnconfirmed,
    warningMessage = null,
  } = props;
  const { t } = useTranslation();

  const disableCreate = loadingFetch || loadingUpdate || hasMandateUnconfirmed;

  return (
    <article className={st["cart-total-price"]}>
      <CartBreakdownTotal
        loading={loadingFetch || loadingUpdate}
        countryCode={totalPrice?.country}
        textTotalInclVat={t("common:totalBlock.totalInclVat")}
        totalPrice={totalPriceVat?.value}
        totalPriceNotVat={
          <Can I={PERMISSION_ACT.READ} this={"cart_total_vat_price"}>
            <CartBreakdownTotal.Line
              label={t("common:totalBlock.totalExclVat")}
              testId="total-vat-price"
              price={
                <WrapperPrice
                  country={totalPrice?.country}
                  loading={loadingFetch || loadingUpdate}
                  value={totalPrice?.value}
                />
              }
            />
          </Can>
        }
        vatPrice={
          <CartBreakdownTotal.Line
            label={t("common:totalBlock.vat", {
              percent: String(vatPercentage ?? 0),
            })}
            price={
              <Can I={PERMISSION_ACT.READ} this={"cart_vat_price"}>
                <WrapperPrice
                  country={vatPrice?.country}
                  loading={loadingFetch || loadingUpdate}
                  value={vatPrice?.value}
                  testId="vat-price"
                />
              </Can>
            }
          />
        }
      />

      <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
        <Col span={24}>
          <div className={st["cart-total-price-actions"]}>
            <Button
              type="primary"
              size="large"
              danger
              block
              loading={loadingCreate}
              disabled={disableCreate}
              onClick={onCreate}
              testId="cart-total-price-button"
            >
              {buttonText}
            </Button>
          </div>
        </Col>
      </Row>
      {warningMessage}
      {hasDescription && (
        <Row
          gutter={ROW_GUTTER_TYPE.md.horizontal}
          className={st["cart-total-price-policy"]}
          data-test-id="cart-total-policy"
        >
          <Col span={24}>
            <Text size="14" box="full" color="gray-700">
              <Trans
                i18nKey={"cart:total.legalNotice"}
                components={{
                  termConditions: <Link key={book.termConditions} href={book.termConditions} />,
                  benefitsTermsConditions: (
                    <Link key={book.benefitsTermsConditions} href={book.benefitsTermsConditions} />
                  ),
                  privacyPolicy: <Link key={book.privacyPolicy} href={book.privacyPolicy} />,
                }}
              />
            </Text>
          </Col>
        </Row>
      )}
    </article>
  );
};
