// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventHeaderMenuDataType,
  EventPayloadHeaderMenuType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  HEADER_MENU_ITEM,
} from "client/utils/gtm/gtm.types";

class HeaderMenuEvent extends AbstractGTMEventSegment<GTMTypesEnum.header_menu> {
  protected readonly segmentShape: EventPayloadHeaderMenuType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: HEADER_MENU_ITEM.empty,
    },
  };

  makeSegment(data: EventHeaderMenuDataType): EventPayloadHeaderMenuType {
    const { actionType } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] = actionType;

    return segment;
  }
}

export const headerMenuEvent: HeaderMenuEvent = new HeaderMenuEvent();
