// Core
import cx from "classnames";
import { forwardRef } from "react";

// Components
import { InputBase } from "components/ui/FormFields/InputBase";
import { SearchBarVehicle } from "client/core/search-bar/components/search-bar-vehicle";

// Definitions
import type { InputRefBaseType } from "models/Base";
import type { InputBaseType } from "components/ui/FormFields/InputBase";

// Utils
import st from "./styles.module.css";

type SearchBarInputProps = InputBaseType & {
  isInputActive: boolean;
};

export const SearchBarInput = forwardRef<InputRefBaseType, SearchBarInputProps>((props, ref) => {
  const {
    type = "text",
    maxLength = 100,
    placeholder = "Search...",
    onChange,
    value = "",
    isInputActive,
    ...rest
  } = props;

  const searchBarInputStyles = cx(st["search-bar-input"], {
    [st["search-bar-input-active"]]: isInputActive,
  });

  return (
    <div className={searchBarInputStyles}>
      <SearchBarVehicle />
      <InputBase
        {...rest}
        className={st.input}
        type={type}
        size={"large"}
        ref={ref}
        autoComplete={"off"}
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
});

SearchBarInput.displayName = "SearchBarInput";
