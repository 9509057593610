// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventsDataUnionType,
  EventPayloadSchemaPageLoadType,
  EventSchemaPageLoadDataType,
} from "client/utils/gtm/gtm.types";
import { ParametersEnum, PayloadKeysEnum, GTMTypesEnum } from "client/utils/gtm/gtm.types";

class SchemaPageLoadEvent extends AbstractGTMEventSegment<GTMTypesEnum.schema_page_load> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.car_id]: 0,
      [ParametersEnum.car_title]: "",
      [ParametersEnum.car_description]: "",
      [ParametersEnum.vin_number]: "",
      [ParametersEnum.schema_count]: 0,
      [ParametersEnum.schema_page_id]: "",
      [ParametersEnum.schema_name]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadSchemaPageLoadType {
    const schemaPageLoadData = data as EventSchemaPageLoadDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters] = schemaPageLoadData;

    return segment;
  }
}

export const schemaPageLoadEvent: SchemaPageLoadEvent = new SchemaPageLoadEvent();
