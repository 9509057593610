// Core
import { useEffect } from "react";
import { useRouter } from "next/router";

// Utils
import { gtmService } from "client/utils/gtm";
import { GTMTypesEnum, ROUTE_NAME_KEYS, VisitorTypeEnum } from "client/utils/gtm/gtm.types";
import { getUniqueID } from "utils/helpers";

type ArgsType = {
  profileId?: number | null;
  profileCountryCode?: string;
};

const pageNeedsRouteKey = [
  ROUTE_NAME_KEYS.dashboard,
  ROUTE_NAME_KEYS.promotions,
  ROUTE_NAME_KEYS.documents,
  ROUTE_NAME_KEYS.orders,
];

export const useGTMPageLoad = (args: ArgsType) => {
  const { profileId = null, profileCountryCode } = args;
  const router = useRouter();
  const routeName = pageNeedsRouteKey.find((item) => router.pathname.indexOf(item) > -1) || null;

  useEffect(() => {
    gtmService?.event(GTMTypesEnum.pageLoad, {
      crm_session_id: getUniqueID(),
      ...(profileId && {
        user_id: profileId,
      }),
      ...(profileCountryCode && {
        geo_country_code: profileCountryCode,
      }),
      visitor_type: profileId ? VisitorTypeEnum.logged_user : VisitorTypeEnum.unknown_user,
      ...(routeName && {
        route_name: routeName,
      }),
    });
  }, [profileId, profileCountryCode, routeName]);
};
