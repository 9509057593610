// Definitions
import type {
  CartPreviewType,
  CartDeliveryType,
  CartProductAlternativeListType,
} from "client/core/cart/models";
import { CART_TAB_SELECT } from "client/core/cart/models";

export const cartKeys = {
  preview: "cart/preview",
  previewCount: "cart/preview/count",
  paymentDelivery: "cart/delivery-address",
  reapirEstimate: "cart/reapir-estimate",
  productsAlternatives: "cart/preview/products-alternatives",
};

export type CartStateType = {
  preview: CartPreviewType;
  deliveryAddress: CartDeliveryType;
  productsAlternatives: CartProductAlternativeListType;
};

export const initialCartState: CartStateType = {
  preview: {
    trackingId: "",
    selected: CART_TAB_SELECT.all,
    totalPrice: null,
    totalPriceVat: null,
    vatPrice: null,
    vatPercentage: null,
    deliveryDate: null,
    checkoutAvailable: false,
    tabs: [],
  },
  deliveryAddress: {
    firstName: "",
    companyName: "",
    surname: "",
    street: "",
    houseNumber: "",
    city: "",
    index: "",
    country: null,
    additionalAddress: "",
    phonePrefix: null,
    phoneNumber: "",
  },
  productsAlternatives: {
    items: [],
    meta: {
      page: 1,
      lastPage: 1,
      from: 0,
      to: 1,
      perPage: 15,
      total: 0,
    },
  },
};
