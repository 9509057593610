// Core
import { FC, PropsWithChildren, ReactNode } from "react";
import cx from "classnames";

// Utils
import st from "./Styles.module.css";

type FormActionsAlignmentHorizontalType = "left" | "center" | "right";

type FormActionsType = PropsWithChildren<{
  children: ReactNode;
  className?: string;
  center?: boolean;
  oneInput?: boolean;
  wrapper?: boolean;
  form?: boolean;
  alignmentHorizontal?: FormActionsAlignmentHorizontalType;
  testId?: string;
}>;

export const FormActions: FC<FormActionsType> = (props) => {
  const {
    children,
    className = "",
    center = false,
    wrapper = false,
    form = false,
    oneInput = false,
    alignmentHorizontal = "left",
    testId = "form-actions",
  } = props;
  const actionsStyles = cx(st["form-actions"], {
    [`${className}`]: className,
    [st.center]: center,
    [st.input]: oneInput,
    [st.wrapper]: wrapper,
    [st.form]: form,
    [st[`form-actions-alignment-horizontal-${alignmentHorizontal}`]]: Boolean(alignmentHorizontal),
  });

  return (
    <div className={actionsStyles} data-test-id={testId}>
      {children}
    </div>
  );
};
