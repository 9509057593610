// Core
import { SyntheticEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Definitions
import type { FormSubmitParams } from "models/Forms";

// Components
import { Form } from "antd";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { WrappedFormInput } from "components/common/WrappedFormInput";
import { Button, ButtonSizeType } from "components/ui/Button";
import { InputForm } from "components/ui/FormFields/InputForm";
import { FormBlock } from "components/ui/FormFields/FormBlock";
import { FormActions } from "components/ui/FormFields/FormActions";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { getFieldsMetaValidation } from "utils/forms";
import { cartStringEditForm, inputConfig, CART_STRING_FIELD_TYPE } from "./config";
import { useTranslation } from "client/utils/i18n/client";

type CartStringEditType = {
  title: string;
};

type CartStringEditFormPropsType = {
  loadingCreate?: boolean;
  loadingUpdate?: boolean;
  initialValue?: {
    title: string;
  };
  formSize?: ButtonSizeType;
  onSubmit?: (data: FormSubmitParams<CartStringEditType>) => void;
  onCancel?: () => void;
  testId?: string;
};

export const CartStringEditForm = (props: CartStringEditFormPropsType) => {
  const { t } = useTranslation();
  const {
    loadingCreate = false,
    loadingUpdate = false,
    initialValue = "",
    formSize = "large",
    onSubmit,
    onCancel,
    testId,
  } = props;

  const formProps = useForm<CartStringEditType>({
    defaultValues: { ...cartStringEditForm.shape, ...initialValue },
    resolver: zodResolver(cartStringEditForm.schema()),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState, setError, reset, setFocus } = formProps;
  const fieldsMeta = getFieldsMetaValidation(CART_STRING_FIELD_TYPE, formState);

  const handleSubmitForm = handleSubmit((values: CartStringEditType): void => {
    onSubmit?.({ values, acts: { setError, reset } });
  });

  const onSubmitForm = (event: SyntheticEvent) => {
    void (async () => {
      await handleSubmitForm(event);
    })();
  };

  const coloumnSize = formSize === "middle" ? 14 : 12;

  useEffect(() => {
    setFocus(CART_STRING_FIELD_TYPE.title);
  }, []);

  return (
    <Form layout="vertical" onFinish={onSubmitForm} data-test-id={testId}>
      <Row gutter={ROW_GUTTER_TYPE.md.horizontal} wrap={false}>
        <Col span={coloumnSize}>
          <FormBlock>
            <InputForm
              id={CART_STRING_FIELD_TYPE.title}
              name={CART_STRING_FIELD_TYPE.title}
              validateStatus={fieldsMeta.title.status}
              labelRequired
              {...fieldsMeta.title.helpText}
            >
              <WrappedFormInput
                name={CART_STRING_FIELD_TYPE.title}
                control={control}
                size={formSize}
                placeholder={t(inputConfig.title.placeholder)}
                maxLength={inputConfig.title.max}
                hasDisabled={loadingCreate || loadingUpdate}
              />
            </InputForm>
          </FormBlock>
        </Col>
        <Col span={12}>
          <FormActions>
            <Row gutter={ROW_GUTTER_TYPE.md.horizontal} wrap={false}>
              <Col flex="none">
                <Button
                  type="primary"
                  size={formSize}
                  htmlType="submit"
                  loading={loadingCreate || loadingUpdate}
                  testId="string-edit-form-submit"
                >
                  {t("buttons:save")}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size={formSize}
                  ghost
                  disabled={loadingCreate || loadingUpdate}
                  onClick={onCancel}
                  testId="string-edit-form-cancel"
                >
                  {t("buttons:cancel")}
                </Button>
              </Col>
            </Row>
          </FormActions>
        </Col>
      </Row>
    </Form>
  );
};
