// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  RouteNameKeysType,
  EventCheckoutBtnDataType,
  EventPayloadCheckoutBtnType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  SELECTED_PAYMENT_METHOD_VALUE,
} from "client/utils/gtm/gtm.types";

class CheckoutBtnEvent extends AbstractGTMEventSegment<GTMTypesEnum.checkout_btn> {
  protected readonly segmentShape: EventPayloadCheckoutBtnType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.route_name]: "" as RouteNameKeysType,
      [ParametersEnum.payment_type]: SELECTED_PAYMENT_METHOD_VALUE.empty,
    },
  };

  makeSegment(data: EventCheckoutBtnDataType): EventPayloadCheckoutBtnType {
    const { routeName, paymentType, saveCardType } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = routeName;
    segment[PayloadKeysEnum.parameters][ParametersEnum.payment_type] =
      SELECTED_PAYMENT_METHOD_VALUE[paymentType];

    if (saveCardType) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.event_label] = saveCardType;
    }

    console.log(segment);

    return segment;
  }
}

export const checkoutBtnEvent: CheckoutBtnEvent = new CheckoutBtnEvent();
