// Core
import { NextRouter } from "next/router";
import QS from "qs";

// Definitions
import type {
  NotificationsParamsType,
  NotificationTagType,
  NotificationReadType,
  FilterQueryParamsType,
  PaginationType,
} from "client/core/notifications/models";

export const initMetaState = {
  page: 1,
  total: 0,
  perPage: 20,
  path: "",
};

type FilterStaticQueryParamsType<T extends Record<string, unknown> | void = {}> =
  FilterQueryParamsType & Record<string, unknown> & T;

const getInitialQueries = (query?: FilterQueryParamsType | null) => {
  const DISABLED_VALUES = ["popup"];

  if (query) {
    const enabledQueries = Object.entries(query).filter(([key]) => !DISABLED_VALUES.includes(key));
    return Object.fromEntries(enabledQueries) as Record<string, string>;
  }

  return {};
};

const getParamsFilterStaticFromQueryStr = <T extends Record<string, unknown>>(
  query?: FilterStaticQueryParamsType<T>,
): FilterStaticQueryParamsType<Record<string, unknown>> => {
  const initialQuery = getInitialQueries(query);

  return QS.parse(initialQuery || "", { parseArrays: true }) ?? {};
};

export const getComposedQueryParams = (
  propsQuery: NotificationsParamsType["params"],
  urlQuery: NextRouter["query"],
) => {
  const initialQueryParams = {
    perPage: propsQuery?.perPage || initMetaState.perPage,
    page: propsQuery?.page || initMetaState.page,
    ...(propsQuery?.read && { read: propsQuery.read }),
    ...(propsQuery?.type && { type: propsQuery.type }),
  };

  const urlQueryParams = getParamsFilterStaticFromQueryStr<{
    type?: NotificationTagType;
    read?: NotificationReadType;
  }>(urlQuery);

  return {
    ...initialQueryParams,
    ...urlQueryParams,
    ...(urlQueryParams?.page && { page: Number(urlQueryParams.page) }),
    ...(urlQueryParams?.perPage && { perPage: Number(urlQueryParams.perPage) }),
  };
};

export const getFinalTableQuery = (
  params: FilterQueryParamsType,
  pagination: PaginationType | null = null,
): FilterQueryParamsType => {
  const TABLE_DEFAULT_PAGE = 1;

  const perPage = params.perPage || pagination?.perPage;
  const page = params.page || pagination?.page;
  const isPerPageChanged = params.perPage && params.perPage !== pagination?.perPage;
  const lastPage = pagination?.total && perPage && Math.ceil(pagination.total / Number(perPage));
  const availablePage = page && lastPage && isPerPageChanged ? TABLE_DEFAULT_PAGE : page;

  return {
    // do not remove keys with void, it's save order of query params
    page: void 0,
    perPage: void 0,
    ...(perPage && { perPage: Number(perPage) }),
    ...(page && { page: Number(availablePage) }),
  };
};
