// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadDocumentDownloadType,
  EventDocumentDownloadDataType,
  RouteNameKeysType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  DOCUMENT_SEARCH_ACTION_TYPES,
} from "client/utils/gtm/gtm.types";

class DocumentDownloadEvent extends AbstractGTMEventSegment<GTMTypesEnum.document_download> {
  protected readonly segmentShape: EventPayloadDocumentDownloadType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_label]: 0,
      [ParametersEnum.event_object]: DOCUMENT_SEARCH_ACTION_TYPES.empty,
      [ParametersEnum.route_name]: "" as RouteNameKeysType,
    },
  };

  makeSegment(data: EventDocumentDownloadDataType): EventPayloadDocumentDownloadType {
    const { documentNumber, documentType, orderId, routeName } = data;
    const segment = this.getSegment();

    if (documentNumber) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] = documentNumber;
    }

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_label] = orderId;
    segment[PayloadKeysEnum.parameters][ParametersEnum.event_object] = documentType;
    segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = routeName;

    return segment;
  }
}

export const documentDownloadEvent: DocumentDownloadEvent = new DocumentDownloadEvent();
