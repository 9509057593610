// Utils
import { globalBook } from "init/book";

const SURVEY_NPS_COUNTRY_CODE = {
  FR: "FR",
  DE: "DE",
  EN: "EN",
  AT: "AT",
  NL: "NL",
} as const;

export type SurveyNpsCountryCodeType = keyof typeof SURVEY_NPS_COUNTRY_CODE;

type SurveyNPSType = {
  link: { [key in SurveyNpsCountryCodeType]: string };
  targetDate: { [key in SurveyNpsCountryCodeType]: string };
  surveyName: string;
};

export const surveyNPS: SurveyNPSType = {
  link: {
    AT: globalBook.nps.at,
    DE: globalBook.nps.de,
    EN: globalBook.nps.en,
    FR: globalBook.nps.fr,
    NL: globalBook.nps.nl,
  },
  targetDate: {
    FR: "2024-10-31 23:59",
    DE: "2024-11-07 23:59",
    AT: "2024-11-07 23:59",
    NL: "2024-11-07 23:59",
    EN: "",
  },
  surveyName: "n_24",
};

export const getSurveyNPSLink = (
  customerID?: number | null,
  countryCode?: SurveyNpsCountryCodeType,
) => {
  if (customerID && countryCode)
    return `${surveyNPS.link[countryCode]}?usp=pp_url&entry.959627652=${customerID}`;

  return surveyNPS.link[countryCode || SURVEY_NPS_COUNTRY_CODE.EN];
};

export const getCapsulesLink = (
  customerID?: number | null,
  countryCode?: SurveyNpsCountryCodeType,
) => {
  if (customerID && countryCode)
    return `${globalBook.capsules[countryCode.toLowerCase()]}?utm_source=autodoc.pro&utm_medium=notification_bell&utm_campaign=capsules_survey&entry.1991422402=${customerID}`;

  return surveyNPS.link[countryCode || SURVEY_NPS_COUNTRY_CODE.EN];
};
