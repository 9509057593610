// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  RouteNameKeysType,
  EventTrackOrderDataType,
  EventPayloadTrackOrderType,
} from "client/utils/gtm/gtm.types";
import { ParametersEnum, PayloadKeysEnum, GTMTypesEnum } from "client/utils/gtm/gtm.types";

class TrackOrderEvent extends AbstractGTMEventSegment<GTMTypesEnum.track_order> {
  protected readonly segmentShape: EventPayloadTrackOrderType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.route_name]: "" as RouteNameKeysType,
      [ParametersEnum.transaction_id]: "0",
      [ParametersEnum.shipping_date]: 0,
    },
  };

  makeSegment(data: EventTrackOrderDataType): EventPayloadTrackOrderType {
    const { routeName, transactionId, shippingDate } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = routeName;
    segment[PayloadKeysEnum.parameters][ParametersEnum.transaction_id] = String(transactionId);
    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = shippingDate;

    return segment;
  }
}

export const trackOrderEvent: TrackOrderEvent = new TrackOrderEvent();
