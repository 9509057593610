// Components
import { Title } from "components/ui/Typography/Title";
import { PersonalManagerOperatorAssesmentForm } from "client/core/personal-manager/components/personal-manager-operator-assesment-form";

// Hooks
import { usePersonalManagerRatingCreate } from "client/core/personal-manager/hooks/use-personal-manager-rating-create";
import { useTranslation } from "client/utils/i18n/client";

// Utils
import st from "./styles.module.css";

export const PersonalManagerOperatorAssesment = () => {
  const { t } = useTranslation();
  const { loadingProfileManagerRatingCreate, onCreateProfileManagerRating } =
    usePersonalManagerRatingCreate();

  const operatorAssesmentInitialValues = { rating: 0 };

  return (
    <div className={st["personal-manager-operator-assesment"]}>
      <Title tag="h4" alignmentHorizontal="center">
        {t("personal-manager:operatorAssesment.title")}
      </Title>

      <div className={st["personal-manager-operator-assesment-content"]}>
        <PersonalManagerOperatorAssesmentForm
          loading={loadingProfileManagerRatingCreate}
          onSubmit={onCreateProfileManagerRating}
          initialValues={operatorAssesmentInitialValues}
        />
      </div>
    </div>
  );
};
