// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventsDataUnionType,
  EventPayloadOemCatalogClickType,
  EventOemCatalogClickDataType,
} from "client/utils/gtm/gtm.types";
import { ParametersEnum, PayloadKeysEnum, GTMTypesEnum } from "client/utils/gtm/gtm.types";

class OemCatalogClickEvent extends AbstractGTMEventSegment<GTMTypesEnum.oem_catalog_click> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.car_id]: 0,
      [ParametersEnum.car_title]: "",
      [ParametersEnum.car_description]: "",
      [ParametersEnum.vin_number]: "",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadOemCatalogClickType {
    const oemCatalogClickData = data as EventOemCatalogClickDataType;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters] = oemCatalogClickData;

    return segment;
  }
}

export const oemCatalogClickEvent: OemCatalogClickEvent = new OemCatalogClickEvent();
