// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type { EventPayloadHeaderTopType, EventHeaderTopDataType } from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  HEADER_TOP_ACTION_TYPE,
} from "client/utils/gtm/gtm.types";

class HeaderTopEvent extends AbstractGTMEventSegment<GTMTypesEnum.header_top> {
  protected readonly segmentShape: EventPayloadHeaderTopType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: HEADER_TOP_ACTION_TYPE.empty,
    },
  };

  makeSegment(data: EventHeaderTopDataType): EventPayloadHeaderTopType {
    const { actionType, bannerName, categoryId } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] = actionType;

    if (bannerName) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.banner_name] = bannerName;
    }

    if (categoryId) {
      segment[PayloadKeysEnum.parameters][ParametersEnum.category_id] = categoryId;
    }

    return segment;
  }
}

export const headerTopEvent: HeaderTopEvent = new HeaderTopEvent();
