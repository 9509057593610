// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";

// Hooks
import { useProfileContext } from "hooks/useProfileContext";
import { useTranslation } from "client/utils/i18n/client";

// Utils
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";

import st from "./styles.module.css";

export const PersonalManagerTitle = () => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  return (
    <Row
      gutter={ROW_GUTTER_TYPE.md.horizontal}
      className={st["personal-manager-title"]}
      justify="space-between"
      align="bottom"
    >
      <Col span={12}>
        <Title tag="h3" size="24">
          {t("personal-manager:pageTitle")}
        </Title>
      </Col>
      <Col span={12}>
        <div className={st["personal-manager-title-customer-id"]}>
          <Text size="20" fontWeight="600">
            <>{t("personal-manager:customerId")}:</>
          </Text>
          <Text size="20" fontWeight="600">
            {WrapperNodeDefault(profile?.customerId)}
          </Text>
        </div>
      </Col>
    </Row>
  );
};
