// Core
import { useMemo } from "react";

// Definitions
import type { CountriesType } from "client/core/user-country-selector/models";
import type { UserCountryItemType } from "bus/user/models";
import type { BaseUnknownRecordType } from "models/Base";
import { UserCountryCodesEnum } from "bus/user/models";

// Domains
import { userCountrySelectorDataKeys } from "client/core/user-country-selector/store";

// Utils
import { mapUserCountriesIcon } from "utils/maps";
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { userCountrySelectorService } from "client/core/user-country-selector/service";

export type UseUserCountrySelectorStaticDataFetchType = {
  loadingStaticData: boolean;
  countries: UserCountryItemType[];
};

type UseUserCountrySelectorStaticDataFetchProps = {
  country?: UserCountryCodesEnum;
};

type CountriesQueryKeyType = [
  string,
  {
    country?: string;
  },
];

export const useUserCountrySelectorStaticDataFetch = (
  props: UseUserCountrySelectorStaticDataFetchProps,
): UseUserCountrySelectorStaticDataFetchType => {
  const { country } = props;

  const queryParams = {
    ...(country && { country }),
  };

  const queryKey: CountriesQueryKeyType = [
    userCountrySelectorDataKeys.userCountrySelector,
    queryParams,
  ];
  const fetchCountriesProps = useQueryAsync<
    CountriesType,
    CountriesType,
    CountriesQueryKeyType,
    BaseUnknownRecordType
  >({
    key: queryKey,
    name: "fetchCountriesQuery",
    fetcher: userCountrySelectorService.fetchCountries,
  });

  const countries = fetchCountriesProps.data?.items || [];

  const countriesWithIcons = useMemo(
    () => (countries ? mapUserCountriesIcon(countries) : []),
    [countries],
  );

  return {
    loadingStaticData: fetchCountriesProps.isLoading,
    countries: countriesWithIcons,
  };
};
