// Definitions
import { IntlErrorCode } from "next-intl";
import type { IntlError } from "next-intl";
import type { TMessages } from "../types";

export function onError(error: IntlError) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error
    // eslint-disable-next-line no-console
    console.warn("Missing translations", error);
  } else {
    // console.log("FAILED TO LOAD TRANSLATIONS", error);
    // Other errors indicate a bug in the app and should be reported
    // reportToErrorTracking(error);
  }
}

export const isTranslationInvalid = (message: string) => {
  return message.includes("Fix this message") || message.includes("is not yet translated");
};

export function getMessageFallback({
  namespace,
  key,
  error,
}: {
  error: IntlError;
  key: string;
  namespace?: string;
}) {
  const path = [namespace, key].filter((part) => part != null).join(".");

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return path + " is not yet translated";
  } else {
    return "Fix this message: " + path;
  }
}

/** Replace old namespaces separator ":" with a simple ".", since it's not supported by next-intl */
export function formatTranslationKey(key: string) {
  return key.replaceAll(":", ".");
}

/**
 * Format individual message to be compatible with next-intl:
 * - replace "{{" with "{" and "}}" with "}"
 * */
export const formatMessageString = (message: string): string => {
  return message.replace(/{{/g, "{").replace(/}}/g, "}");
};

export const resolveMessagesWithReferences = (
  translations: TMessages,
  allMessages: TMessages = translations,
): TMessages => {
  // eslint-disable-next-line no-loops/no-loops
  for (const key in translations) {
    const value = translations[key];

    // recursively call nested objects
    if (typeof value === "object") {
      translations[key] = resolveMessagesWithReferences(value, allMessages);
      continue;
    }

    // replace $t() with the value of the variable
    translations[key] = value.replace(/\$t\(([^)]+)\)/g, (_, v) => {
      const variableKey = formatTranslationKey(v as string);

      const matchingTranslation = variableKey.split(".").reduce(
        (acc, part) => {
          if (typeof acc === "string") {
            return variableKey;
          }

          return acc[part];
        },
        allMessages as TMessages | string,
      );

      if (!matchingTranslation || typeof matchingTranslation === "object") {
        return variableKey;
      }

      return matchingTranslation;
    });
  }

  return translations;
};
