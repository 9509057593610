// Components
import { Grid } from "components/ui/Grid";
import { UserCountrySelectorForm } from "client/core/user-country-selector/components/user-country-selector-form";

// Hooks
import { useUserCountry } from "bus/user/hooks/useUserCountry";
import { useTranslation } from "client/utils/i18n/client";
import { useUserCountrySelectorStaticDataFetch } from "client/core/user-country-selector/hooks/use-user-country-selector-static-data-fetch";

// Utils
import st from "./styles.module.css";

type UserCountrySelectorProps = {
  testId: string;
};

export const UserCountrySelector = ({ testId }: UserCountrySelectorProps) => {
  const { md = true } = Grid.useBreakpoint();
  const { t } = useTranslation();
  const { userCountry, onUpdateUserCountry } = useUserCountry();
  const { loadingStaticData, countries } = useUserCountrySelectorStaticDataFetch({
    country: userCountry,
  });

  const label = md ? t("common:countrySelect") : "";

  return (
    <div className={st["user-country-selector"]} data-test-id={testId}>
      <UserCountrySelectorForm
        testId={`${testId}-form`}
        loading={loadingStaticData}
        label={label}
        options={countries}
        country={userCountry}
        onSubmit={onUpdateUserCountry}
        ghost
        withoutBorder
        type="header"
        controlSize="small"
      />
    </div>
  );
};
