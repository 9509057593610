// Core
import { CookiesProvider as ReactCookiesProvider } from "react-cookie";

// Utils
import { COOKIE_MAX_AGE } from "utils/constants";

export const CookiesProvider = ({ children }: { children: React.ReactElement }) => {
  return (
    <ReactCookiesProvider
      defaultSetOptions={{
        path: "/",
        maxAge: COOKIE_MAX_AGE,
      }}
    >
      {children}
    </ReactCookiesProvider>
  );
};
