// Core
import {
  useLocale as useNextIntlLocale,
  useTranslations as useNextIntlTranslations,
} from "next-intl";

// Definitions
import type { TFunction, TranslationValues, TRawFunction, TRawParams } from "../types";

// Utils
import { formatTranslationKey } from "../lib/utils";

export const useTranslation = (namespace?: string) => {
  const nextIntlTranslateFunction = useNextIntlTranslations(namespace);
  const nextIntlLocale = useNextIntlLocale();

  const t: TFunction = <T>(key: string, values?: TranslationValues) => {
    return nextIntlTranslateFunction(formatTranslationKey(key), values) as T;
  };

  const tRaw: TRawFunction = <T>(key: string, params?: TRawParams) => {
    const value = nextIntlTranslateFunction.raw(formatTranslationKey(key)) as T;

    if (params?.as === "array") {
      if (!value || typeof value !== "object") {
        // eslint-disable-next-line no-console
        console.error(
          `Translation: Failed to convert '${key}' to array. Raw value is not an object`,
        );
        return key;
      }
      return Object.values(value) as T;
    }

    if (params?.as === "object") {
      if (!value || typeof value !== "object") {
        // eslint-disable-next-line no-console
        console.error(
          `Translation: Failed to convert '${key}' to object. Raw value is not an object`,
        );
        return key;
      }
      return value as T;
    }

    return value;
  };

  return {
    t,
    tRaw,
    i18n: {
      language: nextIntlLocale,
    },
  };
};
