// Core
import { SyntheticEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Components
import { Form } from "antd";
import { InputForm } from "components/ui/FormFields/InputForm";
import { WrappedFormSelect } from "components/common/WrappedFormSelect";

// Definitions
import { USER_COUNTRY_SELECTOR_FIELDS } from "client/core/user-country-selector/components/user-country-selector-form/config";
import type { FormSubmitParams } from "models/Forms";
import type { UserCountryItemType, UserChangeCountrySelectorPayload } from "bus/user/models";

// Utils
import { userCountrySelectorForm } from "client/core/user-country-selector/components/user-country-selector-form/config";

type UserCountrySelectorFormType = {
  loading?: boolean;
  label?: string;
  country?: string;
  options?: UserCountryItemType[];
  onSubmit?: (values: FormSubmitParams<UserChangeCountrySelectorPayload>) => void;
  ghost?: boolean;
  withoutBorder?: boolean;
  type?: "header" | "footer";
  controlSize?: "large" | "small" | "default";
  testId?: string;
};

export const UserCountrySelectorForm = (props: UserCountrySelectorFormType) => {
  const {
    label,
    country,
    options = [],
    loading = false,
    onSubmit,
    ghost = false,
    withoutBorder = false,
    type,
    controlSize,
    testId,
  } = props;

  const initialCountry = options.find((el) => el?.value === country) || null;
  const formProps = useForm<UserChangeCountrySelectorPayload>({
    defaultValues: { ...userCountrySelectorForm.shape, country: initialCountry },
    resolver: zodResolver(userCountrySelectorForm.schema()),
    mode: "all",
    reValidateMode: "onChange",
  });
  const { control, handleSubmit, formState, watch, setValue } = formProps;
  const { isSubmitting } = formState;

  const handleSubmitForm = handleSubmit((values: UserChangeCountrySelectorPayload): void => {
    onSubmit?.({ values });
  });

  const submitForm = (event: SyntheticEvent) => {
    void (async () => {
      await handleSubmitForm(event);
    })();
  };

  useEffect(() => {
    if (!initialCountry) {
      return;
    }
    setValue("country", initialCountry);
  }, [setValue, initialCountry]);

  useEffect(() => {
    const subscription = watch(() => void handleSubmitForm());
    return () => subscription.unsubscribe();
  }, [watch, initialCountry]);

  return (
    <Form onFinish={submitForm} layout={"vertical"} data-test-id={testId}>
      <InputForm
        name={USER_COUNTRY_SELECTOR_FIELDS.country}
        loading={loading}
        type={type}
        label={label}
        skeletonSize={controlSize}
      >
        <WrappedFormSelect
          name={USER_COUNTRY_SELECTOR_FIELDS.country}
          control={control}
          options={options}
          hasDisabled={isSubmitting}
          ghost={ghost}
          withoutBorder={withoutBorder}
          type={type}
          controlSize={controlSize}
          menuPlacement="auto"
        />
      </InputForm>
    </Form>
  );
};
