// Definitions
import type { TFunction } from "client/utils/i18n/types";
import { HEADER_BANNER_TYPES } from "../models";

export const COUNTRY_CODE = {
  FR: "FR",
  DE: "DE",
  EN: "EN",
  AT: "AT",
  NL: "NL",
} as const;

export type CountryCodeType = keyof typeof COUNTRY_CODE;

export const getGoCorePDFLink = (country?: CountryCodeType) => {
  const countryCode = country || COUNTRY_CODE.EN;

  const fileList: Record<CountryCodeType, string> = {
    [COUNTRY_CODE.EN]:
      "https://scdn.autodoc.pro/gocore_brochure/en/Brochure%20for%20AUTODOC%20PRO.pdf",
    [COUNTRY_CODE.FR]:
      "https://scdn.autodoc.pro/gocore_brochure/fr/Brochure%20for%20AUTODOC%20PRO.pdf",
    [COUNTRY_CODE.DE]:
      "https://scdn.autodoc.pro/gocore_brochure/de/Brochure%20for%20AUTODOC%20PRO.pdf",
    [COUNTRY_CODE.AT]:
      "https://scdn.autodoc.pro/gocore_brochure/de/Brochure%20for%20AUTODOC%20PRO.pdf",
    [COUNTRY_CODE.NL]:
      "https://scdn.autodoc.pro/gocore_brochure/nl/Brochure%20for%20AUTODOC%20PRO.pdf",
  };

  return fileList[countryCode] ? fileList[countryCode] : fileList[COUNTRY_CODE.EN];
};

type HotTickerAnalyticDataType = {
  bannerName?: string;
  categoryId?: number;
};

export type HotTickerComponent = {
  name: string;
  link: string;
};

export type HotTrickerContentItemType = {
  text: string;
  date?: string;
  format?: HotTickerDateFormat;
  permissionKey: string;
  order: number;
  surveyName?: string;
  type: keyof typeof HEADER_BANNER_TYPES;
  components: HotTickerComponent[];
  analytics?: HotTickerAnalyticDataType;
};

type HotTrickerContentLinkedListItemType = {
  data: HotTrickerContentItemType;
  next: number;
};

export const createContentLinkedList = (
  data: HotTrickerContentItemType[],
): HotTrickerContentLinkedListItemType[] => {
  return data.map((item, n) => ({
    data: item,
    next: n + 1 >= data.length ? 0 : n + 1,
  }));
};

export type HotTickerDateFormat = "dd hh mm" | "hh mm";

type FormatDateParams = {
  days: string;
  hours: string;
  minutes: string;
  format?: HotTickerDateFormat;
  t: TFunction;
};

export const formatDate = (params: FormatDateParams) => {
  const { days, hours, minutes, format = "dd hh mm", t } = params;

  if (format === "hh mm") {
    return `${hours}${t("common:survey.timer.hour")}:${minutes}${t("common:survey.timer.minute")}`;
  }

  return `${days}${t("common:survey.timer.day")}, ${hours}${t("common:survey.timer.hour")}:${minutes}${t("common:survey.timer.minute")}`;
};
