// Core
import variables from "./Styles.module.less";

export const modalTypes: {
  [key: string]: string;
} = {
  SEARCH_BAR: "SEARCH_BAR",
  CUSTOMER_CAR: "CUSTOMER_CAR",
  TRACKING_ORDER: "TRACKING_ORDER",
  CATALOG_OIL_SELECTOR: "CATALOG_OIL_SELECTOR",
};

export const getModalWidth = (type?: string, autoWidth?: boolean): string => {
  if (autoWidth) {
    return variables.modalAutoWidth;
  }
  if (type === modalTypes.SEARCH_BAR) {
    return variables.modalSearchBarWidth;
  }
  if (type === modalTypes.CUSTOMER_CAR) {
    return variables.modalVehicleWidth;
  }
  if (type === modalTypes.TRACKING_ORDER) {
    return variables.modalTrackWidth;
  }
  if (type === modalTypes.CATALOG_OIL_SELECTOR) {
    return variables.modalCatalogOilSelector;
  }

  return variables.modalDefaultWidth;
};
