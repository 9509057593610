// Definitions
import type { LightListItemType } from "components/ui/LightList";
import type { LinkProps } from "components/common/Link";
import type { FooterMenuItemType } from "client/utils/gtm/gtm.types";
import type { TFunction } from "client/utils/i18n/types";

// Component
import { Link } from "components/common/Link";

// Utils
import type { MenuNavItemData } from "data/footer";

type FooterNavModelProps = {
  items: MenuNavItemData[];
  t?: TFunction;
  onEvent: (link: FooterMenuItemType) => void;
};

export const footerNavModelNew = (props: FooterNavModelProps): LightListItemType[] => {
  const { items, t, onEvent } = props;
  return items.map((item) => {
    const textValue = t?.(String(item.text)) || "";
    const CellLink = (linkProps: LinkProps) => Link({ ...linkProps, size: "16", type: "default" });
    const handleClick = () => {
      onEvent(item.analyticKey);
    };

    return {
      key: item.key,
      link: {
        href: item.href,
        component: CellLink,
        blank: item.asBlankLink,
      },
      text: textValue,
      handleTrackClick: handleClick,
    };
  });
};
