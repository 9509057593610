// Core
import { toPairs, mapObjIndexed } from "ramda";

// Definitions
import { SortType, SortEnum } from "models/Sort";
import type { SelectItem } from "components/ui/FormFields/InputSelect/InputSelect.types";
import type { UserCountryItemType } from "bus/user/models";

// Data
import { countryIcons } from "data/locales";

export const mapItemWithIcon = (item?: SelectItem, locale?: string): SelectItem => {
  const localeItem = countryIcons.find((el) => !!locale && el.value === locale);

  return item
    ? {
        ...item,
        ...(localeItem?.icon && {
          icon: localeItem.icon,
        }),
      }
    : null;
};

export const mapCountriesToIcons = (items: SelectItem[]): SelectItem[] =>
  items.map((item) => mapItemWithIcon(item, String(item?.value)));

export const getCountryItemByLocale = (items: SelectItem[], locale: string): SelectItem => {
  const countryItem = items.find((el) => el?.value === locale.toLocaleLowerCase());
  return mapItemWithIcon(countryItem, String(countryItem?.value));
};

export const convertSortParametersToSortingRules = (sortQuery?: string | number | SortType) => {
  if (sortQuery && typeof sortQuery === "object" && toPairs(sortQuery).length) {
    const getAntdSortFromShortString = (value: string) =>
      value === SortEnum.desc ? "descend" : "ascend";
    return mapObjIndexed(getAntdSortFromShortString, sortQuery);
  }
  return {};
};

export const mapUserCountryItemWithIcon = (
  item?: UserCountryItemType | SelectItem,
  locale?: string,
): UserCountryItemType => {
  const localeItem = countryIcons.find((el) => !!locale && el.value === locale);
  const countryItem = item as UserCountryItemType;
  return countryItem
    ? {
        ...countryItem,
        ...(localeItem?.icon && {
          icon: localeItem.icon,
        }),
      }
    : null;
};

export const getUserCountryItemByCountryCode = (
  items: UserCountryItemType[] = [],
  country = "",
): UserCountryItemType => {
  const countryItem = items.find((el) => el?.value === country);
  return mapUserCountryItemWithIcon(countryItem, countryItem?.value);
};

export const mapUserCountriesIcon = (
  items: UserCountryItemType[] | SelectItem[],
): UserCountryItemType[] => {
  return items.map((item) => {
    return mapUserCountryItemWithIcon(item, String(item?.value));
  });
};

export const mapUserCountriesLabel = (items: UserCountryItemType[]): UserCountryItemType[] => {
  return items.map((item) => {
    return item ? { ...item, label: item?.name || item.label } : null;
  });
};
