// Core
import { Fragment, useMemo } from "react";

// Components
import { DecorDivider } from "components/common/DecorDivider";
import { SkeletonItems } from "components/ui/SkeletonItems";
import { Button } from "components/ui/Button";
import { NoData } from "components/ui/NoData";
import { NotificationsPreviewMessage } from "client/core/notifications/components/notifications-preview-message/notifications-preview-message";

// Hooks
import { useNotificationsFetch } from "client/core/notifications/hooks/use-notifications-fetch";
import { useNotificationsViewAll } from "client/core/notifications/hooks/use-notifications-view-all";
import { useNotificationsTrackViewPreview } from "client/core/notifications/hooks/analytics/use-notifications-track-view-preview";
import { useTranslation } from "client/utils/i18n/client";

// Definitions
import type { MouseEvent } from "react";
import { NOTIFICATION_ACTION_TYPE } from "client/utils/gtm/gtm.types";

// Utils
import st from "./styles.module.css";
import { triggerNotificationGtmEvent } from "client/core/notifications/utils/notifications-gtm-events";

export const NotificationsNavbar = () => {
  const { t } = useTranslation();
  const { loadingNotificationsFetch, notifications } = useNotificationsFetch();
  const { onViewAllNotifications } = useNotificationsViewAll();
  const onShowAllNotifications = () => {
    triggerNotificationGtmEvent({
      actionType: NOTIFICATION_ACTION_TYPE.view_all_btn,
    });
    onViewAllNotifications();
  };
  useNotificationsTrackViewPreview();

  const notificationsNotRead = useMemo(
    () => notifications.filter((notif) => !notif.isRead).slice(0, 5),
    [notifications],
  );

  const onClickContainer = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (loadingNotificationsFetch) {
    return (
      <div className={st["notifications-navbar"]} onClick={onClickContainer}>
        {[1, 2, 3].map((_, index) => {
          return (
            <Fragment key={index}>
              <SkeletonItems items={1} active className={st["skeleton-content"]} />
              <SkeletonItems items={1} active className={st["skeleton-content-time"]} />
              <DecorDivider className={st["notifications-navbar-divider"]} />
            </Fragment>
          );
        })}
      </div>
    );
  }

  return (
    <div className={st["notifications-navbar"]} onClick={onClickContainer}>
      {!notificationsNotRead.length ? (
        <NoData />
      ) : (
        <div>
          {notificationsNotRead.map((notification, index) => (
            <NotificationsPreviewMessage
              key={notification.id}
              data={notification}
              notificationNo={index}
              totalNotifications={notifications.length}
            />
          ))}
          <div className={st["notifications-navbar-action-container"]}>
            <Button type="primary" ghost size="middle" onClick={onShowAllNotifications}>
              {t("buttons:viewAllNotifications")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
