// Core
import cx from "classnames";

// Components
import { CartEmpty } from "client/core/cart/components/cart-empty";
import { CartContent } from "client/core/cart/components/cart-content";
import { CartContentLayout } from "client/core/cart/components/cart-content-layout";
import { CartServices } from "client/core/cart/components/cart-services";

// Utils
import {
  CartStoreProvider,
  CartStoreConsumer,
} from "client/core/cart/components/cart-store-provider";

import st from "./styles.module.css";

export const CartMain = () => {
  return (
    <CartStoreProvider>
      <CartStoreConsumer>
        {({ availableCart }) => {
          const cartStyles = cx(st.cart, {
            [st["cart-layout"]]: !availableCart,
          });

          return (
            <div className={cartStyles}>
              {availableCart ? (
                <CartContentLayout>
                  <CartEmpty />
                  <CartServices />
                </CartContentLayout>
              ) : (
                <CartContent />
              )}
            </div>
          );
        }}
      </CartStoreConsumer>
    </CartStoreProvider>
  );
};
