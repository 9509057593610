// Core
import { toPairs, mapObjIndexed } from "ramda";

// Definition
import type { TFunction, TranslationValues } from "client/utils/i18n/types";

export const getFieldLabel = (key: string, isPlaceholder?: boolean): string => {
  const entity = isPlaceholder ? "placeholder" : "label";

  return `inputs:${key}.${entity}`;
};

export const getHeader = (
  main: string,
  key: string,
  t: TFunction,
  options?: TranslationValues,
): string => {
  return t(`${main}.${key}`, options);
};

enum SortEnum {
  asc = "asc",
  desc = "desc",
}
type SortType = {
  [key: string]: SortEnum.asc | SortEnum.desc;
};
export const convertSortParametersToSortingRules = (sortQuery?: string | number | SortType) => {
  if (sortQuery && typeof sortQuery === "object" && toPairs(sortQuery).length) {
    const getAntdSortFromShortString = (value: string) =>
      value === SortEnum.desc ? "descend" : "ascend";
    return mapObjIndexed(getAntdSortFromShortString, sortQuery);
  }
  return {};
};
