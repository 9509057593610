// Core
import { useFormContext } from "react-hook-form";
import { PropsWithChildren, Fragment, useState } from "react";

// Components
import { InputForm } from "components/ui/FormFields/InputForm";
import { Tooltip } from "components/ui/Tooltip";
import { WrappedFormInputCounter } from "components/common/WrappedFormInputCounter";
import { CartCellDisabled } from "client/core/cart/components/cart-cell-disabled";

// Utils
import st from "./styles.module.css";

const CELL_QUANTITY_TYPE = {
  value: "value",
  step: "step",
  default: "default",
} as const;
const CELL_QUANTITY_PRODUCT_FIELD = {
  quantity: "quantity",
} as const;
type QuantityType = {
  value: number;
  step: number;
  default: number;
  available: number;
};

type TooltipWrapperProps = PropsWithChildren & {
  tooltip?: string;
  openTooltip?: boolean;
};
const ToolTipWrapper = ({ tooltip, openTooltip, children }: TooltipWrapperProps) => {
  if (!tooltip) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Tooltip
      align={{ offset: [12, 5] }}
      placement="topRight"
      type="primary"
      color="primary"
      title={tooltip}
      className={st["product-tooltip"]}
      open={openTooltip}
    >
      {children}
    </Tooltip>
  );
};

type CartCellQuantityProps = {
  name?: string;
  loading?: boolean;
  onChange?: (value: number) => void;
  onUpdate?: () => void;
  isDisabled?: boolean;
  tooltip?: string;
};
export const CartCellQuantity = (props: CartCellQuantityProps) => {
  const { name = "", loading = false, isDisabled = false, onChange, onUpdate, tooltip } = props;
  const { control, formState, getValues, setValue } = useFormContext();
  const { isSubmitting } = formState;
  const fieldName = `${name}${CELL_QUANTITY_PRODUCT_FIELD.quantity}`;
  const quantity = getValues(fieldName) as QuantityType;

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleChange = (value: number | string | null) => {
    setValue(`${fieldName}.${CELL_QUANTITY_TYPE.value}`, Number(value));
    onChange?.(Number(value));
    onUpdate?.();
  };

  const baseJSX = (
    <InputForm id={fieldName} name={fieldName} type="no-gutter" testId="cell-quantity">
      <ToolTipWrapper tooltip={tooltip} openTooltip={openTooltip}>
        <WrappedFormInputCounter
          name={fieldName}
          control={control}
          hasDisabled={isDisabled || isSubmitting || loading}
          min={quantity?.step}
          max={quantity?.available}
          step={quantity?.step}
          onChange={handleChange}
          onOpenTooltip={setOpenTooltip}
        />
      </ToolTipWrapper>
    </InputForm>
  );

  return isDisabled ? <CartCellDisabled>{baseJSX}</CartCellDisabled> : baseJSX;
};
