// Definitions
import {
  COUNTRY_CODE,
  CountryCodeType,
  getGoCorePDFLink,
  HotTrickerContentItemType,
} from "client/core/header-banner/utils/common";
import { HEADER_BANNER_ANALYTICS_KEY, HEADER_BANNER_TYPES } from "client/core/header-banner/models";
import { AnyAbility } from "@casl/ability";
import { PERMISSION_ACT } from "client/core/profile/models";
import { SURVEY_STATUSES } from "client/core/header-banner/hooks/use-header-banner-survey-manage-appearance";

// Utils
import { checkDateIsExpired } from "client/core/header-banner/hooks/use-header-banner-countdown-timer";
import { getSurveyNPSLink, surveyNPS } from "data/survey";
import { book } from "init/book";
import { cookiesService } from "utils/cookies";

const getLinkQueryParamsByCountry = (
  countryCode: CountryCodeType,
  bannerType: keyof typeof HEADER_BANNER_TYPES,
) => {
  if (countryCode === COUNTRY_CODE.DE && bannerType === HEADER_BANNER_TYPES.airFilters) {
    return `${book.catalog}/new/products-search?keyword=Luftfilter&search=Luftfilter`;
  }

  if (countryCode === COUNTRY_CODE.NL && bannerType === HEADER_BANNER_TYPES.airFilters) {
    return `${book.catalog}/new/products-search?keyword=Luchtfilter&search=Luchtfilter`;
  }

  if (countryCode === COUNTRY_CODE.FR && bannerType === HEADER_BANNER_TYPES.steeringParts) {
    return `${book.catalog}/new/products-search?keyword=Amortisseurs&search=Amortisseurs`;
  }

  if (countryCode === COUNTRY_CODE.DE && bannerType === HEADER_BANNER_TYPES.steeringParts) {
    return `${book.catalog}/new/products-search?keyword=Lenkungsteile&search=Lenkungsteile`;
  }

  if (countryCode === COUNTRY_CODE.NL && bannerType === HEADER_BANNER_TYPES.steeringParts) {
    return `${book.catalog}/new/categories/10112`;
  }

  if (countryCode === COUNTRY_CODE.FR && bannerType === HEADER_BANNER_TYPES.filters) {
    return `${book.catalog}/new/products-search?keyword=Filtres&search=Filtres`;
  }

  if (countryCode === COUNTRY_CODE.DE && bannerType === HEADER_BANNER_TYPES.filters) {
    return `${book.catalog}/new/products-search?keyword=Filter&search=Filter`;
  }

  if (countryCode === COUNTRY_CODE.NL && bannerType === HEADER_BANNER_TYPES.filters) {
    return `${book.catalog}/new/products-search?keyword=Filter&search=Filter`;
  }
};

export const getData = (
  countryCode: CountryCodeType,
  customerId: number,
): HotTrickerContentItemType[] => {
  return [
    {
      text: "common:topBanner.goCore.learnMore",
      permissionKey: "banner_top_go_core",
      order: 1,
      type: HEADER_BANNER_TYPES.goCore,
      components: [
        {
          name: "go-core-link",
          link: getGoCorePDFLink(countryCode),
        },
      ],
      analytics: {
        bannerName: "goCORE brand launch",
      },
    },
    // {
    //   text: "common:topBanner.goCore.shopNow",
    //   permissionKey: "banner_top_go_core",
    //   order: 0,
    //   type: HEADER_BANNER_TYPES.goCore,
    //   components: [
    //     {
    //       name: "go-core-link",
    //       link: `${book.catalog}/new${book.productsSearch}?keyword=goCORE&search=goCORE`,
    //     },
    //   ],
    //   analytics: {
    //     bannerName: "goCORE Brakes",
    //   },
    // },
    {
      text: "common:topBanner.discDiscount.title",
      date: "2024-10-13 23:59",
      permissionKey: "promote_assortment_expansion_discount_hot_ticker",
      order: 0,
      type: HEADER_BANNER_TYPES.discDiscount,
      components: [
        {
          name: "brake-disc-link",
          link: `${book.catalog}/new/categories/10106`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.discDiscount,
        categoryId: 10106,
      },
    },
    // {
    //   text: "common:topBanner.assortmentExpansion.battery",
    //   permissionKey: "promote_assortment_expansion_hot_ticker",
    //   order: 0,
    //   type: HEADER_BANNER_TYPES.assortmentExpansion,
    //   components: [
    //     {
    //       name: "assortment-expansion-link",
    //       link: `${book.catalog}/new/categories/10110`,
    //     },
    //   ],
    //   analytics: {
    //     bannerName: HEADER_BANNER_ANALYTICS_KEY.batteries,
    //     categoryId: 10110,
    //   },
    // },
    {
      text: "common:topBanner.assortmentExpansion.fluids",
      permissionKey: "promote_assortment_expansion_hot_ticker",
      order: 2,
      type: HEADER_BANNER_TYPES.assortmentExpansion,
      components: [
        {
          name: "assortment-expansion-link",
          link: `${book.catalog}/new/categories/10435`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.oils,
        categoryId: 10435,
      },
    },
    {
      text: "common:topBanner.assortmentExpansion.tyres",
      permissionKey: "promote_assortment_expansion_hot_ticker",
      order: 3,
      type: HEADER_BANNER_TYPES.assortmentExpansion,
      components: [
        {
          name: "assortment-expansion-link",
          link: `${book.catalog}/new/categories/11000`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.tyres,
        categoryId: 11000,
      },
    },
    {
      text: "common:topBanner.timingBeltKits.title",
      date: "2024-11-10 23:59",
      type: HEADER_BANNER_TYPES.beltkitsDiscount,
      permissionKey: "belt_kits_banner_top",
      order: 0,
      components: [
        {
          name: "belt-kits-link",
          link: `${book.catalog}/new/products-search?keyword=Kit+de+distribution&search=Kit+de+distribution`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.proDeal3,
      },
    },
    {
      text: "common:topBanner.lightBulbsDiscount.title",
      date: "2024-11-10 23:59",
      type: HEADER_BANNER_TYPES.lightBulbsDiscount,
      permissionKey: "light_bulbs_banner_top",
      order: 0,
      components: [
        {
          name: "light-bulbs-link",
          link: `${book.catalog}/new/products-search?keyword=Glühlampe&search=Glühlampe`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.proDeal3,
      },
    },
    {
      text: "common:topBanner.sparkPlugsDiscount.title",
      date: "2024-11-10 23:59",
      permissionKey: "spark_plugs_banner_top",
      order: 0,
      type: HEADER_BANNER_TYPES.sparkPlugsDiscount,
      components: [
        {
          name: "spark-plugs-link",
          link: `${book.catalog}/new/categories/10108`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.proDeal3,
        categoryId: 10108,
      },
    },
    {
      text: "common:topBanner.clutchPartsDiscount.title",
      date: "2024-11-24 23:59",
      permissionKey: "clutch_parts_banner_top",
      order: 4,
      type: HEADER_BANNER_TYPES.clutchParts,
      components: [
        {
          name: "clutch-parts-link",
          link: `${book.catalog}/new/products-search?keyword=Pi%C3%A8ces+D%27embrayage&search=Pi%C3%A8ces+D%27embrayage`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.proDeal4,
      },
    },
    {
      text: "common:topBanner.airFiltersDiscount.title",
      date: "2024-11-24 23:59",
      permissionKey: "air_filters_banner_top",
      order: 4,
      type: HEADER_BANNER_TYPES.airFilters,
      components: [
        {
          name: "air-filters-link",
          link: getLinkQueryParamsByCountry(countryCode, HEADER_BANNER_TYPES.airFilters) ?? "",
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.proDeal4,
      },
    },
    {
      text: "common:topBanner.ridexDiscount.title",
      date: "2024-11-22 23:59",
      permissionKey: "banner_top_ridex",
      order: 5,
      type: HEADER_BANNER_TYPES.ridex,
      components: [
        {
          name: "ridex-link",
          link: `${book.catalog}/new/products-search?keyword=ridex&search=RIDEX`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.ridex,
      },
    },
    {
      text: "common:topBanner.steeringPartsDiscount.title",
      date: "2024-12-08 23:59",
      permissionKey: "banner_top_steering_parts",
      order: 6,
      type: HEADER_BANNER_TYPES.steeringParts,
      components: [
        {
          name: "shock-absorbers-link",
          link: getLinkQueryParamsByCountry(countryCode, HEADER_BANNER_TYPES.steeringParts) ?? "",
        },
        {
          name: "steering-parts-link",
          link: `${book.catalog}/new/products-search?keyword=Pièces+De+Direction&search=Pièces+De+Direction`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.proDeal5,
        ...(countryCode === COUNTRY_CODE.NL && {
          categoryId: 10112,
        }),
      },
    },
    {
      text: "common:topBanner.filtersDiscount.title",
      date: "2024-12-12 23:59",
      permissionKey: "banner_top_filters",
      order: 8,
      type: HEADER_BANNER_TYPES.filters,
      components: [
        {
          name: "filters-link",
          link: getLinkQueryParamsByCountry(countryCode, HEADER_BANNER_TYPES.filters) ?? "",
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.proDeal6,
      },
    },
    {
      text: "common:topBanner.blackFriday.title",
      date: "2024-11-30 23:59",
      permissionKey: "banner_top_black_friday",
      order: 7,
      type: HEADER_BANNER_TYPES.blackFriday,
      components: [
        {
          name: "black-friday-link",
          link: `${book.catalog}/new`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.blackFriday,
      },
    },
    {
      text: "common:survey:header.text",
      date: surveyNPS.targetDate[countryCode || "EN"],
      surveyName: surveyNPS.surveyName,
      type: HEADER_BANNER_TYPES.npsSurvey,
      permissionKey: "nps_survey_banner_top",
      order: 0,
      components: [
        {
          name: "survey-link",
          link: getSurveyNPSLink(customerId, countryCode),
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.npsSurveyO24,
      },
    },
    {
      text: "common:topBanner.helloween.title",
      date: "2024-10-31",
      permissionKey: "helloween_banner_top",
      order: 0,
      type: HEADER_BANNER_TYPES.helloween,
      components: [],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.helloween,
      },
    },
    {
      text: "common:serviceAgentHoliday",
      permissionKey: "service_agent_holidays_banner_top",
      order: 0,
      type: HEADER_BANNER_TYPES.agentOff,
      components: [],
    },
    {
      text: "common:olympicGames",
      permissionKey: "banner_top_olympic_games",
      order: 0,
      type: HEADER_BANNER_TYPES.olympicGames,
      components: [],
    },
    {
      text: "common:topBanner.wiperBladesDiscount.title",
      date: "2024-10-27 23:59",
      permissionKey: "wiper_blade_banner_top",
      order: 0,
      type: HEADER_BANNER_TYPES.wiperBladeDiscount,
      components: [
        {
          name: "wiper-blade-link",
          link: `${book.catalog}/new/categories/10118`,
        },
      ],
      analytics: {
        bannerName: HEADER_BANNER_ANALYTICS_KEY.wiperBladeDiscount,
        categoryId: 10118,
      },
    },
  ];
};

export const filterData = (
  data: HotTrickerContentItemType[],
  ability: AnyAbility,
): HotTrickerContentItemType[] => {
  return data
    .filter((banner) => {
      if (banner.type === HEADER_BANNER_TYPES.npsSurvey && banner.surveyName && banner.date) {
        const SURVEY_STATUS = `survey_${banner.surveyName}_banner_top_status`;

        const isNotCompleted = cookiesService.get(SURVEY_STATUS) !== SURVEY_STATUSES.completed;

        return (
          ability.can(PERMISSION_ACT.READ, banner.permissionKey) &&
          isNotCompleted &&
          !checkDateIsExpired(banner.date)
        );
      }

      return !banner.date
        ? ability.can(PERMISSION_ACT.READ, banner.permissionKey)
        : ability.can(PERMISSION_ACT.READ, banner.permissionKey) &&
            !checkDateIsExpired(banner.date);
    })
    .sort((a, b) => a.order - b.order);
};
