// Core
import { useRouter } from "next/router";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Container } from "components/common/Container";
import { CartPreviewVehicles } from "client/core/cart/components/cart-preview-vehicles";
import { CartPreviewTotal } from "client/core/cart/components/cart-preview-total";
import { CartDeliveryInfo } from "client/core/cart/components/cart-delivery-info";
import { CartPreviewForm } from "client/core/cart/components/cart-preview-form";
import { CartPreviewPriceVatSwitch } from "client/core/cart/components/cart-preview-price-vat-switch";
import { CartPreviewVehiclesStoreProvider } from "client/core/cart/components/cart-preview-vehicles-store-provider";
import { SettingsPriceVatProvider } from "client/core/settings-price-vat/context";
import { CartPreviewProductAlternativesProvider } from "client/core/cart/components/cart-preview-product-alternatives-provider";
import { CartPreviewProductAlternatives } from "client/core/cart/components/cart-preview-product-alternatives";

// Hooks
import { useCartPreviewUpdate } from "client/core/cart/hooks/use-cart-preview-update";
import { useCartPreviewFetch } from "client/core/cart/hooks/use-cart-preview-fetch";
import { useSettingsPriceFetch } from "client/core/settings-price-vat/hooks/use-settings-price-fetch";
import { useCartPreviewTrack } from "client/core/cart/hooks/use-cart-preview-track";
import { useTranslation } from "client/utils/i18n/client";

// Utils
import { book } from "init/book";
import { ROW_GUTTER_TYPE } from "components/ui/Row/utils";
import { getCartPreviewPriceVatSwitcherOptions } from "client/core/cart/components/cart-preview-price-vat-switch/options";

import st from "./styles.module.css";

export const CartPreview = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { settingsPrice } = useSettingsPriceFetch();
  const { loadingCartPreviewFetch, cartPreview } = useCartPreviewFetch();
  const { loadingCartPreviewUpdate, onUpdateCartPreview } = useCartPreviewUpdate();
  useCartPreviewTrack({ cartPreview, loading: loadingCartPreviewFetch });

  const handleNavigateToPayment = () => {
    void (async () => {
      await router.push({ pathname: book.ordersCheckout });
    })();
  };

  return (
    <SettingsPriceVatProvider viewPriceVat={settingsPrice.showPriceVat}>
      <>
        <section className={st["cart-preview"]} data-test-id="cart-preview">
          <Container>
            <div className={st["cart-preview-inner"]}>
              <div className={st["cart-preview-inner-top"]}>
                <Row
                  gutter={ROW_GUTTER_TYPE.md.horizontal}
                  align="bottom"
                  justify="space-between"
                  className={st["cart-preview-top"]}
                >
                  <Col span={14}>
                    <div
                      className={st["cart-preview-vehicles-edit"]}
                      data-test-id="cart-preview-vehicles-edit"
                    >
                      <div className={st["cart-preview-vehicles"]}>
                        <CartPreviewForm
                          loadingFetch={loadingCartPreviewFetch}
                          loadingUpdate={loadingCartPreviewUpdate}
                          initialValues={cartPreview}
                          onSubmit={onUpdateCartPreview}
                        >
                          <>{t("inputs:cart.selectAllVehicles.label")}</>
                        </CartPreviewForm>
                      </div>
                      <CartPreviewPriceVatSwitch
                        loading={loadingCartPreviewFetch || loadingCartPreviewUpdate}
                        label={t("products:titles.vat")}
                        options={getCartPreviewPriceVatSwitcherOptions(t)}
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <CartPreviewTotal
                      loadingFetch={loadingCartPreviewFetch}
                      loadingUpdate={loadingCartPreviewUpdate}
                      totalPrice={cartPreview?.totalPrice}
                      totalPriceVat={cartPreview?.totalPriceVat}
                      vatPrice={cartPreview?.vatPrice}
                      vatPercentage={cartPreview?.vatPercentage}
                      hasMandateUnconfirmed={!cartPreview?.checkoutAvailable}
                      onCreate={handleNavigateToPayment}
                    />
                  </Col>
                </Row>

                <Row gutter={ROW_GUTTER_TYPE.md.horizontal} className={st["cart-preview-main"]}>
                  <Col span={24}>
                    <CartPreviewProductAlternativesProvider>
                      <>
                        {loadingCartPreviewFetch ? (
                          <CartPreviewVehicles loadingCartFetch />
                        ) : (
                          cartPreview?.tabs?.map((tab) => (
                            <CartPreviewVehiclesStoreProvider key={tab.id}>
                              <CartPreviewVehicles
                                tab={tab}
                                loadingCartFetch={loadingCartPreviewFetch}
                                loadingCartUpdate={loadingCartPreviewUpdate}
                              />
                            </CartPreviewVehiclesStoreProvider>
                          ))
                        )}
                        <CartPreviewProductAlternatives />
                      </>
                    </CartPreviewProductAlternativesProvider>
                  </Col>
                </Row>
              </div>
              <Row
                gutter={ROW_GUTTER_TYPE.md.horizontal}
                className={st["cart-preview-inner-bottom"]}
              >
                <Col span={16}>
                  <CartDeliveryInfo
                    title={t("cart:total.totalDelivery")}
                    desc={t("cart:total.deliveryInfo")}
                    data={cartPreview?.deliveryDate}
                    loadingFetch={loadingCartPreviewFetch}
                    loadingUpdate={loadingCartPreviewUpdate}
                  />
                </Col>
                <Col span={8}>
                  <CartPreviewTotal
                    loadingFetch={loadingCartPreviewFetch}
                    loadingUpdate={loadingCartPreviewUpdate}
                    totalPrice={cartPreview?.totalPrice}
                    totalPriceVat={cartPreview?.totalPriceVat}
                    vatPrice={cartPreview?.vatPrice}
                    vatPercentage={cartPreview?.vatPercentage}
                    hasMandateUnconfirmed={!cartPreview?.checkoutAvailable}
                    onCreate={handleNavigateToPayment}
                  />
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </>
    </SettingsPriceVatProvider>
  );
};
