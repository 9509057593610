// Core
import React from "react";

// Utils
import st from "./Styles.module.less";

type WrapperClickableProps = {
  onClick: () => void;
  children: React.ReactNode;
  tag?: "div" | "span";
  testId?: string;
};

export const WrapperClickable = (props: WrapperClickableProps) => {
  const { onClick, testId = "wrapper-clickable", children, tag: Tag = "div" } = props;
  return (
    <Tag className={st["wrapper-clickable"]} onClick={onClick} data-test-id={testId}>
      {children}
    </Tag>
  );
};
