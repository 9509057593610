// Core
import { useContext, useEffect, useMemo, useState } from "react";

// Defintions
import type { HotTrickerContentItemType } from "client/core/header-banner/utils/common";

// Utils
import { createContentLinkedList } from "client/core/header-banner/utils/common";
import { filterData, getData } from "../data";
import { useProfileContext } from "hooks/useProfileContext";
import { PermissionContext } from "contexts/usePermissionContext";
import { HEADER_BANNER_TYPES } from "client/core/header-banner/models";
import { useHeaderBannerSurveyManageAppearance } from "./use-header-banner-survey-manage-appearance";
import { checkDateIsExpired } from "./use-header-banner-countdown-timer";

type UseHeaderBannerManageContentType = {
  banners: HotTrickerContentItemType[];
  dataIndex: number;
  handleSurveyLinkClick: () => void;
};

export const useHeaderBannerManageContent = (timeOut: number): UseHeaderBannerManageContentType => {
  const ability = useContext(PermissionContext);
  const { profile } = useProfileContext();
  const [banners, updateBanners] = useState(
    filterData(getData(profile?.countryCode || "EN", profile?.customerId || 0), ability),
  );

  const list = useMemo(() => {
    return createContentLinkedList(banners);
  }, [banners]);

  const [dataIndex, setDataIndex] = useState<number>(0);

  const npsSurvey = useMemo(() => {
    return list.find((banner) => banner.data.type === HEADER_BANNER_TYPES.npsSurvey) ?? null;
  }, [list]);

  const regenerateList = () => {
    updateBanners((prevBanners) =>
      prevBanners.filter((item) => item.type !== banners[dataIndex].type),
    );
    setDataIndex(list[dataIndex].next);
  };

  const handleNext = () => {
    const next = list[list[dataIndex].next].data;

    if (next.date && checkDateIsExpired(next.date)) {
      regenerateList();
    } else {
      setDataIndex(list[dataIndex].next);
    }
  };

  const { handleSurveyLinkClick, npsSurveyAvailable } = useHeaderBannerSurveyManageAppearance({
    targetDate: npsSurvey?.data.date ?? "",
    surveyName: npsSurvey?.data.surveyName ?? "",
    onFinishNps: regenerateList,
  });

  useEffect(() => {
    const interval = setInterval(() => handleNext(), timeOut);

    if (list.length === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [dataIndex, list.length, npsSurveyAvailable]);

  return { banners, dataIndex, handleSurveyLinkClick };
};
