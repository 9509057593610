// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import type {
  EventPayloadDocumentSortType,
  EventDocumentSortDataType,
} from "client/utils/gtm/gtm.types";
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  ROUTE_NAME_KEYS,
  DOCUMENT_COLUMN_VALUE,
} from "client/utils/gtm/gtm.types";

class DocumentSortEvent extends AbstractGTMEventSegment<GTMTypesEnum.document_sort> {
  protected readonly segmentShape: EventPayloadDocumentSortType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.event_action]: DOCUMENT_COLUMN_VALUE.empty,
      [ParametersEnum.route_name]: ROUTE_NAME_KEYS.documents,
    },
  };

  makeSegment(data: EventDocumentSortDataType): EventPayloadDocumentSortType {
    const { actionType } = data;
    const segment = this.getSegment();

    segment[PayloadKeysEnum.parameters][ParametersEnum.event_action] =
      `sort_${DOCUMENT_COLUMN_VALUE[actionType]}`;
    segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = ROUTE_NAME_KEYS.documents;

    return segment;
  }
}

export const documentSortEvent: DocumentSortEvent = new DocumentSortEvent();
