// Core
import { z } from "zod";

export const USER_COUNTRY_SELECTOR_FIELDS = {
  country: "country",
} as const;

const generalWithTranslations: Record<string, string> = {
  defaultPlaceholder: "inputs:defaultPlaceholders.select",
};

export const inputConfig = {
  [USER_COUNTRY_SELECTOR_FIELDS.country]: {
    placeholder: generalWithTranslations.defaultPlaceholder,
  },
};

export const userCountrySelectorForm = {
  shape: {
    country: null,
  },
  schema: () =>
    z.object({
      [USER_COUNTRY_SELECTOR_FIELDS.country]: z
        .object({
          id: z.number(),
          label: z.string(),
          value: z.string(),
          icon: z.string(),
        })
        .nullable(),
    }),
};
